<div class="digital-modal">
  <maced-dialog dialogId="embeddedDialogId" *ngIf="showIframe">
    <maced-dialog-content dialogContentId="Default Dialog Content">
      <maced-button buttonId="embeddedCloseBtn"  [icon]="'icon-x-close'"
        (click)="onCloseIframe()">
        >
      </maced-button>
      <ng-container *ngIf="embeddedUrl">
        <iframe id="embeddedLinkViewer" (load)="onload()" class="digital-modal__iframe" [title]="iframeTitle" [src]="embeddedUrl" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
      </ng-container>
    </maced-dialog-content>
  </maced-dialog>
</div>