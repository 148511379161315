import { Injectable } from '@angular/core';
import { Observable, of} from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { GlobalSettings } from 'src/app/global.settings';
import { ApiConstants } from '../constant/api.constant';
import { HttpClient} from '@angular/common/http';
import { LoggerService } from './logger.service';
import { IHomework } from '../models/homework';
import { environment } from '../../../environments/environment';
@Injectable({
  providedIn: 'root'
})
export class HomeworkService {
  baseUrl: string = ApiConstants.aceApiBaseUrl;
  constructor( private http: HttpClient,
    public loggerService: LoggerService) { }
    saveHomeworkAssessment(homeworkData: any):  Observable<IHomework[]> {
      if (GlobalSettings.isBrowser) {
        const url: string = this.baseUrl + ApiConstants.saveHomeworkAssessmentUrl;
        return this.http.post<IHomework[]>(url, homeworkData)
          .pipe(
            tap(response => {
              return response;
            }),
            catchError(this.handleError('saveHomeworkAssessment', []))
          );
      }
      else if (GlobalSettings.isNative) {
        return new Observable(observer => {
          saveHomeworkAssessmentCallback(homeworkData, ((res:any) => {
            let response = JSON.parse(res);
            observer.next(response.result);
          }));
        });
      } else {
        const url: string = GlobalSettings.LocalNodeServerBaseUrl + '/api/homework/addUpdateHomeworkAssessment';
        return this.http.post<any>(url, {homeworkData: homeworkData, env: environment.name})
          .pipe(
            tap(response => {
              return response;
            }),
            catchError(this.handleError('saveHomeworkAssessment', []))
          );
      }
    }
  handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      this.loggerService.logInfo(error);
      return of(result as T);
    };
  }

copyHomeworkAssessment(homeworkData: any) {
if (GlobalSettings.isBrowser) {
  const url: string = this.baseUrl + ApiConstants.copyAssessmentUrl;
  return this.http.post<IHomework[]>(url, homeworkData)
    .pipe(
      tap(response => {
        return response;
      }),
      catchError(this.handleError('copyHomeworkAssessment', []))
    );
}
else if (GlobalSettings.isNative) {
  return new Observable(observer => {
    copyHomeworkAssessmentCallback(homeworkData, ((res:any) => {
      let response = JSON.parse(res);
      observer.next(response.result);
    }));
  });
} else {
  const url: string = GlobalSettings.LocalNodeServerBaseUrl + '/api/homework/copyHomeworkAssessment';
  return this.http.post<any>(url, homeworkData)
    .pipe(
      tap(response => {
        return response;
      }),
      catchError(this.handleError('copyHomeworkAssessment', []))
    );
}
}

  // deleteHomeworkAssessment API - start here
  deleteHomeworkAssessment(params: any): Observable<any> {
    if (GlobalSettings?.isBrowser) {
      return this.browserDeleteHomeworkAssessment(params);
    } else if (GlobalSettings?.isNative) {
      return this.nativeDeleteHomeworkAssessment(params);
    } else {
      return this.desktopDeleteHomeworkAssessment(params);
    }
  }

  browserDeleteHomeworkAssessment(params: any): Observable<any> {
    const url: string = ApiConstants?.aceApiBaseUrl + ApiConstants?.deleteHomeworkAssessmentUrl;
    return this.http.get<any>(url, { params: params }).pipe(tap(response => {
      return response;
    }), catchError(
      this.handleError('browserDeleteHomeworkAssessment', [])
    ));
  }

  nativeDeleteHomeworkAssessment(params: any): Observable<any> {
    return new Observable(observer => {
      deleteHomeworkAssessmentCallback(params, ((res: any) => {
        const response = JSON.parse(res);
        observer.next(response.result);
      }));
    });
  }

  desktopDeleteHomeworkAssessment(params: any): Observable<any> {
    if(params.entityType === 6) {
      const url: string = GlobalSettings?.LocalNodeServerBaseUrl + '/api/common/getCommonApi';
      const apiUrl: string = ApiConstants?.aceApiBaseUrl + ApiConstants?.deleteHomeworkAssessmentUrl;
      return this.http.get<any>(url, { params: { apiUrl, ...params } }).pipe(tap(response => {
        return response;
      }), catchError(
        this.handleError('desktopDeleteCourse', [])
      ));
    } else {
      const url: string = GlobalSettings?.LocalNodeServerBaseUrl + '/api/homework/deleteHomeworkAssessment';
      return this.http.get<any>(url, { params: params }).pipe(tap(response => {
        return response;
      }), catchError(
        this.handleError('desktopDeleteHomeworkAssessment', [])
      ));
    }
    }
}
