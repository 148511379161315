import { GlobalSettings } from "src/app/global.settings";

export interface IMachineIdentifier {
  os: string;
  browser: string;
  isNativeApp: number;
}
export class AuthLogin {
  ott!: string;
  machineIdentifier!: string;
}
export interface IAuthLogin {
  ott: string;
  machineIdentifier: string;
}
export interface ICentralizedLogin {
  platform: string;  
  homeworkId: string;
  isSkipUserCheck: string;
  appURL: string;
  machineIdentifier: string;
  strAppURL: string;
  strMachineIdentifier: string;
}
export interface IBookmarkNotesData {
  contentUnitID: string;
  pageNo: string;
  notesId: string;
  annotationType: string;
  annotationData: string;
  lastAccessedDateTime: string;
}
export interface ISubscriptionDetail {
  meeUserId: string;
  machineIdentifier: string;
  role: string;
}

export interface IRcfmanifest {
  contentUnitId: string;
  manifestVersion: string;
  env: string;
}

export function getrcfManifestParams(env: string,contentUnitId: string,manifestVersion: string): any {
  let params = <IRcfmanifest>  {
    env: env,
    contentUnitId: contentUnitId,
    manifestVersion: manifestVersion,
  }; 
  return params;
}

export function getMachineIdentifierParams(deviceDetectorService: any): any {
  if (GlobalSettings.isCoreApi && deviceDetectorService) {
    let machineIDParams: IMachineIdentifier = {
      os: deviceDetectorService.os,
      isNativeApp: 1,
      browser: deviceDetectorService.browser
    };
    return machineIDParams;
  }
  else
    return {};
}

export function getCentralizedLoginParams(platform: string, strAppURL: string, isSkipUserCheck: string, machineIdentifier: string, isLoadTest: boolean): any {
  let homeworkId: any = "";
  
  let params = GlobalSettings.isCoreApi?<Omit<ICentralizedLogin, "strAppURL" | "strMachineIdentifier" >>{
      appURL:strAppURL,
      machineIdentifier:machineIdentifier,
      platform:platform,
      homeworkId: homeworkId,
      isSkipUserCheck: isSkipUserCheck,
      isLoadTest: isLoadTest
  }:<Omit<ICentralizedLogin, "appURL" | "machineIdentifier" >>{
      strAppURL:strAppURL,
      strMachineIdentifier:machineIdentifier,
      platform:platform,
      homeworkId: homeworkId,
      isSkipUserCheck: isSkipUserCheck,
      isLoadTest: isLoadTest
  }; 
  return params;
  
}

export function getSaveOrUpdateBookmarkNotesParam(bookmark: any): any {

  var params = GlobalSettings.isCoreApi? <IBookmarkNotesData>{
    contentUnitID: bookmark.BookmarkNotesData[0].ContentUnitId,
      pageNo: bookmark.BookmarkNotesData[0].PageNo,
      notesId: bookmark.BookmarkNotesData[0].NotesId,
      annotationType: bookmark.BookmarkNotesData[0].AnnotationType,
      annotationData: bookmark.BookmarkNotesData[0].AnnotationData,
      lastAccessedDateTime: bookmark.BookmarkNotesData[0].LastAccessedDateTime
  }
    :bookmark;

    return params;
  
}

export interface IDeleteIwbAnnotation{
  ContentUnitID: string;
  PageNo: string;
  contentUnitId: string;
  pageNo: string;
}

export function getDeleteIwbAnnotationParams(dataObj:any): any {
  
  let params = GlobalSettings.isCoreApi?<Omit<IDeleteIwbAnnotation, "ContentUnitID" | "PageNo" >>{
    contentUnitId:dataObj.ContentUnitID,
    pageNo:dataObj.PageNo
  }:<Omit<IDeleteIwbAnnotation, "contentUnitId" | "pageNo" >>{
    ContentUnitID:dataObj.ContentUnitID,
    PageNo:dataObj.PageNo
  }; 
  return params;
  
}
