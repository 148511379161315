<maced-dialog dialogId="commonDialog" tabindex="-1" role="dialog" aria-modal="true" #commonDialogRef>
  <maced-dialog-content>
    <h2 class="c-modal-content__title">
      {{ dialogProps?.title }}
    </h2>
    <p class="c-modal-content__text">
      {{ dialogProps?.subTitle }}
    </p>
  </maced-dialog-content>
  <maced-dialog-footer class="c-dialog__content-footer">
    <maced-button [buttonId]="btnIdOne" [label]="dialogProps?.cancelLabel" color="secondary"
      styleClass="u-button-normal" (click)="dialogClicked(dialogProps?.cancelLabel)">
    </maced-button>
    <maced-button [buttonId]="btnIdTwo" [label]="dialogProps?.saveLabel" color="primary" styleClass="u-button-normal"
      (click)="dialogClicked(dialogProps?.saveLabel)">
    </maced-button>
  </maced-dialog-footer>
</maced-dialog>