import { Injectable } from '@angular/core';
import { concatMap, delay, first, map, Observable, of, ReplaySubject, Subject, Subscription, timer } from 'rxjs';
import { Data } from '../models/data';
import { GlobalSettings } from 'src/app/global.settings';
const { detect } = require('detect-browser');
const browser = detect();
@Injectable({
  providedIn: 'root'
})
export class AccessibilityService {
  public navigationUrlNotifier$ = new ReplaySubject(1);
  stringsArray: string[] = [];
  displayInterval: any;
  public writeLiveSROnlyData: Subject<string> = new Subject();

  constructor(public data: Data) {
    this.bindLiveDataSubscription();
  }

  setNavigationUrl(url: any) {
    this.navigationUrlNotifier$.next(url);
  }

  bindLiveDataSubscription() {
    let writeObserver: Observable<any> = of('delay').pipe(delay(GlobalSettings.srIntervalTime));
    let liveSubscription: Subscription;
    if(browser.type != 'node') {
      this.writeLiveSROnlyData.pipe(
        concatMap((str) => timer(GlobalSettings.srIntervalTime).pipe(
          map(() => str)
        ))
      )
        .subscribe((displayString: any) => {
          this.data.srDisplayData.next(displayString);
          if (liveSubscription) {
            liveSubscription.unsubscribe();
          }
          liveSubscription = writeObserver.pipe(first()).subscribe(() =>{
            this.data.srDisplayData.next('');
          });
        });
    }
  }
}