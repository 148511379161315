import { Injectable } from '@angular/core';
import { fabric } from 'fabric';
import { BehaviorSubject } from 'rxjs';
import { GlobalSettings } from 'src/app/global.settings';
import { UtilityService } from 'src/app/services/utility.service';
import { Data } from '../models/data';
import { GTMAppTypes, IWBAnnotation } from '../models/gtm-models/gtm';
import { CommonService } from './common.service';
import { GtmService } from './gtm.service';
import { IwbAnnotationService } from './iwb-annotation.service';
import { LoggerService } from './logger.service';

/// <reference path='../../../assets/epubjs/readiumjs/readium_reader.d.ts'/>
declare let ReadiumSDK: any;

@Injectable({
  providedIn: 'root'
})
export class WhiteboardUtilityService {
  canvas?: fabric.Canvas | any;
  selectedTool!: 'select' | 'pen' | 'highlight' | 'text' | '';
  textToolProperties!: { textFont: string, textColor: string, textBackgroundColor: string };
  highlightToolProperties!: { highlightStroke: string, highlightColor: string };
  annotationList: any[] = [];
  isCanvasEnabled = false as boolean;
  isCanvasDataModified = false as boolean;
  isCanvasEdit = false as boolean;
  currentHighlightObject: any;
  currentTextObject: any;
  public showPanel: BehaviorSubject<string> = new BehaviorSubject<string>('');

  constructor(
    private _data: Data,
    private _gtmService: GtmService,
    private _commonService: CommonService,
    private _iwbAnnotationService: IwbAnnotationService,
    private utilityService: UtilityService,
    public _loggerService: LoggerService
  ) {
    this.subscribeCanvasEnabled();
    this.subscribeCanvasDataModified();
  }

  subscribeCanvasEnabled() {
    this._data.canvasEnabled.subscribe((isCanvasEnabled: boolean) => {
      this.isCanvasEnabled = isCanvasEnabled;
    });
  }

  subscribeCanvasDataModified() {
    this._data.canvasDataModified.subscribe((isCanvasDataModified: boolean) => {
      this.isCanvasDataModified = isCanvasDataModified;
    });
  }

  //------------------- Canvas related services --------------------------------------//
  loadCanvas() {
    this.canvas = new fabric.Canvas('whiteboard', {
      backgroundColor: 'rgba(255,255,255,0)'
    });
    this.resizeCanvas();
  }

  clearCanvas() {
    if (this.canvas) {
      this.canvas.clear();
    }
  }

  getAnnotationDataForPage() {
    const spineData = ReadiumSDK.reader.getLoadedSpineItems();
    let idref = '';
    if (spineData) {
      idref = spineData.map((obj: any) => obj.idref).toString();
    }
    return this._data.getIwbData?.find(o => o.PageNo === idref);
  }

  getAnnotationData(iwb: string) {
    const json = this._commonService.convertToAnnotationData(iwb);
    return JSON.parse(json);
  }

  sendIsCanvasDataModified(){
    return this.isCanvasDataModified;
  }

  removeCanvasEvents() {
    this.canvas?.off('mouse:down:before');
    this.canvas?.off('mouse:down');
    this.canvas?.off('mouse:move');
    this.canvas?.off('mouse:up');
    this.canvas?.off("object:modified");
    this.canvas?.off('object:added');
    this.canvas?.off('object:moving');
    this.canvas?.off('selection:created');
    this.canvas?.off('selection:updated');
    this.canvas?.off('selection:cleared');
    this.canvas?.off('text:changed');
  }

  setupCanvasEvents() {
    this.canvas.on('mouse:down:before', (options: any) => {
      options.e.stopPropagation();
      this.onCanvasMouseDownBefore(options);
    });

    this.canvas.on('mouse:down', (options: any) => {
      options.e.stopPropagation();
      this.onCanvasMouseDown(options);
    });

    this.canvas.on('mouse:move', (options: any) => {
      options.e.stopPropagation();
      this.onCanvasMouseMove(options);
    });

    this.canvas.on('mouse:up', (options: any) => {
      options.e.stopPropagation();
      this.onCanvasMouseUp(options);
    });

    this.canvas.on("object:modified", () => {
      this.onCanvasChanges();
    });

    this.canvas.on('object:added', () => {
      this.onCanvasChanges();
    });

    this.canvas.on('object:moving', () => {
      this.onCanvasObjectMove();
    });

    this.canvas.on('selection:created', (options: any) => {
      this.onSelectAnnotation(options);
    });

    this.canvas.on('selection:updated', (options: any) => {
      this.onSelectAnnotation(options);
    });

    this.canvas.on('selection:cleared', (options: any) => {
      this.isCanvasEdit = false;
    });

    this.canvas.on({
      'text:changed': (opt: any) => {
        let t1 = opt.target;
        if (t1.width > t1.fixedWidth) { //checks if user type is going beyond the fixed width of textbox if true then decrasing the font-size
          t1.fontSize *= t1.fixedWidth / (t1.width + 1); //Caculating the font size according to user types in textbox
          t1.width = t1.fixedWidth;
        }
        if (t1.height > t1.fixedHeight) { //checks if user type is going beyond the fixed heigth of textbox if true then decrasing the font-size
          t1.fontSize *= t1.fixedHeight / (t1.height + 1);  //Caculating the font size according to user types in textbox
          t1.height = t1.fixedHeight;
        }
        this.prepareSaveIwbData();
      }
    });
  }

  showCanvasConfirmationDialogForExternalActivity() {
    if (this.isCanvasEnabled && this.isCanvasDataModified) {
      let dialogProps: any = this.getSaveDialogLabels();
      dialogProps.visible = true;
      dialogProps.event = 'externalNavigation';
      this._data.showConfirmationDialog.emit(dialogProps);
    }
    if (this.isCanvasEnabled) {
      this._data.canvasEnabled.emit(false);
      this._data.canvasEnabled.emit(true);
    }
  }

  getSaveDialogLabels() {
    return {
      title: this._data.languageJson.iwb_labels?.popup?.title,
      subTitle: this?._data?.languageJson?.iwb_labels?.popup?.subTitle,
      cancelLabel: this?._data?.languageJson?.iwb_labels?.popup?.cancel,
      saveLabel: this?._data?.languageJson?.iwb_labels?.popup?.save
    };
  }

  getDeleteDialogLabels() {
    return {
      title: this._data.languageJson.iwb_labels.popup.delete.title,
      subTitle: this._data.languageJson.iwb_labels.popup.delete.subTitle,
      cancelLabel: this._data.languageJson.global.global_button.cancel,
      saveLabel: this._data.languageJson.global.global_button.delete
    };
  }

  public resizeCanvas() {
    const fixedBookFrame = document.getElementById('fixed-book-frame');
    if (fixedBookFrame && this.canvas) {
      let bookWidth = fixedBookFrame.clientWidth;
      let bookHeight = fixedBookFrame.clientHeight;
      this.canvas.setHeight(this._data.viewerInitialHeight || bookHeight);
      this.canvas.setWidth(this._data.viewerInitialWidth || bookWidth);
      let c: any = document.getElementById('viewerCanvas');
      c.style.width = bookWidth + 'px';
      c.style.height = bookHeight + 'px';
      c.style.left = fixedBookFrame.style.left;
      c.style.top = fixedBookFrame.style.top;
      c.style.transform = fixedBookFrame.style.transform;
      c.style.transformOrigin = fixedBookFrame.style.transformOrigin;
      let canvasContainer: any = document.getElementsByClassName('canvas-container');
      canvasContainer[0].style.width = c.style.width;
      canvasContainer[0].style.height = c.style.height;
    }
  }

  public zoomCanvas() {
    const fixedBookFrame = document.getElementById('fixed-book-frame');
    if (fixedBookFrame && this.canvas) {
      let bookWidth = fixedBookFrame.clientWidth;
      let bookHeight = fixedBookFrame.clientHeight;

      let c: any = document.getElementById('viewerCanvas');
      c.style.width = bookWidth + 'px';
      c.style.height = bookHeight + 'px';

      c.style.left = fixedBookFrame.style.left;
      c.style.top = fixedBookFrame.style.top;
      c.style.transform = fixedBookFrame.style.transform;
      c.style.transformOrigin = fixedBookFrame.style.transformOrigin;

      let canvasContainer: any = document.getElementsByClassName('canvas-container');
      canvasContainer[0].style.width = c.style.width;
      canvasContainer[0].style.height = c.style.height;
    }
  }

  private onCanvasMouseDownBefore(event: any) {
    if (this.currentTextObject) {
      this.currentTextObject.exitEditing();
    }
  }

  private onCanvasMouseDown(event: any) {
    if (this.selectedTool === 'highlight') {
      this.startHighlight(event);
    }
  }

  private onCanvasMouseMove(event: any) {
    if (this.selectedTool === 'highlight') {
      this.continueHighlight(event);
    }
  }

  private onCanvasMouseUp(event: any) {
    this.showPanel.next('close');
    if (this.canvas) {
      if (this.selectedTool === 'text') {
        this.prepareTextTool(event);
      }
      if (this.selectedTool === 'highlight') {
        this.canvas.isDrawingMode = false;
        this.prepareSaveIwbData();
      }
    }
  }

  private onCanvasChanges() {
    this._data.canvasDataModified.next(true);
    this.prepareSaveIwbData();
  }

  private onCanvasObjectMove() {
    this.canvas.isDrawingMode = false;
  }

  private onSelectAnnotation(options: any) {
    this.isCanvasEdit = true;
    if (options.selected.length === 1) {
      if (options.selected[0].type === 'path') {
        setTimeout(() => {
          (document.querySelector('#Pen button') as HTMLButtonElement).click();
        }, 200);
      }
      if (options.selected[0].type === 'line') {
        setTimeout(() => {
          (document.querySelector('#Highlighter button') as HTMLButtonElement).click();
        }, 200);
      }
      if (options.selected[0].type === 'textbox') {
        setTimeout(() => {
          (document.querySelector('#Text button') as HTMLButtonElement).click();
        }, 200);
      }
    }
  }

  private startHighlight(event: any) {
    this.canvas.isDrawingMode = true;
    let pointer = this.canvas.getPointer(event);
    let points = [pointer?.x, pointer?.y, pointer?.x, pointer?.y];
    this.currentHighlightObject = new fabric.Line(points, {
      strokeWidth: Number(this.highlightToolProperties?.highlightStroke),
      stroke: this.highlightToolProperties?.highlightColor,
      fill: this.highlightToolProperties?.highlightColor,
      originX: 'center',
      originY: 'center',
      transparentCorners: false,
      cornerColor: '#5787F4',
      cornerStyle: 'circle',
      borderColor: '#5787F4',
      borderDashArray: [7, 7]
    });
    this.canvas.add(this.currentHighlightObject);
  }

  private continueHighlight(event: any) {
    if (this.canvas.isDrawingMode) {
      let pointer = this.canvas.getPointer(event);
      this.currentHighlightObject.set({
        x2: pointer.x,
        y2: pointer.y
      });
      this.currentHighlightObject.setCoords();
      this.canvas.renderAll();
    }
  }

  private prepareTextTool(event: any) {
    const pointer = this.canvas.getPointer(event);
    const textBoxProperties: any = {
      fontFamily: 'helvatica',
      fontSize: this.textToolProperties?.textFont,
      fontWeight: 400,
      fill: this.textToolProperties?.textColor,
      fontStyle: 'normal',
      top: pointer?.y,
      left: pointer?.x,
      textBackgroundColor: this.textToolProperties?.textBackgroundColor,
      scaleX: 1.5,
      scaleY: 1.5,
      fixedWidth: Math.abs(this.canvas.width - pointer?.x - ((pointer?.x > this.canvas.width / 2 ? 0.17 : 0.3) * this.canvas.width)), // Calculating the pointer location of mouse and compare with 50% of the canvas width if pointer location where user click is greater the canvas 50% width then calculating the actual width of the textbox for typing
      width: Math.abs(this.canvas.width - pointer?.x - ((pointer?.x > this.canvas.width / 2 ? 0.17 : 0.3) * this.canvas.width)),
      height: Math.abs(this.canvas.height - pointer?.y - (0.156 * this.canvas.height)),// Calculating the pointer location of mouse and compare with 50% of the canvas height if pointer location where user click is greater the canvas 50% height then calculating the actual height of the textbox for typing.
      fixedHeight: Math.abs(this.canvas.height - pointer?.y - (0.156 * this.canvas.height)),
      breakWords: true,
      transparentCorners: false,
      cornerColor: '#5787F4',
      cornerStyle: 'circle',
      borderColor: '#5787F4',
      borderDashArray: [7, 7]
    };

    this.currentTextObject = new fabric.Textbox('', textBoxProperties);
    this.canvas.add(this.currentTextObject);
    this.currentTextObject.enterEditing();
  }

  //------------------- IWB Annotation data related http services --------------------------------------//
  gaGtmForSaveIwbData() {
    if (this._data.aceToken?.Role === 'instructor') {
      this._gtmService.eventTracking(IWBAnnotation, 'Save IWBannotation');
    }
  }

  prepareSaveIwbData() {
    const spineData = ReadiumSDK.reader.getLoadedSpineItems();
    let idref = '';
    if (spineData) {
      idref = spineData.map((obj: any) => obj.idref).toString();
    }
    const canvasData = JSON.stringify(this.canvas?.toJSON());
    const annotationObject = {
      ContentUnitID: this._data.contentUnitID,
      PageNo: idref,
      AnnotationData: this._commonService.convertToBase64(canvasData),
      LastAccessedDateTime: new Date()
    };

    this.annotationList = [];
    this.annotationList.push(annotationObject);
  }

  deleteIwbData() {
    this._gtmService.eventTracking(IWBAnnotation, 'Delete IWBannotation.');
    this._gtmService.pageViewTracking(IWBAnnotation);

    const spineData = ReadiumSDK?.reader?.getLoadedSpineItems();
    let idref = '';
    if (spineData) {
      idref = spineData.map((obj: any) => obj.idref).toString();
    }

    return {
      ContentUnitID: this._data.contentUnitID,
      PageNo: idref
    };
  }

  deleteSelectedData() {
    this._gtmService.eventTracking(IWBAnnotation, 'Delete IWBannotation');

    const canvasData = JSON.stringify(this.canvas?.toJSON());
    const spineData = ReadiumSDK?.reader?.getLoadedSpineItems();
    let idref = '';
    if (spineData) {
      idref = spineData.map((obj: any) => obj.idref).toString();
    }
    const newData = {
      ContentUnitID: this._data.contentUnitID,
      PageNo: idref,
      AnnotationData: this._commonService?.convertToBase64(canvasData),
      LastAccessedDateTime: new Date()
    };

    let annotationList = [];
    annotationList.push(newData);

    return annotationList;
  }

  getUpdatedAnnotationData() {
    return new Promise((resolve: any) => {
      this.utilityService.checkInternetConnectivity().subscribe(internetStatus => {
        if (GlobalSettings.isBrowser || !internetStatus || this._data.isOfflineUser) {
          this.getIWBannotation(resolve);
        } else {
          this.syncIWBannotation(resolve);
        }
      });
    });
  }

  createGaGtmObj(eventCategory: string, eventAction: string, eventLabel: string) {
    return { eventCategory, eventAction, eventLabel };
  }

  getDevicePlatform(): string {
    if (GlobalSettings.isBrowser) {
      return GTMAppTypes.Browser;
    } else if (GlobalSettings.isNative) {
      return GTMAppTypes.Native;
    } else {
      return GTMAppTypes.Desktop;
    }
  }

  getIWBannotation(callback?: any) {
    const accountId: string | undefined = this._data.aceToken?.AccountId ? this._data.aceToken?.AccountId : this._data.accountId;
    const meeUserId: string | undefined = this._data.aceToken?.MEEUserId ? this._data.aceToken?.MEEUserId : this._data.meeUserId;

    let getIwbObj = GlobalSettings.isBrowser ? {
      "contentUnitId": this._data.contentUnitID
    } : {
      "contentUnitId": this._data.contentUnitID,
      "accountID": accountId,
      "meeUserId": meeUserId
    };

    this._iwbAnnotationService?.getIwbAnnotation(getIwbObj).subscribe({
      next: (response: any) => {
        this._data.getIwbData = typeof response === 'string' ? [] : response;
        if (this.isCanvasEnabled) {
          this._data.canvasEnabled.emit(false);
          this._data.canvasEnabled.emit(true);
        }
        if (callback) {
          callback();
        }
      },
      error: (e: any) => { this._loggerService.logInfo(`Error occured -->${e}`); },
      complete: () => this._loggerService.logInfo(`Completed with the calling save annotation service`)
    });
  }

  syncIWBannotation(callback?: any) {
    const accountId: string | undefined = this._data.aceToken?.AccountId ? this._data.aceToken?.AccountId : this._data.accountId;
    const meeUserId: string | undefined = this._data.aceToken?.MEEUserId ? this._data.aceToken?.MEEUserId : this._data.meeUserId;
    let dataObj: any;
    if (GlobalSettings.isBrowser) {
      dataObj = {
        "contentUnitId": this._data.contentUnitID
      };
    } else if (GlobalSettings.isNative) {
      dataObj = {
        "contentUnitId": this._data.contentUnitID,
        "accountId": accountId
      };
    } else {
      dataObj = {
        "contentUnitId": this._data.contentUnitID,
        "accountId": accountId,
        "meeUserId": meeUserId
      };
    }


    this._iwbAnnotationService.syncIwbAnnotation(dataObj).subscribe((res: boolean) => {
      if (res) {
        this.getIWBannotation(callback);
      }
    });
  }

  hideCanvas() {
    let viewerCanvas: any = document.getElementById('viewerCanvas')
    if (this.canvas && viewerCanvas) {
      viewerCanvas.style.visibility = 'hidden';
    }
  }
  showCanvas() {
    let viewerCanvas: any = document.getElementById('viewerCanvas')
    if (this.canvas && viewerCanvas) {
      viewerCanvas.style.visibility = 'visible';
    }
  }
}