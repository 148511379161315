import { Injectable,PLATFORM_ID, Inject } from '@angular/core';
import { BehaviorSubject, delay, of } from 'rxjs';
import { Router, ActivatedRoute, Event, NavigationEnd} from '@angular/router';
import { CommonService } from 'src/app/core/services/common.service';
import { isPlatformBrowser } from '@angular/common';
import { BodyClassService } from '../core/services/body-class.service';
@Injectable({
  providedIn: 'root'
})
export class ToastService {
  data:any = { type: '', message: '', link: {}, customClass: ''};
  currentRoute:any;
  private messageSource = new BehaviorSubject<any>(this.data);
  currentMessage = this.messageSource.asObservable();
  isPlatformBrowser: boolean = true;
  queryParams: any;
  timeout: any;

  constructor(private router: Router, public commonService:CommonService, private activatedRoute: ActivatedRoute,private bodyClassService: BodyClassService,@Inject(PLATFORM_ID) public platformId: Object
  ) {
    if (isPlatformBrowser(this.platformId)) {
      this.isPlatformBrowser = true;
    } else {
      this.isPlatformBrowser = false;
    }
    this.activateRoute();
  }
  activateRoute(){
    this.currentRoute = "";
    this.router.events?.subscribe((event: Event) => {
        if (event instanceof NavigationEnd) {
            this.currentRoute = event.url;
        }
    });
    this.activatedRoute.queryParams?.subscribe((res: any) => {
      this.queryParams = res;
    });
  }

  showMessage(type:string, message: string, isNavigationRequired:boolean = true, link?: { label: string, hyperLink: string }, customClass?: string){
    this.commonService.isLoading_hide();
    this.close();
    if(link){
      this.data ={
      type: type,
      message: message,
      link: link,
      customClass : customClass
      };
      }else{
      this.data ={
      type: type,
      message: message
      };
      }

    let queryParams = {
      ...this.queryParams,
      toastId: this.data.type,
      isOpen: true
    };

    if (queryParams.isOpen == true) {
      of('toastr').pipe(delay(1000)).subscribe(() => {
        let toastMassage = document.getElementById('success');
        toastMassage?.setAttribute('aria-atomic', 'true');
        toastMassage?.setAttribute('aria-live', 'assertive');
      })
    }
   
    this.router.navigate([this.router.url.split('?')[0]], { queryParams: queryParams});
    this.messageSource.next(this.data);
    
    of('query params').pipe(delay(5000)).subscribe(() => {
      let updatedQueryParams = {
        ...this.queryParams,
        isOpen: false
      };
      if (isNavigationRequired) {
        this.router.navigate([this.router.url.split('?')[0]], { queryParams: updatedQueryParams });
      }
      this.close();
      this.bodyClassService.removeBodyClass('show-toast');
      this.bodyClassService.removeBodyClass('show-warning-toast');
    });
  }

  close(){
    if(this.isPlatformBrowser){
      this.data ={type: '', message: '', link: ''};
      this.messageSource.next(this.data);
      clearTimeout(this.timeout);
    }
  }
}
