<ng-container *ngIf="!hideCompleteHeader">
  <ng-container *ngIf="!showGlobalHeader">
    <maced-global-header *ngIf="!showHeaderPrelogin" globalHeaderId="globalHeader9" [logoUrl]="logoUrl" [logo]="logo"
      [smallLogo]="smallLogo" [logoAltText]="logoAltText" [moreMenuTitle]="moreMenuTitle" [ariaLabels]="ariaLabels"
      [hasReversedTitle]="true" [isSingleAction]="true">
      <ng-container ngProjectAs="[actions]">
        <ul class="c-headers__list" *ngIf="headerDesktopView; else headerMobileView">
          <li class="c-headers__list-item">
            <maced-dropdown dropdownId="dropdownId" name="dropdownId" ariaLabel="Dropdown" label="Select"
              cheveronClass="icon-chevron-down" [hasCheckboxesDropdown]="false" [options]="languageOptions"
              [selectedOption]="selectedOptions">
            </maced-dropdown>
          </li>
          <li class="c-headers__list-item">
            <ng-container *ngFor="let links of headerlinks">
              <maced-button size="large" type="link" icon="icon-download" position="left" color="primary"
                [url]="links.url" buttonId="submit2" [label]="links.label"></maced-button>
            </ng-container>
          </li>
        </ul>
        <ng-template #headerMobileView>
          <maced-button *ngIf="headerMobileDropDown"
            [ngClass]="isShowlanguageMenubar ? 'c-headers__actions-close-button' : 'c-headers__actions-language-button'"
            size="large" color="secondary" [buttonId]="buttonId" [type]="buttonType" [name]="buttonValue"
            [ariaLabel]="!isShowlanguageMenubar ? 'language button': 'close button' " [label]="buttonValue"
            (click)="openHeaderList()" [ariaExpanded]="activeHamburger" [value]="buttonValue" [url]="currentUrl"
            [queryParams]="{ hasHamburger: isShowlanguageMenubar ? 0:1, type:'singleButton'}"> </maced-button>
        </ng-template>
      </ng-container>
    </maced-global-header>
    <ng-container *ngIf="isShowlanguageMenubar">
      <!-- cascadeMenus:{{cascadeMenus|json}} -->
      <ng-container *ngFor="let cascadeMenu of cascadeMenus;let i = index">
        <maced-cascade-menubar [cascadeMenubarId]="'CascadeMenubar' + i" [cascadeMenubarLabel]="cascadeMenu.name"
          [cascadeMenuList]="cascadeMenu.menus" (menuEvent)="onClickCascadeMenu($event,i)"
          (backEvent)="onClickCascadeBack(i)" (closeEvent)="onClickCascadeClose()" [hasBackButton]="i==0?false:true"
          [hasTitleBar]="i==0?false:true" [hasBreadcrumbs]="false" *ngIf="cascadeMenu.isVisible"
          [hasCloseButton]="false">
        </maced-cascade-menubar>
      </ng-container>
    </ng-container>
  </ng-container>
  <ng-container *ngIf="logoShowHide; else elsePart">
    <maced-global-header
      *ngIf="showGlobalHeader && (!isOfflineBookViewerPage) && (!isExtraPracticePage) && (!isChallengePage) && (!isBookviewer) && (!isHomework) && (!isTaskListingPage) && (!isGradingPaperAssessmentPage)"
      [globalHeaderId]="idHeader" [title]="title" [subTitle]="subTitle" [backUrl]="backUrl"
      [backQueryParams]="backQueryParams" [moreMenuTitle]="moreMenuTitle" [links]="links" [ariaLabels]="ariaLabels">
      <ng-container ngProjectAs="[actions]">
        <ul class="c-headers__list" *ngIf="links.length>1; else singleActionlinks">
          <li class="c-headers__list-item" *ngFor="let link of links">
            <maced-button size="large" [queryParams]="link.queryParams" [buttonId]="link.id" [position]="link.position"
              [icon]="link.icon" [label]="link.label" type="link" [url]="link.url" [color]="link.color">
            </maced-button>
          </li>
        </ul>
        <ng-template #singleActionlinks>
          <ng-container *ngFor="let link of links">
            <maced-button size="large" [queryParams]="link.queryParams" [buttonId]="link.id" [position]="link.position"
              [icon]="link.icon" [label]="link.label" type="link" [url]="link.url" [color]="link.color">
            </maced-button>
          </ng-container>
        </ng-template>
        <maced-button *ngIf="hideHamMenu"
          [ngClass]="isShowMenubar ? 'c-headers__actions-close-button' : 'c-headers__actions-hamburger-button'"
          size="large" color="secondary" [buttonId]="buttonId" [type]="buttonType" [name]="buttonValue"
          [ariaLabel]="!isShowMenubar ? 'hamburger': 'close' " [label]="buttonValue" (click)="openMenu()"
          [ariaExpanded]="activeHamburger" [value]="buttonValue" [url]="currentUrl"
          [queryParams]="{ hasHamburger: isShowMenubar ? 0:1, type:'singleButton'}"> </maced-button>

      </ng-container>
    </maced-global-header>
  </ng-container>
  <ng-template #elsePart>
    <maced-global-header
      *ngIf="showGlobalHeader && (!isOfflineBookViewerPage) && (!isExtraPracticePage) && (!isChallengePage) && (!isBookviewer) && (!isTaskListingPage) && !selectActivityPage && !isLanguageSwitcherPage && (!isGradingPaperAssessmentPage)"
      globalHeaderId="globalHeader11" [title]="title" [subTitle]="subTitle" [logoUrl]="logoUrl" [logo]="imageLogoPath"
      [smallLogo]="imageLogoPathSmall" [logoAltText]="logoAltText" [moreMenuTitle]="moreMenuTitle"  [queryParams]="logoUrlParams"
      [ariaLabels]="ariaLabels" [hasReversedTitle]="true" [isSingleAction]="true" [hasHamburger]="true">
      <ng-container ngProjectAs="[actions]">
        <maced-button *ngIf="headerMobileDropDown"
          [ngClass]="isShowMenubar ? 'c-headers__actions-close-button' : 'c-headers__actions-hamburger-button'"
          size="large" color="secondary" [buttonId]="buttonId" [type]="buttonType" [name]="buttonValue"
          [ariaLabel]="!isShowMenubar ? 'hamburger': 'close' " [label]="buttonValue" (click)="openMenu()"
          [ariaExpanded]="activeHamburger" [value]="buttonValue" [url]="currentUrl"
          [queryParams]="{ hasHamburger: isShowMenubar ? 0:1, type:'singleButton'}"> </maced-button>
      </ng-container>
    </maced-global-header>
  </ng-template>
  
  <maced-modal-header [title]="title" [subtitle]="subTitle" [cancelUrl]="backUrl" *ngIf="isBookviewer"
    [id]="idModelHeaderZoom" [isSmallContentModal]="isSmallContentModal">
    <app-goto-page *ngIf="!data.isMobileView || data?.iwbModeBtn"></app-goto-page>
    <div class="zoom-section" #tooltipTargetReference>
      <maced-toggle *ngIf="showtogglebutton || data?.iwbModeBtn" name="hotspotToggle" id="hotspotToggle"
      [ariaLabel]="toggleNgModel ? zoomAreaOnLabel : zoomAreaOffLabel"
      [textOn]='toggleText' [(ngModel)]="toggleNgModel" [disabled]="isToggleDisabled" (ngModelChange)="showHotspot()">
      </maced-toggle>
    </div>
    <app-zoom-control class="book-zoom-control" *ngIf="isBrowserOrDesktop || data?.iwbModeBtn"></app-zoom-control>
    <maced-button [isDisabled]="disableSearchButton" (click)="globalSearch();" *ngIf="isSearch"
      styleClass="header-search" class="book-header-search" buttonId="searchBookmarksId" color="secondary" shape="square" size="medium" icon="icon-search"
      [label]="SearchButtonLabel" [ariaLabel]='SearchButtonLabel'>
    </maced-button>        
    <maced-button buttonId="exitBookViewer" label="{{data.languageJson.global.global_button.exitBookView}}"
      color="primary" position="left" styleClass="u-button-normal"  class="book-exit-button" (click)="backToUrl();" shape="square" size="medium"></maced-button>
  </maced-modal-header>

  <maced-modal-header *ngIf="isExtraPracticePage" [title]="title" [subtitle]="subTitle" id="exitHeader" role="banner"
    [isSmallContentModal]="true" aria-label="Exit Header">
    <maced-button buttonId="exitEPCActivity" [ariaLabel]="headerLabel" [url]="exitEPCUrl"
      [queryParams]="backQueryParams" type="link" [label]="headerLabel" color="primary" position="left"
      styleClass="u-button-normal"></maced-button>
  </maced-modal-header>

  <maced-modal-header *ngIf="isOfflineBookViewerPage" [title]="title" [subtitle]="subTitle" id="exitOfflineBookviewerHeader" role="banner"
    [isSmallContentModal]="true" aria-label="Exit Header">
    <maced-button buttonId="exitOfflineBookviewer" [ariaLabel]="exitButtonLabel" [url]="exitCourseBundlePage"
      [queryParams]="backQueryParams" type="link" [label]="exitButtonLabel" color="default" position="left"
      styleClass="u-button-normal"></maced-button>
  </maced-modal-header>

  <maced-modal-header *ngIf="isChallengePage" [title]="title" [subtitle]="subTitle" id="exitHeader" role="banner"
    [isSmallContentModal]="true">
    <maced-button buttonId="exitchallengeActivity" [ariaLabel]="headerLabelChallenge" [url]="exitEPCUrl" [queryParams]="backQueryParams" type="link"
      [label]="headerLabelChallenge" color="primary" position="left" styleClass="u-button-normal"></maced-button>
  </maced-modal-header>

  <maced-modal-header *ngIf="isGradingPaperAssessmentPage" [title]="title" [subtitle]="subTitle" id="paperGradingHeader" role="banner" aria-label="gradingHeader"
    [isSmallContentModal]="true">
    <maced-button buttonId="exitGradingPaperAssessment" [url]="backUrl" [queryParams]="backQueryParams" type="link"
      [label]="exitButtonLabel" color="default" position="left" styleClass="u-button-normal"></maced-button>
  </maced-modal-header>
  
  <maced-global-header
    *ngIf="isTaskListingPage && (!isOfflineBookViewerPage) &&(!isExtraPracticePage) && (!isChallengePage) && (!isHomework) && (!isBookviewer) && (!isGradingPaperAssessmentPage)"
    [cancelUrl]="backUrl" [backUrl]="backUrl" [backQueryParams]="backQueryParams" globalHeaderId="idModelHeaderHomework"
    [title]="title" [subTitle]="subTitle" [moreMenuTitle]="moreMenuTitle" [ariaLabels]="ariaLabels"
    [hasReversedTitle]="true">
  </maced-global-header>
  <maced-modal-header *ngIf="selectActivityPage && !isTaskListingPage"
    [styleClass]="selectActivityPage && !isTaskListingPage? 'dnone' : 'dblock'" [title]="title" [subtitle]="subTitle"
    id="activityHeader" class="activity-header" [isSmallContentModal]="selectActivityPage">
    <maced-button [queryParams]="backQueryParams" tabindex="-1" [url]="exitActivityUrl" type="link"
      buttonId="exitActivity" label="Exit" color="tertiary" position="left" styleClass="u-button-normal"></maced-button>
  </maced-modal-header>
  <maced-global-header *ngIf="isLanguageSwitcherPage" [title]="title" [subTitle]="subTitle" globalHeaderId="langHeader"
    [logoUrl]="logoUrl" [logo]="logo" [smallLogo]="smallLogo" [logoAltText]="logoAltText" [ariaLabels]="ariaLabels"
    [hasReversedTitle]="false">
    <ng-container ngProjectAs="[actions]">
      <maced-button
        [ngClass]="isShowMenubar ? 'c-headers__actions-close-button' : 'c-headers__actions-hamburger-button'"
        size="large" color="secondary" [buttonId]="buttonId+'lang'" [type]="buttonType" [name]="buttonValue"
        [ariaLabel]="!isShowMenubar ? 'hamburger': 'close' " [label]="buttonValue" (click)="openMenu()"
        [ariaExpanded]="activeHamburger" [value]="buttonValue" [url]="currentUrl"
        [queryParams]="{ hasHamburger: isShowMenubar ? 0:1, type:'singleButton'}"> </maced-button>
    </ng-container>
  </maced-global-header>
</ng-container>
