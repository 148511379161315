import { Resource } from './resource';

export class ICourse {
  courseLevel!: string;
  courseName!: string;
  endDate!: string;
  startDate!: string;
  subscriptionId!: string;
  productBundleId!: string;
  productBundleName?: string;
  resources!: Resource[];
  isHybrid?: boolean;
  isMep?: boolean;
  isLms?: boolean;
  averageScore?: number;
  primaryImageUrl?: any;
  secondaryImageUrl?: any;
  typeData?:any;
  isCompleted?: any;
}
