export const RESOURCETYPES = {
  TestGenerator: { Title: "Test Generator", ResourseType: "MACXTG" },
  ResourceBank: { Title: "Resource Bank", ResourseType: 'MACXRB' },
  MagicPhonics:{ Title:"Magic Phonics", ResourseType:'magicphonics'},
  PrintDerived: { Title: "Print Derived", ResourseType: 'MACXPD' },
  NonPrintDerived: { Title: "Non Print Derived", ResourseType: 'MACXNPD' },
  CURResourceBank: { Title: "Resource Bank", ResourseType: 'CURRB' },
  CURPrintDerived: { Title: "Print Derived", ResourseType: 'CURPD' },
  CURNonPrintDerived: { Title: "Non Print Derived", ResourseType: 'CURNPD' },
  NavioHybridNonPrintDerived: { Title: "Navio Hybrid Non Print Derived", ResourseType: "NHYNPD" },
  NavioHybridPrintDerived: { Title: "Navio Hybrid Non Print Derived", ResourseType: "NHYPD" },
  EplannerResource: { Title: 'EPLANNER', ResourceType: 'eplanner' },
  CURAssessment: { Title: "Assessment", ResourseType: 'curassess' },
  CourseBuilder: { Title: "View all courses", ResourseType: 'MEECB' },
  CourseBuilderActivity: 'MEEBBQ',
  Macxassess: 'MACXASSESS'
};

export const BADGESORTRANK = {
  gold: { sortRank: 1 },
  silver: { sortRank: 2 },
  bronze: { sortRank: 3 },
};

export const LOGINPARAMS = {
  LOGIN: 'login',
  CREATEACCOUNT: 'createaccount'
};

export class ActivityModeType {
  public StandAloneAudioType = 'standAloneMedia_audio';
  public StandAloneVideoType = 'standAloneMedia_video';
  public RcfHeightForAudio = 144;
  public RcfWidthForAudio = 818;
  public RcfHeightForVedio = 524;
  public RcfWidthForVedio = 650;

  public RcfMinWidthForVideo = 50;
  public RcfMinHeightForVideo = 80;
  public RcfNewHeightForAudio = 114;
  public RcfNewHeightForMacAudio = 150;
  public RcfNewWidthForAudio = 430;
  public RcfExpandedHeightForAudio = 400;
  public RcfExpandedWidthForAudio = 650;
  public RcfNewHeightForAudioStudent = 175;
  public standAloneAudio = 'audio';
  public standAloneVideo = 'video';
  public standAloneStory = "story";
  public default = 'default';
}

export class RCFWindowState {
  public static maximized = 'maximized';
  public static windowed = 'windowed';
  public static closed = 'closed';
}

export class RCFDisplayType {
  public static maximized = 'maximized';
  public static fullScreen = 'full-screen';
  public static audioScriptOpen = 'audio-script-open';
  public static audioScriptClosed = 'audio-script-closed';
}
export class PlayerLanguage {
  public static En = 'en';
  public static Es = 'es';
}

export class PlayerTitleBarPosition {
  public static top = 'top';
  public static bottom = 'bottom';
}

export const DEFAULT_PLAYER_THEME = 'theme_1';

export const PlayerTheme =  {
 "blue": DEFAULT_PLAYER_THEME,  // Default theme for RCF and Single platform
 "calm-blue": "theme_calmBlue",
 "purple-sky": "theme_purpleSky",
 "turtle-bay": "theme_turtleBay"
};

export const GlobalEmptyImgTheme =  {
  "blue": "error_blue",
  "calm-blue": "error_blue",
  "purple-sky": "error_blue",
  "turtle-bay": "error_blue"
 };
 
export const RcfActivityBook = 'RCF Activity - Book';
export const RcfActivityChallenge = 'RCF Activity - Challenge';
export const RcfActivityPreparation = 'RCF Activity - Preparation & Practice';
export const EPC_ModuleName='Module-Preparation & Practice';
export const Challenge_ModuleName='Module-Challenge';
export const Grading_ModuleName='Module-Grading';
export const Progress_ModuleName='Module-Progress-Tracker';


export class DebugMode {
  public static Development = 'development';
  public static Production = 'production';
}

export class DisplayType {
  public static Windowed = 'windowed';
  public static FullScreen = 'full-screen';
}

export class UserRole {
  public static Teacher = 'teacher';
  public static Student = 'student';
  public static admin = 'admin';
}

export class LinkOrigin {
  public static Hotspot = 'hotspot';
  public static Homework = 'homework';
  public static Gradebook = 'gradebook';
  public static Assessment = 'assessment';
  public static strictHomework = 'strictHomework';
}

export const PDResourceTypes = {
  macxpd: 'macxpd',
  nhypd: 'nhypd',
  curpd: 'curpd'
};

export const hwResourceTypes = {
  curpd: 'curpd',
  curnpd: 'curnpd',
  macxnpd: 'macxnpd',
  macxpd: 'macxpd',
  nhypd: 'nhypd',
  nhynpd: 'nhynpd',
};

export const ManifestTypes = {
  SPOTON: 'spoton',
  TOC: 'toc',
  RCF: 'rcf'
};

export const THEME_CLASSES = {
  blueTheme: 'blueTheme',
  redTheme: 'redTheme',
  navioHybridColor: 'navioHybridColor',
  lmsTheme: 'lmsTheme',
  defaultColor: 'defaultColor'
};

export const GTMdata = {
  teacherRole: 'Teacher',
  studentRole: 'Student',
  undefined: 'Undefined'
};

export const CourseEmpty = 'Select Course';
export const BookmarkEmpty = 'You have not saved any bookmarks';

export const REGEX = {
  Email: /^(\([a-zA-Z\-0-9]+\)){0,1}(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)(\([a-zA-Z\-0-9]+\)){0,1}|("[^\"\\]+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  EmailCommentStart: /^(\([a-zA-Z\-0-9]+\))/,
  EmailCommentEnd: /(\([a-zA-Z\-0-9]+\))$/,
  Name: /^[^`~!@#$%\^&*()_+={}|[\]\\:';"<>?,./]*$/,
  Guid: /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i,
  Password: /^[^&<>]*$/
};

export const WHITEBOARDTOOLS = {
  Highlight: 'Highlight',
  Text: 'Text',
  Freehand: 'Freehand',
  Notes: 'Notes',
  Select: 'Select',
  DefaultStrokeColor: 'red',
  DefaultStrokeSize: 10,
  DefaultHighlighterStrokeColor: 'rgba(255, 228, 0,0.5)',
  DefaultHighlighterStrokeSize: 10,
  PenIcon: 'url(src/assets/default/images/Pen.svg), auto',
  HighlightIcon: 'url(src/assets/default/images/Highlight.svg), auto',
  SelectedPath: 'path',
  SelectedText: 'i-text',
  MenuWidth: 92,
  MenuHeight: 300,
  Hotspot: 'Hotspot'
};

export const SCHEMA = {
  version3: '3.0',
  versionframeless1: 'frameless_1.0',
  version4: '4.1',
};

export const ICON = {
  widthIconValueSinglePage: 6.005,
  heightIconValueSinglePage: 4.005,
  widthIconValue: 3.005,
  heightIconValue: 4.005
};

export const COURSEPROGRESS = {
  NotApplicable: 'Not applicable',
  TotalActivitiesCompleted: 'Total Activities Completed',
  Progress: 'Progress',
  ProgressEmptyMessage: 'Please complete an activity to see a score here.'
};

export const APPTYPE = {
  Student: 'Student',
  Teacher: 'Teacher'
};

export const ROLETYPE = {
  admin: 'admin',
  superadmin: 'superadmin',
  parent: 'parent',
  teacher: 'ace teacher'
};

export const Spinners = {
  main: 'mainSpinner'
};

export const homeworkFirstTab = {
  firstTabTitle: 'Select students',
  secondTabTitle: 'Select activities',
  thirdTabTitle: 'Information for students',
  fourthTabTitle: 'Confirm homework',
  fifthTabTitle: 'Homework successfully sent'
};

export const nextIconClass = {
  nextIconClassname: 'icon-chevron-slash',
  iconLinkExternal: 'icon-link-external c-link__right'
};

export const breadcrumbsContainerId = {
  breadcrumbsContainerId: 'breadcrumbsContainer1'
};

export const numberOfLinksVisible = {
  numberOfLinksVisible: 3
};

export const breadCrumbLimit = {
  breadCrumbLimit: 20
};

export const smallScreenSize = {
  smallScreenSizeWidth: 1023
};

export const smallScreenTabSize = {
  smallScreenTabSizeWidth: 1300
};

export const maxSmallScreenMobileSize = {
  maxSmallScreenMobileSize: 767
};

export const smallScreenMobileSize = {
  smallScreenMobileSize: 800
};

export const iwbScreenSize = {
  iwbScreenSizeWidth: 1920
};

export const zoomScreenSize = {
  zoomScreenSizeWidth: 1439
};

export const GTMDATATRACKEVENTOBJ = {
  eventCategory: 'Book Controls',
  eventAction: 'Clicked On Hotspot Overview Tool',
  eventLabel: 'Hotspot Overview Tool'
};

export const NotificationType = {
  homework: 'Homework'
};

export const HYBRID_FOOTER_LIST = ['courses', 'join-class', 'help', 'log-out', 'more-options'];
export const HYBRID_MEP_FOOTER_LIST = ['courses', 'homework', 'challenge', 'progress', 'more-options'];
export const HYBRID_MORE_MENU = ['terms-and-condition', 'privacy-policy'];
export const HYBRID_RESOURCES = ['NHYNPD', 'NHYPD', 'MACXRB', 'MACXTG', 'CURRB'];
export const MEP_RESOURCES = ['MACXPD', 'MACXNPD', 'MACXRB', 'MACXTG', 'CURNPD', 'CURPD', 'CURASSESS', 'CURRB'];
export const ELT_MEP_RESOURCES = ['MACXASSESS'];
export const HYBRID_RESOURCESTYPE = ['MACXPD', 'MACXNPD', 'CURNPD', 'CURPD', 'CURASSESS'];
export const LMS_FOOTER_LIST = ['studentcalender', 'subjects', 'challenge', 'homework', 'resource', 'assessments', 'reports', 'messages', 'notifications', 'myprofile', 'send-report', 'help', 'log-out', 'minimizeMenu', 'moreOption'];
export const PARENT_FOOTER_LIST = ['parentcalender', 'reports', 'messages', 'notifications', 'calender', 'parentprofile', 'help', 'log-out', 'minimizeMenu', 'moreOption'];
export const notesColumns = [['Highlighted text', 'Added Notes', 'Highlighted color', 'Added on']];
export const bookColumns = [['Book Title', 'Exported On']];
export const Parent_Dropdown = [
  {
    Name: 'Parent', Id: 'parent'
  },
  { Name: 'Student', Id: 'student' }
];

export const Cookie_Status = {
  Accepted: 'accepted',
  NotAccepted: 'notaccepted',
  Rejected: 'rejected'
};

export const BookType = {
  epub: 'epub',
  pdf: 'pdf'
};

export const GlossaryTypes = {
  audio: 'audio',
  video: 'videos',
  image: 'images'
};

export const ScoreCategory = {
  FirstScore: 'FisrtScore',
  LatestScore: 'LatestScore',
  MaxScore: 'MaxScore'
};

export const ActivityStatus = {
  NotAttempted: 'NotAttempted',
  Completed: 'Completed',
  InProgress: 'InProgress'
};

export const ImagePathForActivityStatus = {
  NotAttempted: '../assets/default/images/oval-copy-12.svg',
  Completed: '../assets/default/images/icon-copy-40.svg',
  InProgress: '../assets/default/images/InProgress.svg',
  Review: '../assets/default/images/Review.svg'
};

export const ProgressReportsKey = {
  ActivityDetails: 'ActivityDetails'
};

export const staticKey = {
  Reports: 'Reports',
  Gradebook: 'Gradebook',
  Homework: 'Homework',
  ProgressTracker: 'Progress Tracker',
  zeroPercentage: '0 %',
  RemedialAssessments: 'Remedial Assessments'
};

export const DisplayNotFoundMessage = 'There are no messages in your';
export const DisplayNotFoundDashboardMessage = 'There are no messages to display';
export const DisplayNotFoundAssessmentMessage = 'There are no assessments due this month.';

export const MessagePagerParams = {
  "pageNumber": 1,
  "rowsPerPage": 10,
  "sortColumn": "CreatedDate",
  "sortOrder": "DESC",
  "searchText": "null",
};

export const DeleteMessagePop = {
  headerForPermanentlyDeleteMessage: 'Permanently Delete Message',
  titleForPermanentlyDeleteMessage: 'Are you sure you want to permanently delete this message?',
  headerForDeleteMessage: 'Delete Message',
  titleForDeleteMessage: 'Are you sure you want to detele this message? The message will be moved into your trash folder.',
};

export const messageType = {
  inbox: 'inbox',
  sent: 'sent',
  trash: 'trash'
};

export const moveMessageToTrash = {
  titleForMessage: 'Message has been moved to Trash',
};
export const UserAccessType = {
  Student: 'Student',
  Teacher: 'Instructor'
};

export const ComponentType = {
  Gradebook: 'Gradebook',
  Remedial: 'Remedial',
  Notification: 'Notification'
};

export const HomeworkStatus = {
  InProgress: 'In Progress',
  Completed: 'Completed',
  NotStarted: 'Not Started'
};

export const HomeworkType = {
  RCF: 0,
  UGC: 1,
  Mix: 2
};

export const ShowHomeworkLabel = {
  createNewHomework: 'Create new homework',
  editHomework: 'Edit homework',
  homeworkSent: 'Homework sent',
  setHomeworkAgain: 'Set homework again'
};

export const downloadStatus = {
  complete: 'complete',
  downloading: 'Downloading',
  success: 'Success'
};

export const HomeworkStepData = {
  Emitfirststep: 1,
  Emitsecondstep: 2,
  Emitthirdstep: 3,
  Emitfourthstep: 4,
  EmitReturnstep1: "return1",
  EmitReturnstep2: "return2",
  EmitReturnstep3: "return3",
  EmitHideStepper: "hideStepper",
  EmitShowStepper: "showStepper",
  EmitHomeworkfailed: "homeworkfailed",
  EmitshowDialog: "showDialog",
};

export const IconPathHomeworkStatus = {
  Completed: 'assets/default/images/icon-copy-40.svg',
  InProgress: 'assets/default/images/InProgress.svg',
  NotStarted: 'assets/default/images/oval-copy-12.svg'
};

export const IconBase64StringHomeworkStatus = {
  Completed: 'data:image/jpeg;base64,/9j/4AAQSkZJRgABAQAAAQABAAD//gAfQ29tcHJlc3NlZCBieSBqcGVnLXJlY29tcHJlc3P/2wCEAAICAgICAgICAgIDAwMDAwQEBAQEBAcFBQUFBQcKBgcGBgcGCgkLCQgJCwkQDQsLDRATEA8QExcUFBcdGx0lJTIBAgICAgICAgICAgMDAwMDBAQEBAQEBwUFBQUFBwoGBwYGBwYKCQsJCAkLCRANCwsNEBMQDxATFxQUFx0bHSUlMv/CABEIABQAFAMBIgACEQEDEQH/xAAYAAEAAwEAAAAAAAAAAAAAAAAJBQYHCP/aAAgBAQAAAADWefVoK+QuiGHm1/8A/8QAFwEAAwEAAAAAAAAAAAAAAAAAAwYHCP/aAAgBAhAAAADPrYCt/wD/xAAWAQEBAQAAAAAAAAAAAAAAAAAIBAf/2gAIAQMQAAAAFWiSJj//xAAqEAABBAEDAwMDBQAAAAAAAAACAQMEBQYABxIIERMhMTJBUZEXIkNhYv/aAAgBAQABPwDqa6msuzrLrrFcVupNZjNbKdiCMR1WjnG0vA3XTD1VtV+Aa2xlbkrk7X6cXE6FZNNPTHHm5aR2W2Y4+Rx2UbioCNCifu5+mtjOqGiznb+DZZnPjV91FfchzBUuAPG2gkj7Y/QTQvzpvDcZpM1zPb3cmbNpbBiYcWFafONHfbNexS2eKkbDwqioYr3H37LrMLamwDGntsMNtItjLnK25k13Dc5syjBebdfDP6xWfcy/kP8ApNbN9OmabnYi5lFU+cWIVg8w1zVQR3xCPcw+6d1Ue/3TXVDsZt/nNFPzOygvxbqvjHxmQ3EbN4A+Lb6EJIYp+ddOezmI7m5tIqcncsCiRCI/Ew8LXlQF+Di8VXsv+ey6oqKoxqor6Ghr2YNfBZFlhhkeIAA+yImv/8QAJBEAAgIABQMFAAAAAAAAAAAAAQIDBAAFBhETEjFRFiEiQXH/2gAIAQIBAT8Asvdt25s4zS6VEbtuiybSK4PtGo+v3FTU2rDWrlMrMq8a7OYySw841PXr+qqa8Ee0hjLjpHyJPc+cIoREVAAAAAB2GP/EACYRAAIBAgUCBwAAAAAAAAAAAAEDAgQFAAYHERITMRQXITJBUXH/2gAIAQMBAT8ArXXS43KpzNmC6SWEuZutbuL1tjIiKIQ7x/e22LdqBqQaCjKrCaiHRhxbJMiZjb3EjGoNFR+Y9rX4RPF0klg6Y2mSfUy+8KhBalrXCMYxiAIgbAAfAGP/2Q==',
  InProgress: 'data:image/jpeg;base64,/9j/4AAQSkZJRgABAQAAAQABAAD//gAfQ29tcHJlc3NlZCBieSBqcGVnLXJlY29tcHJlc3P/2wCEAAICAgICAgICAgIDAwMDAwQEBAQEBAcFBQUFBQcKBgcGBgcGCgkLCQgJCwkQDQsLDRATEA8QExcUFBcdGx0lJTIBAgICAgICAgICAgMDAwMDBAQEBAQEBwUFBQUFBwoGBwYGBwYKCQsJCAkLCRANCwsNEBMQDxATFxQUFx0bHSUlMv/CABEIABYAFgMBIgACEQEDEQH/xAAYAAEAAwEAAAAAAAAAAAAAAAAFAgMHCf/aAAgBAQAAAADrqRoZEa3gdL//xAAUAQEAAAAAAAAAAAAAAAAAAAAA/9oACAECEAAAAA//xAAUAQEAAAAAAAAAAAAAAAAAAAAA/9oACAEDEAAAAA//xAArEAABBAEDAwIFBQAAAAAAAAADAQIEBQYAByEREhMIIhQVIzJxQmFicqH/2gAIAQEAAT8AKTJN8ckyWBAyWxx7AsesC1hS1hFjz7qfH4kNbIT3BiBd7PZy9evOqHZ7aG+m5fDwd+V4zc4zbfLDWcS8mIdZKAHJ72+YxmmZ0KiKhG62xy3J33OR7X7imDIyTHwx5QrAA/CK2rJCq0M1BpwwiOarDMThHJxr02FHE23Pi5ujLTHMjvq6yEv3Nk/GlOj1/YjCNei6xyrzzANysxV2KNuMczXIWWKWUGWxD1pVjDjqyXHN2dRfS6941X8aN1vPUuZKg/atBt4+NZGHz4zWM4Z44H/y7AOf+F1l2190uUG3E2wyMOP5JIAINgKTHWRWWwg8DSaFitchGJwwzF7kTjSv9St6p6rzbeUCs6DNZRiTrEw+79YIxxgZ3f3cqa2929qNu6iTBgyZU+bPlPmWdnMehJc+W/7jGd/jWpw1OE1//8QAFBEBAAAAAAAAAAAAAAAAAAAAIP/aAAgBAgEBPwAf/8QAFBEBAAAAAAAAAAAAAAAAAAAAIP/aAAgBAwEBPwAf/9k=',
  NotStarted: 'data:image/jpeg;base64,/9j/4AAQSkZJRgABAQAAAQABAAD//gAfQ29tcHJlc3NlZCBieSBqcGVnLXJlY29tcHJlc3P/2wCEAAICAgICAgICAgIDAwMDAwQEBAQEBAcFBQUFBQcKBgcGBgcGCgkLCQgJCwkQDQsLDRATEA8QExcUFBcdGx0lJTIBAgICAgICAgICAgMDAwMDBAQEBAQEBwUFBQUFBwoGBwYGBwYKCQsJCAkLCRANCwsNEBMQDxATFxQUFx0bHSUlMv/CABEIABUAFQMBIgACEQEDEQH/xAAXAAADAQAAAAAAAAAAAAAAAAAFBwgJ/9oACAEBAAAAANcDZQU8olfKIrf/xAAUAQEAAAAAAAAAAAAAAAAAAAAA/9oACAECEAAAAA//xAAUAQEAAAAAAAAAAAAAAAAAAAAA/9oACAEDEAAAAA//xAApEAABBAIABQMEAwAAAAAAAAACAQMEBgUHABESISIIE0EQFCMxQlJy/9oACAEBAAE/AOVj3lY7JGj2PI4GhYDIv4tVxbyxp2anRl6ZKrJHyaiNF4eHdxeffh/026/hte/TpdjrGVBPxZLHZyYTyH8K6El10HR/sJivPjWt+zBSLNRNlyobVlq70YHpjaIxHyUOUJHFnNAvYCNAJHATsJovHpufaha9mU+QqN5WsWLOY7JNfzR4pjskHf8ALrbomJfTaVLzO2txWpqkSEAa1g8LjZ76H0iUtw5Mr2UL9KTYOCpJ8dXF71w+7mJeyqHZXqvZWYSNzHgjjKh5KOxzUGp0UiBDUP0DiEhjxTdqbj25m5NJC1YOtCCEj+QxuFM5ZAKeXs/dSXAaJfglEuXFFotf15X2a7XWXUaR1x9999xXZMuS6vU7Jkul3cdNe5Fx/8QAFBEBAAAAAAAAAAAAAAAAAAAAIP/aAAgBAgEBPwAf/8QAFBEBAAAAAAAAAAAAAAAAAAAAIP/aAAgBAwEBPwAf/9k='
};

export const AltTextForHomeworkProgressIcon = {
  Completed: 'complete-status',
  InProgress: 'inprogress-status',
  NotStarted: 'notstarted-status'
};

export const ExceedLoginLimit = {
  map: 'Login Exceed Error',
  warning: 'You have exceeded the number of active sessions. One of your previous sessions will end.',
  message: 'You have been logged out.',
  confirmText: 'Ok'
};

export const AlturaComponents = {
  Dashboard: 'Dashboard',
  Messages: 'Messages',
  Profile: 'Profile'
};

export const ProductName = {
  Altura: 'altura'
};

export const CsvDownloadParams = {
  contentType: 'text/csv'
};

export const EDITEDHOMEWORKSTATUS = {
  NotEdited: 0,
  Edited: 1,
  EditedAndSeen: 2,
  EditedSeenAndUpdated: 3,
};

export const ASSESSMENTNAME = 'assessments';
export const activityType = 'selectstudent';

export const REMEMBERMEDETAILS = {
  TITLE: "Stay Logged In?",
  MESSAGE: "Select this option to allow offline access to your course.",
  CONFIRMTEXT: "Save and continue"
};

export const ActivityRepresentationType = {
  AUDIO: "audio",
  STORY: "story",
  PICTURESTUDY: "pictureStudy",
  SLIDESHOW: "slideshow",
  VIDEO: "video",
  AUTHOR: "author",
  ACTIVITY: "activity"
};

export const productFrameworkTypeIdentifier = "MACX_IND";

export const pageNavigationConstant = {
  next: "next",
  previous: "previous"
};

export const DialogButtonsId = {
  buttonIdOne: 'Yes',
  buttonIdTwo: 'No'
};

export const updateDialogButtonsLabel = {
  buttonLabelOne: 'Update',
  buttonLableTwo: 'Remind me later'
};

export const ToastMessageType = {
  SUCCESS: "success",
  WARNING: "warning",
  ERROR: "error",
  INFO: "info"
};

export const NotesMessageType = {
  ADD: "Note added",
  DELETE: "Note deleted",
  UPDATE: "Note updated",
  ADD_NOTE: "Add a note",
  Empty: "Notes Empty",
  EditText: "Edit",
  Type: "note",
  buttonLabelCancel: "Cancel",
  buttonLabelDelete: "Delete",
};

export const NotesPDF = {
  book_title: "Book Title",
  exported_on: "Exported On",
  page_number: "Page Number",
  highlighted_text: "Highlighted Text",
  added_notes: "Added Notes",
  highlighted_color: "Highlighted Color",
  added_on: "Added On",
  export_pdf: "Export to PDF"
};

export const BookmarkMessageType = {
  ADD: "Bookmark added",
  Empty: "My Bookmarks empty",
  UPDATE: "Bookmark updated",
  DELETE: "Bookmark deleted",
  EditText: "Edit"
};

export const ErrorMessage = {
  ERR400: "Bad Request.",
  ERR401: "You need to log in to do this action.",
  ERR403: "You don't have permission to access the requested resource.",
  ERR404: "The requested resource does not exist.",
  ERR412: "Precondition Failed.",
  ERR500: "Internal Server Error.",
  ERR502: "The requested service  encountered a temporary error and was unable to complete your request.",
  ERR503: "The requested service is not available.",
  ERR504: "The requested service didn't respond in time.",
  ERR422: "Validation Error!"
};

export const searchEventType = `Global Text Search`;
export const searchErrorMsg = ` `;//purposely added 2 blank space as search reesult component give error for 1 blank string hence causes log out .Code fix needs to be done from UI component
export const NO_LINKS = "You don't have any links to view right now";
export const NO_RESULT = 'No results have been found';
export const NO_RECORD = 'No record found';
export const EMPTY_DOWNLOAD_TAB = 'My Downloads Empty';
export const EMPTY_ADD_CONTENT_TAB = 'All content downloaded';
export const NO_UNITS_DOWNLOADED = "You don't have any units downloaded";
export const ALL_CONTENT_DOWNLOADED = "You have downloaded all the content";
export const ADD_CONTENT_BTN = "Add content"
export const VIEW_DOWNLOAD_BTN = "View downloads"
export const DOWNLOAD_ALL_BTN = "Download All";
export const REMOVE_ALL_BTN = "Remove downloads";


export const SearchType = {
  GLOBAL_SEARCH: "globalSearch",
  NOTE_SEARCH: "showNoteSearch",
  BOOKMARK_SEARCH: "showBookMarkSearch",
  MY_DOWNLOADS:"myDownloads"
};
export class EpubActivity {
  public EpubType = 'epub:type';
  public PageListKey = 'page-list';
  public SplitConstantKey = '.xhtml#page';
  public CoverKey = 'Cover';
  public CoverDefaultValue = '000';
  public pageSpreadLeft = 'page-spread-left';
  public pageSpreadRight = 'page-spread-right';
  public pageSpreadCenter = 'page-spread-center';
}
export const courseDetailsMsg = {
  GAGTM_MSG: "Clicked On Resource Bank",
  GAGTM_VIEW_MSG: "course details viewed"
};

export const globalAccessibility = {
  SKIP_NAVIGATION_LABEL: 'Skip Navigation'
};

export declare type NodeType = 'content-unit' | 'unit' | 'lesson' | 'section' | 'activity-set';

export const menuList = {
  TABLE_OF_CONTENT: "Table of contents",
  QUICK_LINKS: "Quick links",
  GOTO_PAGE: "Go to page",
  SEARCH: "Search",
  MY_BOOKMARK: "My bookmarks",
  MY_NOTES: "My notes",
  EXTRA_ACTIVITIES: "Extra activities",
  ALL_ACTIVITIES: "All activities",
  MY_DOWNLOADS: "My downloads",
  READ_ALOUD: "Read aloud",
  REWARDS: "Rewards"
};

export const menuState = {
  OPEN: "Open Menubar",
  CLOSE: "Close Menubar",
}
export const ReadAloudLabels = {
  play: 'Play',
  pause: 'Pause',
  reload: 'Reload',
  dragDrop: 'Drag and drop',
  close: 'Close'
};
export const downloadAppText = {
  TITLE: "Free upgrade available",
  SUB_TITLE: "Download the new version of this app including bug fixes and improvements.",
  REMINDME_BTN_LABEL: "Remind me later",
  UPDATE_BTN_LABEL: "Update",
  BTNID_ONE: "buttonIdOne",
  BTNID_TWO: "buttonIdTwo",
};
export const meePlatformOSEnvId = {
  meePlatformWin64_App: "MEEPlatformWin64_App",
  meePlatformMac_App: "MEEPlatformMac_App",
  meePlatformNonAdminLinux64_App: "MEEPlatformNonAdminLinux64_App",
  meePlatformAdminLinux64_App: "MEEPlatformAdminLinux64_App"
};
export const AppOs = {
  Windows: "Windows",
  Mac: "Apple MacOS",
  Linux: "Linux - individual and admin",
  LinuxDeb: "Linux .deb - admin only"
};

export const AppOsTypes = {
  Window: "windows",
  Mac: "mac"
}

export const getdeviceType = {
  mobile: "mobile",
  tablet: "tablet",
  desktop: "desktop"
};
export const getOSType = {
  android: "Android",
  ios: "iOS"
};

export const Position = {
  Right: "Right",
  Left: "Left"
}

export const courselistRouterName = {
  courselist: 'courselist'
};

export const Percentage = {
  rightSideNumber: 80,
  middleSideNumber: 70,
  centerPageNumber: 50.0
}

export const indexCountIncrement = {
  index: 1
};

export const manifestVersionsUrl = {
  url: 'downloaded_files/manifestVersions'
};

export const componentType = ["MACXPD", "NHYPD", "CURPD"]
export const CALENDAR = {
  MIN_CHARACTER: 15,
  MAX_CHARACTER: 20,
  DAY_FOCUS: 710,
  WEEK_FOCUS: 780,
  VIEW_DAY: 'day',
  VIEW_MONTH: 'month',
  VIEW_WEEK: 'week',
};

export const RCFVALUE = {
  top: 2.5,
  singlePageTop: 2.5,
  left: 2,
  mobileLeftPagePosition: 3.5,
  desktopSinglePageLeft: 3.6,
  desktopSinglePageLandscape:0.5,
  singlePageBookLeft: 0.2,
  singlePageBookLeftMobile: 0,
  mobileSinglePageLeft: 3.8
};

export const CALCULATION = {
  doublePage: 0.0,
  singlePage: 0.07,
  mobileLeftPosition: 2.0,
  desktopRightPosition: 0.95,
  SinglePagePosition: 0.09,
  mobileRightPosition: 0.9,
  mobileRightMiddleIcon: 0.6,
};

export const RCFIconSizeSet = {
  mobileWidth: "6.25%",
  landScapeMobileWidth:"6.45%",
  DesktopWidth: "8%",
  largePageDesktopWidth:"6.45%"
};




export const MEETINGTYPE = {
  typeOfVideoCall: "Choose one",
  googleVideoCall: { label: "Google Meet", value: 0 },
  microsoftVideoCall: { label: "Microsoft Teams", value: 1 },
  zoomVideoCall: { label: "Zoom", value: 2 },
};

export const calendarEventApiType = { eventList: "eventList", eventDetails: "eventDetails" };

export const LastZonalOperationState = {
  CLOSE_BOOK: 'close_book',
  MY_NOTES: 'My Notes',
  CANVAS: 'canvas',
  ZOOM_CONTROLLER: 'zoom_controller'
};
export const RECURRENCE = {
  daily:'Daily',
  weekly:'Weekly',
  monthly:'Monthly',
  custom:'Custom'
};
export const ENDTYPE = {
  never:'NEVER',
  until:'UNTIL',
  after:'AFTER',
  day_limit:90,
  week_limit:12,
  month_limit:3,
};

export const iframeHTML = `<iframe id="RCFiframe" src="/rcf-player.html" frameborder="0"></iframe>
      <div class="rcf-resizers">
      <div class="rcf-resizer top-left"></div>
      <div class="rcf-resizer top-right"></div>
      <div class="rcf-resizer bottom-left"></div>
      <div class="rcf-resizer bottom-right"></div>
      <div id="RCFdraggable" class="bottomBarDraggable"></div>
      <div>`

export const keyboardEventCode = {
  ENTER : 'Enter',
  SPACE: 'Space'
};
export const applicationRole = {
  aria_label: '...'
}
export const tocType = {
  COMPONENT: 'component',
  PARTS: 'parts',
  UNIT: 'unit',
  CHAPTER: 'chapter',
  LESSON: 'lesson',
}
export const numbersEnum = {
  NUM_2: 2,
  NUM_3: 3,
  NUM_4: 4,
  NUM_DECIMAL_7: 0.7,
  NUM_DECIMAL_5: 0.5,
  NUM_DECIMAL_3: 0.3
}
export enum SubmitType {
  submitCloseGradable = 'submit-closed-gradable',
  submitOpenNonGradable = 'submit-open-non-gradable',
  submitOpenGradable = 'submit-open-gradable',
  submitOpenGradableFeedback = 'submit-open-gradable-feedback',
  submitNonGradable = 'submit-non-gradable',
  submitMixedGradable = 'submit-mixed-gradable',
  submitMixedWithOpenGradable = 'submit-mixed-with-open-gradable',
  submitMixedWithOpenGradableFeedback = 'submit-mixed-with-open-gradable-feedback'
}
export enum ActiveTabType {
  graded = 'graded',
  completed = 'completed',
  requiresGrading = 'requires-grading'
}

export enum SEARCHLABEL {
  search = "Search"
}

export enum ASSESSMENT_INDICATOR {
  notStarted = 'Not started',
  inProgress = 'In progress',
  exempt = 'Exempt',
  requiresGrading = 'Requires grading',
  completedViewProgress = 'Completed'
}

export enum HOMEWORK_INDICATOR {
  notStarted = 'Not started',
  inProgress = 'In progress',
  exempt = 'Exempt',
  requiresGrading = 'Requires grading',
  completed = 'Completed'
}

export enum ASSESSMENT_HOMEWORK_TABS_NAME {
  active = 'Active',
  future = 'Future',
  past = 'Past',
  draft = 'Draft'
}
export enum ASSESSMENT_FORMAT_TYPE {
  digital = 'Digital',
  paper = 'Paper',
  RCFHomework = 'RCF Homework'
}
export enum ASSESSMENT_DOWNLOAD_STATUS {
  success = 'Success',
  failed = 'Failed',
  temporarilyFailed = 'Temporarily Failed',
  notStarted = 'Not Started',
  inProgress = 'InProgress'
}
export enum deletePopupMessage {
  BOOKMARK_DELETE_CONFIRMATION = 'deleteBookmarkConfirmationFromSidePanel'
}
export enum ENTITY_TYPE {
  homework = 1,
  assessment = 2
}

export enum CACHE_DURATION {
  FIVE_MINUTES = 5,
  THIRTY_MINUTES = 30,
  ONE_HOUR = 60,
  TWELVE_HOURS = 60 * 12,
  ONE_DAY = 60 * 24,
  FIFTEEN_DAYS = 60 * 24 * 15,
  THIRTY_DAYS = 60 * 24 * 30
}
export enum TEST_DURATION {
  Teacher = 300
}
export const activeClassPrefix={
  classPrefix: 'cursor-icon-', activeClassSuffix: '--active'
}
export const focusClassPrefix = {
  classPrefix: 'cursor-icon-', activeClassSuffix: '--focus'
}
export const  classNames = ['hand', 'audio', 'video', 'story', 'picture-study', 'author', 'slide-show'];
export const  classNamesMultiActivity = ['hand-multiple', 'audio-multiple', 'video-multiple', 'story-multiple', 'picture-study-multiple', 'author-multiple', 'slide-show-multiple'];
export enum SECURITY_HEADERS_VALUES {
  deny = 'DENY'
}
export enum SECURITY_HEADERS_KEYS {
  XFrameOptions = 'X-Frame-Options'
}

export class OnBoardingUserRole {
  public static Teacher = 'instructor';
  public static Student = 'student';
  public static Admin = 'admin';
  public static Norole = 'norole';
}

export interface ShowExitPopupFlags {
  hasInputFieldEdited?: boolean;
}

export const OnBoardingDomainName  = ['mee', 'macx'];

export const GRADING_PAPER_ASSESSMENT_URL: string = '/school-work/grading/grading-paper-assessment';

export enum pageSpreadType {
  pageSpreadLeft = 'page-spread-left',
  pageSpreadRight = 'page-spread-right',
  pageSpreadCenter = 'page-spread-center',
  single = 'single',
  double = 'double',
}

export enum CREATEOFFLINEPACKAGETYPE {
  create_offline_for_windows = "Create offline for Windows",
  create_offline_for_mac     = "Create offline for Mac"
}

export enum OfflineUserUsbPath {
  usb_path = '/MeeApps/meeplatform-app/usb/'
}

export enum OfflineOsZip {
  mac = '-Mac.zip',
  windows = '-Win64.zip'
}
export const validresoursetype= ["Macxrb","Macxtg","Macxpd","Macxnpd","NHYNPD","NHYPD","CURRB","CURPD","CURNPD","CURASSESS","MACXASSESS","testgenerator","resourcepack","magicphonics","mactn","macdpsb","macdabeab","macdp","mactlp","planner"];
export const meecbType = ["meecb"]