<maced-dialog dialogId="dialog1">
    <maced-dialog-header dialogHeaderId="Default Dialog Header" icon="icon-alert-circle"
        [title]="offlineBookviewObj?.noInternet">
    </maced-dialog-header>
    <maced-dialog-content>
        <p class="c-modal-content__text c-modal-content__text--no-internet-connection" [attr.aria-label]="offlineBookviewObj?.themeNoInternetMessage" *ngIf="isThemeInternetStatus">
            {{offlineBookviewObj?.themeNoInternetMessage}}</p>
        <p class="c-modal-content__text c-modal-content__text--no-internet-connection" [attr.aria-label]="noInternetMessage">
            {{noInternetMessage}}</p>
    </maced-dialog-content>
    <maced-dialog-footer class="c-dialog__content-footer">
        <maced-button name="cancel" buttonId="savBtnId" label="{{offlineBookviewObj?.cancel_label}}" color="secondary"
            class="large-button" buttonType="submit" (click)="onCancel()">
        </maced-button>
        <maced-button buttonId="Yes" [label]="offlineBookviewObj?.try_again" color="primary"
            styleClass="u-button-normal" (click)="onTryAgain()">
        </maced-button>
    </maced-dialog-footer>
</maced-dialog>