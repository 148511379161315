import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { Data } from 'src/app/core/models/data';

@Component({
  selector: 'app-no-internet-connection-dialog',
  templateUrl: './no-internet-connection-dialog.component.html',
  styleUrls: ['./no-internet-connection-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class NoInternetConnectionDialogComponent implements OnInit, AfterViewInit {
  @Output() cancel: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() tryAgain: EventEmitter<void> = new EventEmitter<void>();
  @Input() isThemeNoInternet = false;
  @Input() noInternetMessage = '';
  public offlineBookviewObj: any;
  isThemeInternetStatus:boolean = false;

  constructor(private data: Data) {

  }

  ngOnInit() {
    if(this.isThemeNoInternet) {
      this.isThemeInternetStatus = true;
    }
    this.setLabels();
  }

  ngAfterViewInit(): void {
    let focusableElement = document.getElementById('dialog1');
    focusableElement?.setAttribute("tabindex", '-1');
    focusableElement?.focus();
  }

  setLabels() {
    this.offlineBookviewObj = this.data.languageJson?.dashboard?.offlineBookViewer;
    this.noInternetMessage = this.noInternetMessage ? this.noInternetMessage : this.offlineBookviewObj?.noInternetMessage;
  }

  onCancel() {
    this.cancel.emit(false);
  }

  onTryAgain() {
    this.tryAgain.emit();
  }
}
