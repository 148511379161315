import { Injectable } from '@angular/core';
import { SCHEMA, ActivityRepresentationType, RCFVALUE, CALCULATION, RCFIconSizeSet, Percentage } from '../models/enum';
import { RcfIconWithActivitySets } from '../models/rcf-models/rcfIcon';
import { Data } from 'src/app/core/models/data';

@Injectable({
  providedIn: 'root'
})
export class CourseRcfUtilityService {
  spotOnData: any;
  rcfData: any;
  iconList: RcfIconWithActivitySets[] = [];
  activitySets: any[] = [];
  iconArr: any = [];

  constructor(public data: Data) {}

  getActivitySetList(spotOnData: any, rcfData: any, pagePosition: any, currPage?: string) {
    this.spotOnData = spotOnData;
    this.rcfData = rcfData;
    this.activitySets = [];
    if (this.spotOnData.componentPageZoneMap && this.spotOnData.componentPageZoneMap.schemaVersion == SCHEMA.version3 || this.spotOnData.componentPageZoneMap.schemaVersion == SCHEMA.versionframeless1 || this.spotOnData.componentPageZoneMap.schemaVersion == SCHEMA.version4) {
      let pages = Object.values(this.spotOnData.componentPageZoneMap.pages);
      let page = pages.find((pageObj: any) => pageObj.id == pagePosition);
      if (page) {
        this.getIconIdFrameIdList(page,currPage);
      }
    }
    return this.activitySets;
  }
  getIconIdFrameIdList(pageObj: any, currPage?: string) {
    this.iconList = [];
    if (pageObj.frames && pageObj.frames.length > 0) {
      pageObj.frames.forEach((frameId: string) => {
        let frame = this.getFrameDetailsByframeId(frameId);
        if (frame && frame.icons && frame.icons.length) {
          frame.icons.forEach((iconId: string) => {
            this.iconList.push({ 'id': iconId, 'frameId': frameId });
          });
        } else {
          this.iconList.push({ 'id': frameId, 'frameId': frameId });
        }
      });      
    } else if(pageObj.icons && pageObj.icons.length > 0){
      pageObj.icons.forEach((iconId: string) => {
        this.iconList.push({ 'id': iconId, 'frameId': iconId });
      });
    }
    this.getIconDetailList(pageObj,currPage);
  }

  getFrameDetailsByframeId(frameId: string) {
    let frames = Object.values(this.spotOnData.componentPageZoneMap.frames);
    let frame: any = frames.find((frameObj: any) => frameObj.id === frameId);
    return frame;
  }

  getIconDetailList(pageObj: any, currPage?: string) {
    let icons: RcfIconWithActivitySets[] = [];
    if (this.iconList && this.iconList.length > 0) {
      this.iconList.forEach((iconObj: RcfIconWithActivitySets) => {
        if (iconObj) {
          let icon = this.getIconDetailsByiconId(iconObj.id);
          if (icon) {
            icon.frameId = iconObj.frameId;
            icon.activitySets = [];
            icon.rcfActivitySets.forEach((rcfActivitySetId: string) => {
              if(this.rcfData && this.rcfData.activitySets) {
                let iconData = this.getActivitySetDetailsByRcfActivitySetId(rcfActivitySetId,currPage);
                if (iconData) {
                  icon.activitySets.push(iconData);
                }
              }
            });
            icons.push(icon);
          }
        }
      });

      let activitySetByPage = {
        pageNo: pageObj.id,
        icons: icons
      };
      this.activitySets.push(activitySetByPage);
    }
  }

  getIconDetailsByiconId(iconId: string) {
    if (this.spotOnData && this.spotOnData.componentPageZoneMap && this.spotOnData.componentPageZoneMap.icons) {
        let icons: any = Object.values(this.spotOnData.componentPageZoneMap.icons);
        let icon: any = icons.find((iconObj: any) => iconObj.id === iconId);
        return icon;
    } else {
       
        return null; 
    }
}


  getActivitySetDetailsByRcfActivitySetId(rcfActivitySetId: string, currPage?: string) {
      let activitySetObj = this.rcfData.activitySets.find((activitySets: any) =>
      activitySets.activitySetId === rcfActivitySetId);
        return activitySetObj;
    }

  getPagePosition(spotOnData: any, pageIndex: number) {
    if (spotOnData && spotOnData.componentPageZoneMap) {
      return spotOnData.componentPageZoneMap.bookPageOrder[pageIndex];
    }
  }

  getIconType(activityRepresentationType: any, rcfActivitySets?:any) {
    let icon;
    let isMultiActivity = (rcfActivitySets && rcfActivitySets?.length > 1) ? true : false;
    switch (activityRepresentationType) {
      case ActivityRepresentationType?.AUDIO:
        icon = isMultiActivity? 'cursor-icon-audio-multiple':'cursor-icon-audio';
        break;
      case ActivityRepresentationType?.STORY:
        icon = isMultiActivity? 'cursor-icon-story-multiple':'cursor-icon-story';
        break;
      case ActivityRepresentationType?.PICTURESTUDY:
        icon = isMultiActivity? 'cursor-icon-picture-study-multiple':'cursor-icon-picture-study';
        break;
      case ActivityRepresentationType?.SLIDESHOW:
        icon = isMultiActivity? 'cursor-icon-slide-show-multiple':'cursor-icon-slide-show';
        break;
      case ActivityRepresentationType?.VIDEO:
        icon = isMultiActivity? 'cursor-icon-video-multiple':'cursor-icon-video';
        break;
      case ActivityRepresentationType?.AUTHOR:
        icon = isMultiActivity? 'cursor-icon-author-multiple':'cursor-icon-author';
        break;
      case ActivityRepresentationType?.ACTIVITY:
        icon = isMultiActivity? 'cursor-icon-hand-multiple':'cursor-icon-hand';
        break;
      case '':
      case null:
        icon = isMultiActivity? 'cursor-icon-hand-multiple':'cursor-icon-hand';
        break;
      default:
        icon = isMultiActivity? 'cursor-icon-hand-multiple':'cursor-icon-hand';
    }
    return icon;
  }

  hideIcon() {
    let icons = document.getElementsByClassName('icon-wrap');
    while (icons.length > 0) icons[0].remove();
  }

  groupBy(list: any, keys: any) {
    return list.reduce(function (rv: any, x: any) {
      let newKey = '';

      keys.forEach((key: any) => {
        if (newKey) {
          newKey = newKey + '/' + x[key];
        } else {
          newKey = x[key];
        }
      });

      (rv[newKey] = rv[newKey] || []).push(x);
      return rv;
    }, {});
  }

  showMultipleIcons(iconsArray: any,isMobileBrowser?:any, position?:any) {
    let leftValueData = 0;
    let isLandscapePage = false;
    let resArr: any = [];
    iconsArray.forEach((item: any, index:any) => {
      if(item.activitySets && item.activitySets.length && item.activitySets[0] !== undefined) {
        resArr.push({ id: item.id, art: item.activitySets[0].activityRepresentationType, rcfActivitySets:item.rcfActivitySets, posX: item.posX, posY: item.posY, indexIcon:index });
      }
    });
    let obj = this.groupBy(resArr, ['posX', 'posY']);
    for (const key in obj) {
      let x = key as keyof typeof obj;
      let leftvalue: number | bigint;
      let element: HTMLElement = document.getElementById('fixed-book-frame') as HTMLElement;
      let singlePageDiv = document.getElementsByClassName('fixed-page-frame-center');
      let div = document.createElement('div');
      let mobilePageWidth = parseFloat(element?.style?.width || '0');
      let mobilePageHeight = parseFloat(element?.style?.height || '0');
      if(mobilePageWidth > mobilePageHeight){
        isLandscapePage = true;
      }
      if (singlePageDiv && singlePageDiv?.length === 0) {
        leftValueData = RCFVALUE.left;
        leftvalue = Number(obj[x][0]['posX']) - (Number(obj[x][0]['posX']) * CALCULATION.doublePage);
      } else {        
        if(isMobileBrowser){
            leftValueData = RCFVALUE.left;
            if(position === 'Left'){
            leftValueData = RCFVALUE.mobileLeftPagePosition;
              leftvalue = Number(obj[x][0]['posX']) * CALCULATION.mobileLeftPosition; 
            }
           else if (position === 'Single') {
            leftValueData = (Number(obj[x][0]['posX']) >= Percentage.centerPageNumber) ? RCFVALUE.singlePageBookLeftMobile : RCFVALUE.mobileSinglePageLeft;
            leftvalue = Number(obj[x][0]['posX']) - (Number(obj[x][0]['posX']) * CALCULATION.SinglePagePosition);
            if(isLandscapePage){
              let posX = Number(obj[x][0]['posX']);
              leftValueData=this.calculateLeftValue(posX,true)
            }
            }
            else{
              // this is check right side rcf icon of right side page of book
              if (Number(obj[x][0]['posX']) >= Percentage.rightSideNumber) {
                leftvalue = Number(obj[x][0]['posX']) * CALCULATION.mobileRightPosition + 6.4;
              } 
              // this is to check middle rcf icon position for right side page of book
              else if (Number(obj[x][0]['posX']) >= Percentage.middleSideNumber && Number(obj[x][0]['posX']) <= Percentage.rightSideNumber) {
                leftvalue = Number(obj[x][0]['posX']) * CALCULATION.mobileRightMiddleIcon;
              }
              // this is to check left side rcf icon on right side page of book
              else {
                leftvalue = Number(obj[x][0]['posX']) - (Number(obj[x][0]['posX']) * CALCULATION.desktopRightPosition);
              }
            }
            div.style.width = RCFIconSizeSet.mobileWidth;
            div.style.paddingBottom = RCFIconSizeSet.mobileWidth;
               
        }
        else{
          leftvalue = Number(obj[x][0]['posX']) - (Number(obj[x][0]['posX']) * CALCULATION.singlePage);
          leftValueData = (Number(obj[x][0]['posX']) >= Percentage.centerPageNumber)? RCFVALUE.singlePageBookLeft : RCFVALUE.desktopSinglePageLeft;
          //this is to check large page size book of rcf icons position 
            if(isLandscapePage){
              let posX = Number(obj[x][0]['posX']);
              leftValueData = this.calculateLeftValue(posX,false);
              div.style.width =  RCFIconSizeSet.largePageDesktopWidth;
              div.style.paddingBottom = RCFIconSizeSet.largePageDesktopWidth;
            }else{
              div.style.width =  RCFIconSizeSet.DesktopWidth; 
              div.style.paddingBottom = RCFIconSizeSet.DesktopWidth; 
            }
        }
      }      
      
      if(obj[x]?.length > 1){
        let iconIds:any[]=[];
        obj[x].forEach((elementObj:any) => {
          iconIds.push(elementObj.id);
        });
        div.id = iconIds.toString();
      } else {
        div.id = obj[x][0]['id'];
      }
      let iconType = this.getIconType(obj[x][0]['art'], obj[x][0]?.rcfActivitySets)
      div.className = 'icon-wrap';
      div.innerHTML = `<div tabindex="0" role="button" aria-label="rcf${obj[x][0].indexIcon + 1}" class="${iconType}"></div>`;
      div.style.left = `${leftvalue - leftValueData}%`;
      div.style.top = this.activitySets[0]?.pageNo?.includes("-")? `${obj[x][0]['posY'] -  RCFVALUE.top }%`: `${obj[x][0]['posY'] - RCFVALUE.singlePageTop }%`;
      if(!this.activitySets[0]?.pageNo?.includes("-")){
        div.style.transform="scale(" + .92 + ")";
      }
      let divObject = document.getElementById(div.id);
      if (!divObject && element) {
            element.appendChild(div);
      }else{
        if (element && document) {
          document.getElementById(div.id)?.remove();
          element.appendChild(div);
        }
      }
    }   
    this.data.rcfIConsInserted.next(true);
  }

public calculateLeftValue(posX: any, isMobile: boolean) {
  // Constants initialization
  const { desktopSinglePageLeft, desktopSinglePageLandscape, mobileSinglePageLeft, singlePageBookLeftMobile, singlePageBookLeft } = RCFVALUE;
  const DESKTOP_ADJUSTMENT = 3;
  const MOBILE_ADJUSTMENT = 4;
  const CENTER_PAGE_NUMBER = Percentage.centerPageNumber;
  const THRESHOLD = 40.0;

  // Set mobile or desktop specific values
  const baseValue = isMobile ? mobileSinglePageLeft : desktopSinglePageLeft;
  const singlePageBookLeftValue = isMobile ? singlePageBookLeftMobile : singlePageBookLeft;
  const adjustment = isMobile ? MOBILE_ADJUSTMENT : DESKTOP_ADJUSTMENT;

  // Calculate and return value based on posX
  if (posX >= CENTER_PAGE_NUMBER) {
      return singlePageBookLeftValue - adjustment;
  }
  if (posX >= THRESHOLD) {
      return baseValue - adjustment;
  }
  return desktopSinglePageLandscape;
}


}  

