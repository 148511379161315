<div [ngClass]="emptyDekstopSystemErrorTitle === accessDeniedLabel ? 'error-container':''">
    <maced-error-state [id]="emptyDekstopSystemErrorId" [ariaLabels]="ariaLabels" [imageUrl]="emptyDekstopSystemErrorImg"
        [title]="emptyDekstopSystemErrorTitle">
        <ng-container ngProjectAs="[content-body]">
            <h2 class="c-error-state__name">{{ errorStatusCode }}</h2>
            {{ emptyDekstopSystemErrorMessage }}
        </ng-container>
        <ng-container ngProjectAs="[content-footer]">

            <maced-button *ngIf="!showGatewayTimeOutloaderFlag && emptyDekstopSystemErrorTitle !== accessDeniedLabel && !isContentAccessible "
                buttonId="Yes" label="{{tryAgain}}" color="primary" styleClass="u-button-normal" (click)="toggleTryAgain()"
                buttonType="submit">
            </maced-button>
            <maced-button *ngIf="emptyDekstopSystemErrorTitle === accessDeniedLabel || isContentAccessible" buttonId="goToBookshelfButton"
                [label]="bookshelfLabel" color="primary" type="link" styleClass="u-button-normal"
                url="/courses/courselist" buttonType="submit">
            </maced-button>
            <div *ngIf="showGatewayTimeOutloaderFlag" class="theme1">
                <span class="custom-loader"></span>
            </div>
        </ng-container>

    </maced-error-state>
</div>