import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, map, Observable, of, tap } from 'rxjs';
import { GlobalSettings } from 'src/app/global.settings';
import { ApiConstants } from '../constant/api.constant';
import { LoggerService } from './logger.service';
import { Data } from 'src/app/core/models/data';
import { AllActivitiesService } from './all-activities.service';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {


  notificationData = [];

  public baseUrl: string = ApiConstants.aceApiBaseUrl;
  public tocItems: Array<any> = [];
  activityFound = false;
  allActivitySetIds: any = [];
  constructor(
    public http: HttpClient,
    public data: Data,
    public loggerService: LoggerService,
    public allActivitiesService: AllActivitiesService
  ) {

  }

  viewNotification(reqBody: any): Observable<any> {
    if (GlobalSettings.isBrowser) {
      return this.viewNotificationBrowser(reqBody);
    } 
    else if (GlobalSettings.isNative) {
      return this.viewNotificationNative(reqBody);
    } else {
      return this.viewNotificationDesktop(reqBody);
    }

  }

  viewNotificationBrowser(reqBody: any) {
    const url = this.baseUrl + ApiConstants.viewNotificationUrl;
    console.log('notif url is:', url);
    return this.http.post<any>(url, reqBody)
      .pipe(
        tap(response => {
          return response;
        })
      );
  }
  viewNotificationNative(data: any) {
    return new Observable(observer => {
      viewUserNotificationsCallback(data, (response: any) => {
        console.log("calling response native viewnotification",response);
        response = JSON.parse(response);
        if (response.result) {
          observer.next(response.result);
        }
      });
    });
  }

  viewNotificationDesktop(reqBody: any): Observable<any> {
    let url= GlobalSettings.LocalNodeServerBaseUrl + '/api/notifications/markUserNotificationAsRead';
    return this.http.post<any>(url, reqBody)
    .pipe(
      tap(response => {
        return response;
      })
    );
  }

  getNotificationList(reqBody: any): Observable<any> {
    if (GlobalSettings.isBrowser) {
      return this.getNotificationListBrowser(reqBody);
    } 
    else if (GlobalSettings.isNative) {
      return this.getNotificationListNative(reqBody);
    } else {
      return this.getNotificationListDesktop(reqBody);
    }

  }

  getNotificationListBrowser(reqBody: any) {
    const url = this.baseUrl + ApiConstants.getNotificationsList;
    return this.http.post<any>(url, reqBody)
      .pipe(
        tap(response => {
          return response;
        })
      );
  }
  getNotificationListNative(data: any) {
    return new Observable(observer => {
      getNotificationDetailsCallback(data, (response: any) => {
        response = JSON.parse(response);
        if (response.result) {
          observer.next(response.result);
        }
      });
    });
  }

  getNotificationListDesktop(reqBody: any): Observable<any> {
    let url= GlobalSettings.LocalNodeServerBaseUrl + '/api/notifications/getUserNotifications';
    return this.http.post<any>(url, reqBody)
    .pipe(
      tap(response => {
        return response;
      })
    );
  }

  getNotificationsCount(): Observable<any> {
    if (GlobalSettings.isBrowser) {
      return this.getNotificationCountBrowser();
    }
    else if (GlobalSettings.isNative) {
      return this.getNotificationCountNative();
    } else {
      return this.getNotificationCountDesktop();
    }
  }

  getNotificationCountBrowser() {
    let url = this.baseUrl + ApiConstants.getnotificationcountUrl;
    return this.http.get<any>(url)
      .pipe(
        tap(response => response),
        catchError(this.handleError('getuserunreadnotificationscount', []))
      );
  }

  getNotificationCountNative(): Observable<any> {
    return new Observable(observer => {
      getUserUnReadNotificationsCountCallback((res: any) => {
        res = JSON.parse(res);
        if (res.error) {
          observer.next(res.error);
        } else {
          observer.next(res.result);
        }
      });
    });
  }

  getNotificationCountDesktop() {
    const url: string = `${GlobalSettings.LocalNodeServerBaseUrl}/api/notifications/getUserUnreadNotificationsCount`;
    return this.http.get<any>(url).pipe(
      map((data) => data),
      catchError(this.handleError('getAnswerForActivitySet', []))
    )
  }

  deleteNotification(reqBody: any): Observable<any> {
    if (GlobalSettings.isBrowser) {
      return this.deleteNotificationBrowser(reqBody);
    } 
    else if (GlobalSettings.isNative) {
      return this.deleteNotificationNative(reqBody);
    } else {
      return this.deleteNotificationDesktop(reqBody);
    }

  }

  deleteNotificationBrowser(reqBody: any) {
    const url = this.baseUrl + ApiConstants.getNotificationDeleteUrl;
    console.log('notif url is:', url);
    return this.http.post<any>(url, reqBody)
      .pipe(
        tap(response => {
          return response;
        })
      );
  }
  deleteNotificationNative(data: any):Observable<any> {
   return new Observable(observer => {
      deleteUserNotificationsCallback(data, (response: any) => {
        response = JSON.parse(response);
        if (response.result) {
          observer.next(response.result);
        }
      });
    });
  }

  deleteNotificationDesktop(reqBody: any): Observable<any> {
    let url= GlobalSettings.LocalNodeServerBaseUrl + '/api/notifications/deleteNotificationsDetails';
    return this.http.post<any>(url, reqBody)
    .pipe(
      tap(response => {
        return response;
      })
    );
  }
  handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      this.loggerService.logInfo(error);
      return of(result as T);
    };
  }
}