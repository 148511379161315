import { Injectable } from '@angular/core';
import { Data } from 'src/app/core/models/data';

@Injectable({
  providedIn: 'root'
})
export class HotspotService {
  public frameDataAvailable: boolean = false;
  public spotOnData:any = "";

  constructor(
    public data: Data
  ) { }

  showhideToggleDataCheck() {
    this.frameDataAvailable = false; // show and hid toggle and check frame data in entire component
    if (this.data.spotOnData && this.data.spotOnData.componentPageZoneMap) {
      this.spotOnData = this.data.spotOnData.componentPageZoneMap; 
    }
    if (this.spotOnData && this.spotOnData.frames && Object.values(this.spotOnData.frames).length) {
      this.frameDataAvailable = true;
      return this.frameDataAvailable;
    } else {
      return this.frameDataAvailable;
    }
  }

  checkCurrentPageFrameData() {
   if(this.frameDataAvailable){
    this.data.matchFramesData=[];
    if (this.data.spotOnData && this.data.spotOnData.componentPageZoneMap &&
      this.data.spotOnData.componentPageZoneMap.pages) {
        Object.values(this.data.spotOnData.componentPageZoneMap.pages).forEach((value: any) => {
          if (value && value.id && value.id === this.data.spineItemPageNumber && value.frames && value.frames.length > 0) {
            this.data.matchFramesData.push(value.frames);
          }
        });
    }
    this.doGreyToggleButton();
   }
  }
  doGreyToggleButton(){
    if (this.data.matchFramesData.length && this.data.currentZoomLevel === this.data.zoomDefault && this.data.framedZoomedOut) {
      let getAllID = document.querySelectorAll('#hotspotToggle');
      if (getAllID) {
        if(document.querySelectorAll('#hotspotToggle') && document.querySelectorAll('#hotspotToggle').length){
          document.querySelectorAll('#hotspotToggle')[0].removeAttribute('disabled');
        }
      }
    }
    else {
      let getAllID = document.querySelectorAll('#hotspotToggle');
      if (getAllID) {
        if(document.querySelectorAll('#hotspotToggle') && document.querySelectorAll('#hotspotToggle').length){
          document.querySelectorAll('#hotspotToggle')[0].setAttribute('disabled', 'disabled');
          this.data?.showPredefinedArea?.emit(false);
        }
      }
    }
  }

}
