import { EventEmitter, Injectable, NgZone } from '@angular/core';
import { WindowRefService } from '../core/services/window-ref.service';
import { Data } from '../core/models/data';
import { GlobalSettings } from '../global.settings';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { IpcService } from '../core/services/ipc.service';
import { Router } from '@angular/router';
import { ApiConstants } from '../core/constant/api.constant';
import { Observable, of, tap, catchError, Subject, BehaviorSubject, first } from 'rxjs';
import { IConstants } from '../core/models/constants';
import { ResourceDetails } from '../core/models/resource-details';
import { CourseService } from '../core/services/course.service';
import { CommonService } from '../core/services/common.service';
import { ICourse } from '../core/models/course';

@Injectable({
  providedIn: 'root'
})
export class UtilityService {
  //Managing the state of the menu Toggle data fetched.
  private readonly _menuToggleData = new BehaviorSubject<any>(undefined);

  //An access point to state data of the menu Toggle.
  readonly menuToggleData$ = this._menuToggleData.asObservable();
  public menuShowHideNotifier: EventEmitter<boolean> = new EventEmitter();
  window: any;
  public isNotesModeOn = new Subject<boolean>();
  HYBRID_RESOURCETYPES: any;
  private quickLinkSubject = new BehaviorSubject<any>("");
  quickLinkSubjectObs$ = this.quickLinkSubject.asObservable();

  constructor(
    private windowRefService: WindowRefService,
    public data: Data,
    private router: Router,
    private ngZone: NgZone,
    private http: HttpClient,
    public constants: IConstants,
    public courseService: CourseService,
    public commonService: CommonService
  ) {
    this.window = windowRefService.nativeWindow;
    this.HYBRID_RESOURCETYPES = GlobalSettings.HYBRID_RESOURCETYPES ? GlobalSettings.HYBRID_RESOURCETYPES.split(',') : [];

  }
  baseUrl: string = ApiConstants.aceApiBaseUrl;

  isObjectEmpty(obj: any) {
    for (var key in obj) {
      if (obj.hasOwnProperty(key)) {
        return false;
      }
    }
    return true;
  }
  public checkDeviceOS() {
    const iDevices = [
      'iPad Simulator',
      'iPhone Simulator',
      'iPod Simulator',
      'iPad',
      'iPhone',
      'iPod'
    ];
    if (!!navigator.platform) {
      while (iDevices.length) {
        if (this.window.navigator.platform === iDevices.pop()) { return true; }
        else if (this.window.navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1) { return true; }
      }
    }
    return false;
  }

  addEncryptionContext(currentEncryptionContext: any): any {

    if (this.data.encryptionContext.length === 0) {
      this.data.encryptionContext.push(currentEncryptionContext);
    } else {
      let isPresent = false;
      for (var encryptionContext of this.data.encryptionContext) {
        if (JSON.stringify(encryptionContext) === JSON.stringify(currentEncryptionContext)) {
          isPresent = true;
        }
      }
      if (!isPresent) {
        this.data.encryptionContext.push(currentEncryptionContext);
      }
    }
    if (GlobalSettings.isNative) {
      const variables = {
        'encryptionContext': this.data.encryptionContext
      };
      saveAngularVariablesToMobileDevice(variables, (response: any) => {
      });
      return new Observable();
    } else {
      //Call node api to store all encryption context
      let url: string = GlobalSettings.LocalNodeServerBaseUrl + '/api/decrypt/getAllEncryptionContext';
      return this.http.post<any>(url, { params: this.data.encryptionContext, env: environment.name, macxClientId: GlobalSettings.MACXClientId });
    }
  }

  checkInternetConnectivity(bypass?: boolean): Observable<any> {
    if (GlobalSettings.isNative) {
      return new Observable(observer => {
        getIsUserOnlineCallback((status: any) => {
          status = JSON.parse(status).result;
          observer.next(status);
        });
      });
    } else {
      if (GlobalSettings.isBrowser) {
        return of(true);
      } else {
        if( this.data.isLoginIn && !bypass){
          return of(this.data.isInternet);
        }else{
          let url: string = GlobalSettings.LocalNodeServerBaseUrl + '/api/utility/checkInternetConnectivity?env=' + environment.name;
          return this.http.get<boolean>(url);
        }
      }
    }
  }

  //For Fetching all the contentUnitId's from the Manifest
  getAllContentUnitIds(rcfManifest: any): any {
    let currentEncryptionContext;
    let iosEncryptionContext = '';
    if (!rcfManifest || !rcfManifest.contentUnits) {
      return;
    }
    if (!this.checkDeviceOS()) {
      for (let currentContentUnit of rcfManifest.contentUnits) {
        if ((currentContentUnit.series).length > 13) {
          currentContentUnit.series = (currentContentUnit.series).substring(0, 13);
        }
        currentEncryptionContext = {
          'courseName': currentContentUnit.series,
          'courseLevel': currentContentUnit.levels[0].level,
          'contentUnitId': currentContentUnit.levels[0].contentUnitID
        };
        this.addEncryptionContext(currentEncryptionContext);
        return currentEncryptionContext;

      }
    } else {
      for (let currentContentUnitObj of rcfManifest.contentUnits) {
        iosEncryptionContext = iosEncryptionContext + currentContentUnitObj.levels[0].contentUnitID + '_';
      }
      this.addEncryptionContextIOS(iosEncryptionContext);
    }
  }

  addEncryptionData(spotOnManifest: any): any {
    let iosEncryptionContext = '';
    if (!spotOnManifest || !spotOnManifest.componentPageZoneMap) {
      return;
    }
    if (!this.checkDeviceOS()) {
      if ((spotOnManifest.componentPageZoneMap.series).length > 13) {
        spotOnManifest.componentPageZoneMap.series = (spotOnManifest.componentPageZoneMap.series).substring(0, 13);
      }
      let currentEncryptionContext = {
        'courseName': spotOnManifest.componentPageZoneMap.series,
        'courseLevel': spotOnManifest.componentPageZoneMap.level,
        'contentUnitId': spotOnManifest.componentPageZoneMap.contentUnitID
      };
      this.addEncryptionContext(currentEncryptionContext).pipe(first()).subscribe();
    } else {
      iosEncryptionContext = iosEncryptionContext + spotOnManifest.componentPageZoneMap.contentUnitID + '_';
      this.addEncryptionContextIOS(iosEncryptionContext);
    }
  }

  addEncryptionContextIOS(iosEncryptionContext: any) {
    if (GlobalSettings.isNative && this.checkDeviceOS()) {
      const varibales = {
        'iosEncryptionContext': iosEncryptionContext
      };
      saveAngularVariablesToMobileDevice(varibales, (response: any) => {
      });
    }
  }

  checkInternetConnectivityFileExist(): Observable<any> {
    return new Observable((observer: any) => {
      this.checkInternetConnectivity().subscribe(internalStatus => {
        if (internalStatus && !this.data.isOfflineUser) {
          this.commonService.globalDekstopOfflineModePopUp$.next(false);
          this.commonService.globalDesktopOfflinePage$.next(false);
          observer.next(true);
        } else {
          let recourceDetails = new ResourceDetails(this.data.spotOnData?.componentPageZoneMap);
          let filePath = '/macmillanXHotspotMapping/' + recourceDetails.getResourceRootPath() + '/' + recourceDetails.getSourceFile().split('.')[0];
          const mimeTypeFilePath = filePath + '/mimetype';
          this.courseService.checkFileExists(mimeTypeFilePath).subscribe((fileExistStatus) => {
            if (fileExistStatus) {
              this.commonService.globalDekstopOfflineModePopUp$.next(false);
              this.commonService.globalDesktopOfflinePage$.next(false);
              observer.next(true);
            } else if (this.data.isOfflineUser) {
              this.commonService.globalSystemErrorPage$.next({showErrorPage:true,statusCode: 404, isContentAccessible: true, errDetails:"SoMe Msg"});
              observer.next(false);
            } else {
              this.commonService.globalDekstopOfflineModePopUp$.next(true);
              observer.next(false);
            }
          });
        }
      });
    });
  }

  getRandomBytes() {
    return (Math.random()+1).toString(36).slice(2,5);
  }

  generateGUID(): string {
    const crypto = window.crypto;
    //@ts-ignore
    return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
      (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
    );
  };

  openExternalURLCallback(externalUrl: any, subscriptionIds: any) {
    let data = {
      url: externalUrl,
      isExternal: true
    };
    if (GlobalSettings.isNative) {
      openExternalURLCallback(data, null);
    }
    else {
      new IpcService().send('openExternalLink', externalUrl);
    }
    this.ngZone.run(() => this.router.navigate(['/courses/course-details/' + subscriptionIds]));
  }
  updateAvailable(): Observable<any> {
    const productType = GlobalSettings.productType;
    if (GlobalSettings.isBrowser) {
      let url: string = this.baseUrl + ApiConstants.applicationBuildVersion;
      let params = new HttpParams().set('productType', productType);
      return this.http.get<any>(url, { params })
        .pipe(
          tap((response: any) => {
            return response;
          }),
          catchError(this.handleError('updateavailable', []))
        );
    } else if (GlobalSettings.isNative) {
      return new Observable(observer => {
        getApplicationVersionCallback(productType, ((status: any) => {
          status = JSON.parse(status).result;
          observer.next(status);
        }));
      });
    } else {
      const url: string = GlobalSettings.LocalNodeServerBaseUrl + '/api/account/getApplicationbuildversion';
      let params = new HttpParams().set('productType', productType).set('env', environment.name);
      return this.http.request('GET', url, { responseType: 'json', params })
        .pipe(
          tap(response => {
            return response;
          }),
          catchError(this.handleError('updateavailable', []))
        );

    }
  }

      checkLinuxUserType(): Observable<any> {     
          const url: string = GlobalSettings.LocalNodeServerBaseUrl + '/api/download/osStatus';          
          return this.http.request('GET', url, { responseType: 'json' })
            .pipe(
              tap(response => {
                return response;
              }),
              catchError(this.handleError('checklinuxusertype', []))
          );            
      }

      handleError<T>(operation = 'operation', result?: T) {
        return (error: any): Observable<T> => {
          return of(result as T);
        };
      }
  getDeviceFontUrl() {
    let deviceFontUrl = '/assets/default/fonts';
    if (this.checkDeviceOS()) {
      deviceFontUrl = '/dist/meeplatform/browser/assets/default/fonts';
    }
    else {
      deviceFontUrl = '/android_asset/dist/meeplatform/browser/assets/default/fonts';
    }
    let deviceFontStyle = `
        @font-face {
          font-family: 'mee-platform';
          src: url('${deviceFontUrl}/mee-platform.eot?9o4x5f');
          src: url('${deviceFontUrl}/mee-platform.eot?9o4x5f#iefix') format('embedded-opentype'),
              url('${deviceFontUrl}/mee-platform.ttf?9o4x5f') format('truetype'),
              url('${deviceFontUrl}/mee-platform.woff?9o4x5f') format('woff'),
              url('${deviceFontUrl}/mee-platform.svg?9o4x5f#mee-platform') format('svg');
          font-weight: normal;
          font-style: normal;
          font-display: block;
        },
        /* open-sans-regular - latin */
        @font-face {
          font-family: 'Open Sans';
          font-style: normal;
          font-weight: 400;
          src: url('${deviceFontUrl}/opensans/open-sans-regular.eot'); /* IE9 Compat Modes */
          src: local('Open Sans Regular'), local('OpenSans-Regular'),
              url('${deviceFontUrl}/opensans/open-sans-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
              url('${deviceFontUrl}/opensans/open-sans-regular.woff2') format('woff2'), /* Super Modern Browsers */
              url('${deviceFontUrl}/opensans/open-sans-regular.woff') format('woff'), /* Modern Browsers */
              url('${deviceFontUrl}/opensans/open-sans-regular.ttf') format('truetype'), /* Safari, Android, iOS */
              url('${deviceFontUrl}/opensans/open-sans-regular.svg#OpenSans') format('svg'); /* Legacy iOS */
        }
        /* open-sans-italic - latin */
        @font-face {
          font-family: 'Open Sans';
          font-style: italic;
          font-weight: 400;
          src: url('${deviceFontUrl}/opensans/open-sans-italic.eot'); /* IE9 Compat Modes */
          src: local('Open Sans Italic'), local('OpenSans-Italic'),
              url('${deviceFontUrl}/opensans/open-sans-italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
              url('${deviceFontUrl}/opensans/open-sans-italic.woff2') format('woff2'), /* Super Modern Browsers */
              url('${deviceFontUrl}/opensans/open-sans-italic.woff') format('woff'), /* Modern Browsers */
              url('${deviceFontUrl}/opensans/open-sans-italic.ttf') format('truetype'), /* Safari, Android, iOS */
              url('${deviceFontUrl}/opensans/open-sans-italic.svg#OpenSans') format('svg'); /* Legacy iOS */
        }
        /* open-sans-600 - latin */
        @font-face {
          font-family: 'Open Sans';
          font-style: normal;
          font-weight: 600;
          src: url('${deviceFontUrl}/opensans/open-sans-600.eot'); /* IE9 Compat Modes */
          src: local('Open Sans SemiBold'), local('OpenSans-SemiBold'),
              url('${deviceFontUrl}/opensans/open-sans-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
              url('${deviceFontUrl}/opensans/open-sans-600.woff2') format('woff2'), /* Super Modern Browsers */
              url('${deviceFontUrl}/opensans/open-sans-600.woff') format('woff'), /* Modern Browsers */
              url('${deviceFontUrl}/opensans/open-sans-600.ttf') format('truetype'), /* Safari, Android, iOS */
              url('${deviceFontUrl}/opensans/open-sans-600.svg#OpenSans') format('svg'); /* Legacy iOS */
        }
        /* open-sans-600italic - latin */
        @font-face {
          font-family: 'Open Sans';
          font-style: italic;
          font-weight: 600;
          src: url('${deviceFontUrl}/opensans/open-sans-600italic.eot'); /* IE9 Compat Modes */
          src: local('Open Sans SemiBold Italic'), local('OpenSans-SemiBoldItalic'),
              url('${deviceFontUrl}/opensans/open-sans-600italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
              url('${deviceFontUrl}/opensans/open-sans-600italic.woff2') format('woff2'), /* Super Modern Browsers */
              url('${deviceFontUrl}/opensans/open-sans-600italic.woff') format('woff'), /* Modern Browsers */
              url('${deviceFontUrl}/opensans/open-sans-600italic.ttf') format('truetype'), /* Safari, Android, iOS */
              url('${deviceFontUrl}/opensans/open-sans-600italic.svg#OpenSans') format('svg'); /* Legacy iOS */
        }
        /* open-sans-700italic - latin */
        @font-face {
          font-family: 'Open Sans';
          font-style: italic;
          font-weight: 700;
          src: url('${deviceFontUrl}/opensans/open-sans-700italic.eot'); /* IE9 Compat Modes */
          src: local('Open Sans Bold Italic'), local('OpenSans-BoldItalic'),
              url('${deviceFontUrl}/opensans/open-sans-700italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
              url('${deviceFontUrl}/opensans/open-sans-700italic.woff2') format('woff2'), /* Super Modern Browsers */
              url('${deviceFontUrl}/opensans/open-sans-700italic.woff') format('woff'), /* Modern Browsers */
              url('${deviceFontUrl}/opensans/open-sans-700italic.ttf') format('truetype'), /* Safari, Android, iOS */
              url('${deviceFontUrl}/opensans/open-sans-700italic.svg#OpenSans') format('svg'); /* Legacy iOS */
        }
        /* open-sans-700 - latin */
        @font-face {
          font-family: 'Open Sans';
          font-style: normal;
          font-weight: 700;
          src: url('${deviceFontUrl}/opensans/open-sans-700.eot'); /* IE9 Compat Modes */
          src: local('Open Sans Bold'), local('OpenSans-Bold'),
              url('${deviceFontUrl}/opensans/open-sans-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
              url('${deviceFontUrl}/opensans/open-sans-700.woff2') format('woff2'), /* Super Modern Browsers */
              url('${deviceFontUrl}/opensans/open-sans-700.woff') format('woff'), /* Modern Browsers */
              url('${deviceFontUrl}/opensans/open-sans-700.ttf') format('truetype'), /* Safari, Android, iOS */
              url('${deviceFontUrl}/opensans/open-sans-700.svg#OpenSans') format('svg'); /* Legacy iOS */
        }`
    let node = document.createElement('style');
    node.innerHTML = deviceFontStyle;
    document.body.appendChild(node);
  }
  loadMenuToggleState(fileName: string): Observable<any> {
    let url: string = `${environment.menuToggleStateUrl}/${fileName}.json`;
    if (GlobalSettings.isBrowser) {
      return this.http.get<any>(url).pipe(
        tap(res => {
          return res;
        }),
        catchError(this.handleError('loadMenuToggleState', []))
      );
    } else if (GlobalSettings.isNative) {
      return new Observable(observer => {
      let showHideFileUrl=`useruploadedfiles/menuconfig/meeplatform/${fileName}.json`
      readLanguageTranslationCallback(showHideFileUrl, ((res: any) => {
        if (typeof(res) === 'string'){
          res = JSON.parse(res);
        }
          observer.next(res);
        }));
      });
    }
    else {
      let fileUrl: string = `${fileName}.json`;
      //Prepare the url for node endpoint which will download the file for us to be availabel offline.
      let nodeApiUrl = GlobalSettings.LocalNodeServerBaseUrl + '/api/download/getLanguageData';

      return this.http.post<any>(nodeApiUrl, { fileName: fileUrl, env: environment.name })
        .pipe(
          tap(res => {
            return res;
          }),
          catchError(this.handleError('loadMenuToggleState', []))
        );
    }
  }

  isHybridUser(coursesList: any[]) {
    if (coursesList && coursesList.length > 0) {
      coursesList = coursesList.map(course => {
        if (course && Object.keys(course).length>0) {
          course.isHybrid = this.HYBRID_RESOURCETYPES.some((resourceType: any) => {
            return course.resources.find((resource:any) => resourceType == resource.resourceType.toUpperCase()) ? true : false
          })
        }
        return course;
      })
      let hybridCourseList = coursesList.filter(course => {
        if (!course) {
          course = new ICourse();
        }
        return course.isHybrid == true;
      })
      return (hybridCourseList.length == coursesList.length)
    }
    else if (this.data.isHybridUser) {
      return true;
    } 
    else {
      return false;
    }
  }

  getInternetSpeed() {
    const img$  = new Observable<any>(observer => {
     let imageAddr = environment.assetsCdnUrl + 'landing_image.png';
      
      let downloadSize = this.data.internetSpeedCheckFileSize ? this.data.internetSpeedCheckFileSize : this.constants.INTERNETSPEEDCHECKFILESIZE;
      let startTime, endTime;
      let download = new Image();
      download.onload = function () {
        endTime = (new Date()).getTime();
        let internetSpeed = showResults();
        observer.next(internetSpeed);
        observer.complete();        
      }

      download.onerror = function (err, msg) {
        observer.error(err);        
      }

      startTime = (new Date()).getTime();
      let cacheBuster = "?nnn=" + startTime;
      download.src = imageAddr + cacheBuster;

      function showResults() {
        let duration = (endTime - startTime) / 1000;
        let bitsLoaded = downloadSize * 8;
        let speedBps: any = (bitsLoaded / duration).toFixed(2);
        let speedKbps: any = (speedBps / 1024).toFixed(2);
        let speedMbps: any = (speedKbps / 1024).toFixed(2);
        return speedMbps;
      }
    });   
    return img$;
  }

  getCpuUsage(): Observable<any> {
    if (!GlobalSettings.isBrowser) {
      let url = GlobalSettings.LocalNodeServerBaseUrl + '/api/download/calculatecpu';
      return this.http.get<any>(url)
        .pipe(
          tap(response => {
            return response;
          }),
          catchError(this.handleError('calculatecpu', []))
        );
    } else {
      return of(0);
    }
  }

  

  getUserPreferenceFromDatabase(): Observable<any> {
    if (this.data.isInNative) {
      return new Observable(observer => {
        getLocalUserPreferenceCallback(this.data.aceToken?.MEEUserId,'isSnoozedTillLogout', response => {

          if (typeof response === 'string') {
            response = JSON.parse(response);
          }
          if (response.error) {
            observer.next(response.error);
          } else {
            observer.next(response.result);
          }
        });
      });
    } else {
      const url: string = GlobalSettings.LocalNodeServerBaseUrl + '/api/account/getuserpreference';
      let meeUserID = this.data.aceToken?.MEEUserId
      return this.http.post<any>(url, { meeUserID: meeUserID })
        .pipe(
          tap(activitySetManifest => {

          }),
          catchError(this.handleError('getDetailsOfActivities', []))
        );
    }
  }

  saveUserPreferenceToDatabase(response) {

    if (this.data.isInNative) {
      return new Observable(observer => {
        setLocalUserPreferenceCallback(this.data.aceToken?.MEEUserId,'isSnoozedTillLogout',response, result => {
          observer.next(result);
        });
      });
    } else {
      const url: string = GlobalSettings.LocalNodeServerBaseUrl + '/api/account/setuserpreference';
      if (this.data.aceToken) {
        return this.http.post<any>(url, { meeUserID: this.data.aceToken?.MEEUserId, userPreferenceObject: response })
          .pipe(
            tap(activitySetManifest => {

            }),
            catchError(this.handleError('getDetailsOfActivities', []))
          );
      }
    }
  }

  reRenderQuicklink(data:any){
    this.quickLinkSubject.next(data); 
  }
}
