<div class="moving-data-modal moving-data-modal--mapping" *ngIf ="isDataProcessing">
  <maced-dialog dialogId="Default Dialog">
      <maced-dialog-header dialogHeaderId="Default Dialog Header" [title]="importingDataMessage">
      </maced-dialog-header>
      <maced-dialog-content dialogContentId="Default Dialog Content">
          <p class="c-dialog-content__text">{{importingDataMessageBody}}</p>
          <img class="c-dialog-content__image" src="assets/default/images/moving-data.gif" alt="moving data">
      </maced-dialog-content>
  </maced-dialog>
</div>

<div class="moving-data-modal moving-data-modal--mapping" *ngIf="isDataMigrated">
  <maced-dialog dialogId="Default Dialog">
      <maced-dialog-header dialogHeaderId="Default Dialog Header" [title]="horray">
      </maced-dialog-header>
      <maced-dialog-content dialogContentId="Default Dialog Content">
          <p class="c-dialog-content__text"></p>
          <img class="c-dialog-content__image c-dialog-content__image--success" src="assets/default/images/data-magrated.gif" alt="data migrated">
      </maced-dialog-content>
      <maced-dialog-footer dialogFooterId="Default Dialog Footer">
        <div class = "modal-footer-button">
          <maced-button buttonId="saveFeedback" size="large" color="primary" label="Close" type="button"
              buttonType="submit" (click)="onSuccess()">
          </maced-button>
        </div>
      </maced-dialog-footer>
  </maced-dialog>
</div>
<maced-dialog dialogId="orientationId" *ngIf="ispopup">
  <maced-dialog-content>
    <h2 class="c-modal-content__title">{{horray}}</h2>
    <p class="c-modal-content__text">{{description}}</p>
  </maced-dialog-content>
</maced-dialog>