import { Injectable } from '@angular/core';
import { IpcRenderer } from 'electron';

@Injectable({
  providedIn: 'root'
})
export class IpcService {
  private _ipc: IpcRenderer | undefined;
  constructor() {
    if ((<any>window).ElectronRequire) {
      try {
        this._ipc = (<any>window).ElectronRequire('electron').ipcRenderer;
      } catch (e) {
        throw e;
      }
    }
  }
  public on(channel: string, listener: any): void {
    if (!this._ipc) {
      return;
    }
    this._ipc.on(channel, listener);
  }

  public send(channel: string, ...args: any): void {
    if (!this._ipc) {
      return;
    }
    this._ipc.send(channel, ...args);
  }
  public sendSync(channel: string, ...args: any): any {
    if (!this._ipc) {
      return;
    }
    return this._ipc.sendSync(channel, ...args);
  }
  public removeListener(channel: string) {
    if (!this._ipc) {
      return;
    }
    return this._ipc.removeAllListeners(channel);
  }
}