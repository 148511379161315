import { isPlatformBrowser } from '@angular/common';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable, PLATFORM_ID, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Observable, of, Subscription } from 'rxjs';
import { catchError, delay, tap } from 'rxjs/operators';
import { GlobalSettings } from 'src/app/global.settings';
import { UtilityService } from 'src/app/services/utility.service';
import { environment } from 'src/environments/environment';
import { ApiConstants } from '../constant/api.constant';
import { Data } from '../models/data';
import { getCentralizedLoginParams, getMachineIdentifierParams } from '../models/http-handler';

import { ACEToken, MEEToken } from '../models/token';
import { AuthService } from './auth.service';
import { CourseService } from './course.service';
import { TokenService } from './token.service';
import { CommonService } from './common.service';
import { ToastService } from 'src/app/services/toast.service';
import { ToastMessageType } from '../models/enum';
@Injectable({
  providedIn: 'root'
})
export class AccountService {
  constructor(
    private http: HttpClient,
    private deviceDetectorService: DeviceDetectorService,
    @Inject(PLATFORM_ID) private platformId: object,
    public data: Data,
    public commonService: CommonService,
    private router: Router,
    private tokenService: TokenService,
    private ngZone: NgZone,
    private cookieService: CookieService,
    private utilityService: UtilityService,
    private authService: AuthService,
    public courseService: CourseService,
    public toastService: ToastService,
  ) { }

  baseUrl: string = ApiConstants.aceApiBaseUrl;
  env: string = environment.name;
  deleteRedisSubscription$!: Subscription;
  public isApiCallInProgress: boolean = false;

  redirectToCentralizedURL(platform: string, strAppURL: string, isRegistration: string, isSkipUserCheck: string, machineIdentifier: string, isLoadTest: boolean): Observable<any> {
    if (GlobalSettings.isDesktop) {
      const url: string = GlobalSettings.LocalNodeServerBaseUrl + '/api/account/getResponseUrl';
      let params = new HttpParams().set('env', this.env).set('platform', platform).set('strAppURL', strAppURL).set('isSkipUserCheck', isSkipUserCheck).set('strMachineIdentifier', machineIdentifier).set('isLoadTest', isLoadTest);
      return this.http.request('GET', url, { responseType: 'json', params })
        .pipe(
          tap(response => {
            return response;
          }),
          catchError(this.handleError('getResponseUrl', [])));

    } else if (GlobalSettings.isBrowser) {
      let url: string;
      let centralizedLoginRedirectURL: string = ApiConstants.centralizedLoginUrl;
      url = `${this.baseUrl}${centralizedLoginRedirectURL}`;
      let params = getCentralizedLoginParams(platform, strAppURL, isSkipUserCheck, machineIdentifier, isLoadTest);

      return this.http.get<any>(url, { params: new HttpParams({ fromObject: params }) })
        .pipe(
          tap(response => {
            return response;
          }),
          catchError(this.handleError('redirectToCentralizedURL', []))
        );
    } else {
      return new Observable(observer => {
        const data = {
          platform: platform,
          strAppURL: strAppURL,
          isRegistration: isRegistration,
          isSkipUserCheck: isSkipUserCheck,
          strMachineIdentifier: machineIdentifier,
          isLoadTest: isLoadTest
        };
        getResponseUrlCallback(data, (result: string) => {
          result = JSON.parse(result);
          if (result) {
            observer.next(result);
          }
        });
      });
    }
  }

  redirectToCentralizedRegistrationURL(platform: string, isRegistration: string, isSkipUserCheck: string, strAppURL: string, machineIdentifier: string, isLoadTest: boolean): Observable<any> {
    if (GlobalSettings.isDesktop) {
      const url: string = GlobalSettings.LocalNodeServerBaseUrl + '/api/account/getResponseUrl';
      let params = new HttpParams().set('env', this.env).set('platform', platform).set('strAppURL', strAppURL).set('isRegistration', isRegistration).set('strMachineIdentifier', machineIdentifier).set('isLoadTest', isLoadTest);
      return this.http.request('GET', url, { responseType: 'json', params })
        .pipe(
          tap(response => {
            return response;
          }),
          catchError(this.handleError('redirectToCentralizedRegistrationURL', [])));

    } else if (GlobalSettings.isBrowser) {
      let url: string;
      let centralizeRegistrationRedirectURL: string = ApiConstants.centralizedLoginUrl;
      url = `${this.baseUrl}${centralizeRegistrationRedirectURL}`;
      let params = new HttpParams().set('platform', platform).set('strAppURL', strAppURL).set('isRegistration', isRegistration).set('machineIdentifier', machineIdentifier).set('isLoadTest', isLoadTest);
      return this.http.get<any>(url, { params: params })
        .pipe(
          tap(response => {
            return response;
          }),
          catchError(this.handleError('redirectToCentralizedRegistrationURL', []))
        );
    } else {
      return new Observable(observer => {
        const data = {
          platform: platform,
          strAppURL: strAppURL,
          isSkipUserCheck: isSkipUserCheck,
          isRegistration: isRegistration,
          isLoadTest: isLoadTest
        };
        getResponseUrlCallback(data, (result: string) => {
          result = JSON.parse(result);
          if (result) {
            observer.next(result);
          }
        });
      });
    }

  }
  setKmliData(key: any): Observable<any> {
    let url: string = ApiConstants.siteUrl + '/api/setKMLIData?key=' + key;
    return this.http.get<any>(url);
  }
  classAdmin(): Observable<any> {
    let baseUrl: string = ApiConstants.aceApiBaseUrl;
    let url: string = baseUrl + ApiConstants.classAdmin;
    return this.http.get(url, { responseType: 'text' });
  }
  getMachineIdentifier() {
    if (GlobalSettings.isBrowser) {
      let url = "";
      if (isPlatformBrowser(this.platformId)) {
        url = GlobalSettings.isCoreApi ? this.baseUrl + ApiConstants.machineIdentifierUrl
          : this.baseUrl + ApiConstants.machineIdentifierUrl + '/os/' + this.deviceDetectorService.os + '/browser/' + this.deviceDetectorService.browser + '/isNativeApp/' + 1;
      }
      else {
        url = this.baseUrl + ApiConstants.machineIdentifierUrl;
      }
      let machineIDParams = getMachineIdentifierParams(this.deviceDetectorService);
      return this.http.post(url, machineIDParams).pipe(tap(response => {
        return response;
      }),
        catchError(this.handleError('validateMEEToken', []))
      );
    } else if (GlobalSettings.isNative) {
      return new Observable(observer => {
        getMachineIdentifierCallback((result: any) => {
          result = JSON.parse(result);
          var response = [];
          if (result.MachineIdentifier) {
            response.push(result);
          }
          observer.next(response);
        });
      });
    } else {
      let url: string = GlobalSettings.LocalNodeServerBaseUrl + '/api/account/getmachineidentifier';
      return this.http.post<any>(url, {
        os: this.deviceDetectorService.os,
        browser: this.deviceDetectorService.browser,
        isNativeApp: 1,
        env: this.env
      })
        .pipe(
          tap(response => {
            return response;
          }),
          catchError(this.handleError('getmachineidentifier', []))
        );
    }
  }

  logout(kmliResponse: any) {
    if(!kmliResponse && GlobalSettings.isBrowser){
      return true;
    }
    let logoutUrl = `${environment.aceApiBaseCoreUrl}${ApiConstants.ssoLogout}`;
    let logoutredirect = environment.siteUrl;
    this.utilityService.checkInternetConnectivity().subscribe(internetStatus => {
      if (internetStatus) {
        if (this.data.deleteAPIFlag) {
          this.data.deleteAPIFlag = false;
          this.data.isDesktopBox = false;
          if (GlobalSettings.isBrowser) {
            this.data.isLoadingLgoin.next(true);
            this.commonService.deleteUserDeviceTokenBrowser().subscribe();
          }
          this.deleteRedisSubscription$ = this.tokenService.deleteRedisData().subscribe((data => {
            if (GlobalSettings.isBrowser) {
              this.http.get<any>('/api/deleteCacheData');
            }
          }));
        }
        if (this.data.aceToken?.MEEUserId) {
          let reqBody = { "key": `homeworkCourses_${this.data.aceToken?.MEEUserId}` };
          this.courseService.commonClearRedis(reqBody).subscribe();
        }
      }
    });
    this.commonService.setDefaultThemeClass();
    let currentUrl = window.location.href;
    if (GlobalSettings.isDesktop) {
      let rootUrl = currentUrl.split('/').slice(0, 3).join('/');
      logoutredirect = `http://localhost:` + new URL(rootUrl).port + '/index.html';
    } else if (GlobalSettings.isNative && GlobalSettings.routePath.includes('android')) {
      logoutredirect = 'http://localhost:8080' + GlobalSettings.routePath + '/index.html';
    } else if (GlobalSettings.isNative && !GlobalSettings.routePath.includes('android')) {
      logoutredirect = 'http://localhost:9080' + GlobalSettings.routePath + '/index.html';
    }
    this.utilityService.checkInternetConnectivity().subscribe(internetStatus => {
      if (!internetStatus) {
        this.data.isLoading.emit(false);
        this.data.isLoadingLgoin.emit(false);
        this.app_nativLogout('res', true,false);
      } else {
        this.sigmaLogout(logoutUrl, logoutredirect, kmliResponse);
      }
    });
    this.cookieService.delete('stopSnoozeTime');
    this.cookieService.delete('snoozeStartTime');
    this.cookieService.delete('isSnoozedTillLogoutBrowser');
    this.cookieService.delete('iwbMode');
    if (!GlobalSettings.isBrowser) {
      let userPref = {
        isSnoozedTillLogout: false
      }
      this.utilityService.saveUserPreferenceToDatabase(JSON.stringify(userPref).replace(/[']/g, '\'\'')).subscribe(resp => {
        // This is intentional
      });
    }
  }
  sigmaLogout(logoutUrl: any, logoutredirect: any, kmliResponse: any) {
    this.http.get<any>(logoutUrl + '/?appUrl=' + logoutredirect).subscribe(res => {
      if (GlobalSettings.isBrowser && kmliResponse) {
        this.data.aceToken = new ACEToken();
        this.data.meeToken = new MEEToken();
        if (isPlatformBrowser(this.platformId)) {
          this.data.isLoadingLgoin.next(true);
          this.tokenService.clearRedisData(res);
        } else {
          this.router.navigate(['/']);
        }
      } else {
        this.app_nativLogout(res, false,false);
      }
    });
  }
  app_nativLogout(res: any, isOffline: boolean,isSessionExpired: boolean,redirectUrl?: any) {
    const meeUserId: string | undefined = this.data.meeUserId ? this.data.meeUserId : this.data.aceToken?.MEEUserId;
    const machineIdentifier: string | undefined = this.data.machineIdentifier ? this.data.machineIdentifier : this.data.aceToken?.MachineIdentifier;
    const keepMeLoggedIn: boolean | undefined = this.data.isKMLIChecked ? this.data.isKMLIChecked : true;
    if (!meeUserId) { return; };
    this.logoutUser({ meeUserId, machineIdentifier, keepMeLoggedIn,isSessionExpired }).subscribe((status) => {
      if (status && this.router.url !== '/download') {
        this.data.aceToken = new ACEToken();
        this.data.meeToken = new MEEToken();
        this.data.isKMLIChecked = false;
        if (isOffline) {
          if (redirectUrl) {
            this.ngZone.run(() => location.href = encodeURI(redirectUrl));
          }
        } else {
          if (res && res.redirectionUrl) {
            this.ngZone.run(() => location.href = encodeURI(res.redirectionUrl));
          }
        }
      }
    });
  }

  fetchAppConfigurationForOfflineBookviewer(): Observable<any> {
    const url: string = GlobalSettings.LocalNodeServerBaseUrl + '/api/offlineBookViewer/getAppConfig';
    return this.http.get<any>(url, {})
      .pipe(
        tap(response => {
          return response;
        }),
        catchError(this.handleError('checkAppConfiguration', []))
      );
  }

  checkKMLIStatus(): Observable<any> {
    if (GlobalSettings.isNative) {
      return new Observable(observer => {
        checkKMLIStatusCallback(((res: any) => {
          res = JSON.parse(res);
          observer.next(res.result);
        }));
      });
    } else if(GlobalSettings.isDesktop) {
      const url: string = GlobalSettings.LocalNodeServerBaseUrl + '/api/login/checkKMLIStatus';
      return this.http.get<any>(url, {})
        .pipe(
          tap(response => {
            return response;
          }),
          catchError(this.handleError('checkKMLIStatus', []))
        );
    } else {
      of('handling in async way').pipe(delay(2000)).subscribe(() => {
        this.data.isLoading.next(false);
        this.data.isLoadingLgoin.next(false);
      });
      return of([])
    }
  }

  getRedis(): Observable<any> {
    if (GlobalSettings.isNative) {
      return new Observable(observer => {
        getRedisCacheCallback(((responseOfGetRedis: any) => {
          responseOfGetRedis = JSON.parse(responseOfGetRedis);
          if (responseOfGetRedis.error) { observer.next(responseOfGetRedis.error); }
          else { observer.next(responseOfGetRedis.result); }
        }));
      });
    } else {
      const url: string = GlobalSettings.LocalNodeServerBaseUrl + '/api/login/getRedis';
      let params = new HttpParams().set('meeUserId', this.data.meeUserId).set('machineIdentifier', this.data.machineIdentifier).set('keepMeLoggedIn', this.data.isKMLIChecked).set('accountId', this.data.accountId).set('env', environment.name);
      return this.http.request('GET', url, { responseType: 'json', params })
        .pipe(
          tap(response => {
            return response;
          }),
          catchError(this.handleError('getRedis', []))
        );
    }
  }

  setRedis(tokenResponse: any): Observable<any> {
    if (GlobalSettings.isNative) {
      return new Observable(observer => {
        setRedisCacheCallback(tokenResponse, ((responseOfSetRedis: any) => {
          responseOfSetRedis = JSON.parse(responseOfSetRedis);
          observer.next(responseOfSetRedis.result);
        }));
      });
    } else {
      const url: string = GlobalSettings.LocalNodeServerBaseUrl + '/api/login/setRedis';
      return this.http.post<any>(url, { token: tokenResponse, env: environment.name })
        .pipe(
          tap(response => {
            return response;
          }),
          catchError(this.handleError('setRedis', []))
        );
    }
  }

  // for desktop and native API call
  setRedisData(redisData: any): Observable<any> {
    if (GlobalSettings.isNative) {
      return new Observable(observer => {
        setRedisAPICallback(redisData, ((responseOfSetRedis: any) => {
          observer.next(responseOfSetRedis);
        }));
      });
    } else {
      const url: string = GlobalSettings.LocalNodeServerBaseUrl + '/api/login/setRedisAPI';
      return this.http.post<any>(url, { token: redisData, env: environment.name })
        .pipe(
          tap(response => {
            return response;
          }),
          catchError(this.handleError('setRedis', []))
        );
    }
  }

  logoutUser(logoutData: any): Observable<any> {
    if (GlobalSettings.isNative) {
      return new Observable(observer => {
        logoutUserCallback(logoutData, ((responseAfterLogout: any) => {
          responseAfterLogout = JSON.parse(responseAfterLogout);
          observer.next(responseAfterLogout.result);
        }));
      });
    } else {
      const url: string = GlobalSettings.LocalNodeServerBaseUrl + '/api/login/logout';
      let params = new HttpParams().set('meeUserId', logoutData.meeUserId).set('machineIdentifier', logoutData.machineIdentifier).set('keepMeLoggedIn', logoutData.keepMeLoggedIn).set('fcmToken', this.data.FMCtoken).set('env', environment.name).set('isSessionExpired',logoutData.isSessionExpired);
      return this.http.request('GET', url, { responseType: 'json', params })
        .pipe(
          tap(response => {
            return response;
          }),
          catchError(this.handleError('logoutUser', []))
        );
    }
  }

  setKMLI() {
    if (!GlobalSettings.isBrowser) {
      this.checkKMLIStatus().subscribe((status: any) => {
        this.data.isKMLIChecked = status && status.length > 0 && status[0].keepMeLoggedIn ? status[0].keepMeLoggedIn : false;
        this.data.meeUserId = status && status.length > 0 && status[0].meeUserId ? status[0].meeUserId : "";
        this.data.machineIdentifier = status && status.length > 0 && status[0].machineIdentifier ? status[0].machineIdentifier : "";
        this.data.accountId = status && status.length > 0 && status[0].accountId ? status[0].accountId : "";
        this.data.role = status && status.length > 0 && status[0].role ? status[0].role : "";
        this.data.organisationName = status && status.length > 0 && status[0].organisationName ? status[0].organisationName : "";
        
        if (this.data.isKMLIChecked || this.data.isOfflineUser) {
          this.utilityService.checkInternetConnectivity().subscribe(internetStatus => {
            if (internetStatus && !this.data.isOfflineUser) {
              this.getRedis().subscribe((redisData: any) => {
                this.data.loadMenuconfigAfterLogin.next(true);
                if (redisData === null || redisData === undefined) {
                  let currentUrl = window.location.href;
                  let logoutredirect = environment.siteUrl;
                  if (GlobalSettings.isDesktop) {
                    let rootUrl = currentUrl.split('/').slice(0, 3).join('/');
                    logoutredirect = `http://localhost:` + new URL(rootUrl).port + '/index.html';
                  } else if (GlobalSettings.isNative && GlobalSettings.routePath.includes('android')) {
                    logoutredirect = 'http://localhost:8080' + GlobalSettings.routePath + '/index.html';
                  } else if (GlobalSettings.isNative && !GlobalSettings.routePath.includes('android')) {
                    logoutredirect = 'http://localhost:9080' + GlobalSettings.routePath + '/index.html';
                  }
                      this.data.isLoading.emit(false);
                      this.data.isLoadingLgoin.emit(false);
                      this.app_nativLogout('res', true,true,logoutredirect);
                } else {
                  this.data.aceToken = redisData;
                  if (GlobalSettings.isDesktop && redisData.fbc) {
                    this.authService.startPushService(redisData);
                    let queryParams = {"resetAccountIs" : true}; 
                    if(this.data.globalQuerypath.path){
                      this.ngZone.run(() => this.router.navigate(['courses/courselist'],{ queryParams: queryParams}));
                    }else{
                      this.ngZone.run(() => this.router.navigate(['courses/courselist']));
                    }
                  }
                  if (GlobalSettings.isNative) {
                    this.commonService.PendingPushNotificationsNative().subscribe(res => {
                      if (Object.keys(res).length > 0) {
                        let redirect_url: any = res;
                        this.commonService.redirectUrl(redirect_url.click_action);
                      } else {
                        let queryParams = {"resetAccountIs" : true};
                      if(this.data.globalQuerypath.path){
                        this.ngZone.run(() => this.router.navigate(['courses/courselist'],{ queryParams: queryParams}));
                      }else{
                        this.ngZone.run(() => this.router.navigate(['courses/courselist']));
                      }
                      }
                    });
                  }
                }
              });
            } else {
              this.data.aceToken = new ACEToken;
              this.data.aceToken.Role = this.data.isOfflineUser ? '' : this.data?.role;
              this.data.aceToken.MEEUserId = this.data.isOfflineUser ? '' : this.data.meeUserId;
              this.ngZone.run(() => this.router.navigate(['courses/courselist']));
            }
          });
        }
      });
    } else {
      if (this.cookieService.get('isKMLI_ssr') == 'true') {
        this.router.navigate(['courses/courselist']);
      } else if (this.cookieService.get('isKMLI_ssr') == "false") {
        this.logout(false);
      }
    }
  }

  internetRestored(): Observable<any> {
    return new Observable(observer => {
      this.data.isInternetRestored.subscribe((internetStatus) => {
        if (internetStatus && GlobalSettings.isDesktop && !this.isApiCallInProgress && (this.data.aceToken && !this.data.aceToken.access_token) && !this.data.isOfflineUser) {
          this.isApiCallInProgress = true;
          this.getRedis().subscribe((redisData: any) => {
            this.isApiCallInProgress = false;
            if (redisData === null) {
              this.ngZone.run(() => this.router.navigate(['/logout']));
            } else {
              this.data.aceToken = redisData;
            }
          });
          observer.next(true);
        }
      });
    });
  }

  setConfigurationForOfflineBookviewer(): Promise<boolean> {
    return new Promise((resolve) => {
      if (GlobalSettings.isDesktop) {
        this.fetchAppConfigurationForOfflineBookviewer().subscribe((res) => {
          if (res.length > 0) {
            this.data.offlineAccessStartDate = res[0].startDate;
            this.data.offlineAccessEndDate = res[0].endDate;
            this.data.offlineOverDate = res[0].isOverDate;
            resolve(true);
          } else {
            resolve(true);
          }
        });
      } else {
        resolve(true);
      }
    });
  }

  handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      return of(result as T);
    };
  }
  ngOnDestroy(): void {
    if (this.deleteRedisSubscription$) {
      this.deleteRedisSubscription$.unsubscribe();
    }
  }
}
