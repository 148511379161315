

<maced-toast *ngIf="msgType" [toastId]="msgType" [message]="message" [type]="msgType"
ariaLabel="Close" [hasCloseButton]="isHasCloseButton" [modifiedIcon]="modifiedIcon" [hasDefinedIcon]="false" [hasModifiedIcon]="hasModifiedIcon"
#toastMessage>
<ng-container ngProjectAs="[toastButtonContent]">  
  <ng-container *ngIf="msgType === 'error' || msgType === 'warning'">
    <maced-link *ngIf="link.label!=''" id="link" [links]="link"></maced-link>
  </ng-container>
</ng-container>
</maced-toast>




