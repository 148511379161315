import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { CourseRcfUtilityService } from './course-rcf-utility.service';
import { NavigationEnd, Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AllActivitiesService {
  gotoBookPage$!: Observable<any>;
  private gotoBookPageSubject = new BehaviorSubject<string>('PAGE');
  currentUrl: string = "";
  constructor(private _courseRcfUtilityService: CourseRcfUtilityService,public router: Router,
  ) {
    this.gotoBookPage$ = this.gotoBookPageSubject.asObservable();
    this.router?.events?.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.currentUrl = event.url;
      }
    });
  }
  getAllData(response: any) {
    let Datalist: any = {
      courseComponent: [],
      courseUnits: [],
      courseParts: [],
      courseChapters: [],
      courseLessons: [],
      courseActivities: [],
      courseActivitySets: [],
      courseSections: []
    };
    if (response) {
      if (response.components) {
        Object.values(response.components).forEach((value: any) => {
          Datalist.courseComponent.push(value);
        });
      }
      if (response.parts) {
        Object.values(response.parts).forEach(value => {
          Datalist.courseParts.push(value);
        });
      }
      if (response.units) {
        Object.values(response.units).forEach(value => {
          Datalist.courseUnits.push(value);
        });
      }
      if (response.chapters) {
        Object.values(response.chapters).forEach(value => {
          Datalist.courseChapters.push(value);
        });
      }
      if (response.lessons) {
        Object.values(response.lessons).forEach(value => {
          Datalist.courseLessons.push(value);
        });
      }
      if (response.sections) {
        Object.values(response.sections).forEach(value => {
          Datalist.courseSections.push(value);
        });
      }
      if (response.activities) {
        Object.values(response.activities).forEach(value => {
          Datalist.courseActivities.push(value);
        });
      }
      if (response.activitySets) {
        Object.values(response.activitySets).forEach(value => {
          Datalist.courseActivitySets.push(value);
        });
      }
    }
    return Datalist
  }



  getFirstLayerData(list: any, menu: any) {
    let objmenu: any = [];
    let unit = list.courseUnits.find((i: any) => i.unitId == menu.id);
    if (unit?.lessons) {
      unit.lessons.forEach((lessonId: any) => {
        let lession: any = list.courseLessons.find((i: any) => i.lessonId == lessonId);
        let pageNo: any = '';
        if (lession?.activitySets) {
          let setId = lession?.activitySets[0];
          let sets = list.courseActivitySets.find((a: any) => a.activitySetId == setId);
          pageNo = (sets?.printPageReference) ? sets?.printPageReference[0] : "";
        }
        if (pageNo) {
          objmenu.push({ id: lession.lessonId, name: lession.title, icon: "icon-chevron-right", isContainSubMenu: true, page: pageNo, ...lession });
        } else {
          objmenu.push({ id: lession.lessonId, name: lession.title, icon: "icon-chevron-right", isContainSubMenu: true, ...lession });
        }

      });
    } else {
      if (unit?.activitySets) {
        unit.activitySets.forEach((activitySetId: any) => {
          let activitySet = list.courseActivitySets.find((a: any) => a.activitySetId == activitySetId);
          let classnameany = (activitySet?.activityRepresentationType) ? this._courseRcfUtilityService.getIconType(activitySet?.activityRepresentationType) : 'cursor-icon-hand';
          objmenu.push({ id: activitySet.activitySetId, name: activitySet.title, isActivity: true, trailingIcon: `${classnameany} activityIcon`, icon: 'icon-chevron-left',isContainSubMenu: false, ...activitySet });
        });
      }
    }
    return objmenu;
  }

  allActvitiyDataCheck(rcfData: any) {
    if (rcfData) {
      if (rcfData.activitySets && Object.values(rcfData.activitySets).length) {
          return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  getSecondLayerData(list: any, menu: any) {
    let objmenu: any = [];
    let lesson = list.courseLessons.find((i: any) => i.lessonId == menu.id);
    if (lesson?.sections) {
      lesson?.sections.forEach((sectionsId: any) => {
        let section: any = list.courseSections.find((s: any) => s.sectionId == sectionsId);
        objmenu.push({ id: section.sectionId, name: section.title, isTitle: true, ...section });

        if (section?.activitySets) {
          section.activitySets.forEach((activitySetId: any) => {
            let activitySets: any = list.courseActivitySets.find((a: any) => a.activitySetId == activitySetId);
            let classnameany = (activitySets?.activityRepresentationType) ? this._courseRcfUtilityService.getIconType(activitySets?.activityRepresentationType) : 'cursor-icon-hand';
            objmenu.push({ id: activitySets.activitySetId, name: activitySets.title, isActivity: true, iconClass: `${classnameany} icon-chevron-left`, icon: `${classnameany} icon-chevron-left`, isContainSubMenu: true, ...activitySets });
          });
        }
      });
    } else {
      if (lesson?.activitySets) { 
        lesson.activitySets.forEach((activitySetId: any) => {
          let activitySets = list.courseActivitySets.find((a: any) => a.activitySetId == activitySetId);
          let classnameany = (activitySets?.activityRepresentationType) ? this._courseRcfUtilityService.getIconType(activitySets?.activityRepresentationType) : 'cursor-icon-hand';
          const trailingIcon = this.currentUrl?.includes('extra-practices/dashboard') ? `${classnameany} activityIcon`: '';
          objmenu.push({ id: activitySets.activitySetId, name: activitySets.title, isActivity: true, trailingIcon: trailingIcon,iconClass: `${classnameany} icon-chevron-left`, icon: `${classnameany} icon-chevron-left`, isContainSubMenu: true, ...activitySets });
        });
      }
    }
    return objmenu;
  }
  baseLayer(list: any) {
    let objmenu: any = [];
    if (list?.courseUnits) {
      list?.courseUnits.forEach((item: any) => {
        let pageNo: any = '';
        if (item?.activitySets) {
          let setId = item?.activitySets[0];
          let sets = list.courseActivitySets.find((a: any) => a.activitySetId == setId);
          pageNo = (sets?.printPageReference) ? sets?.printPageReference[0] : '';
        }
        if (pageNo) {
          objmenu.push({ id: item.unitId, name: item.title, icon: "icon-chevron-right", isContainSubMenu: true, page: pageNo, ...item });
        } else {
          objmenu.push({ id: item.unitId, name: item.title, icon: "icon-chevron-right", isContainSubMenu: true, ...item });
        }

      });
    }
    return objmenu;
  }
  
  getAllActivitySet(list: any, type: string, unitIdOrLessonId?: string, downloadsView?: string) {
    let activitySetIds: any[] = [];
    let lessonsData = this.findLessonData(list, unitIdOrLessonId, downloadsView);
    if (list?.sections?.length > 0) {
      activitySetIds = this.extractActivitySetIdFromSectionsData(list, lessonsData, type);
    } else {
      activitySetIds = this.extractActivitySetIdFromLessonsData(lessonsData, type);
    }
    let activitySetData = activitySetIds?.map((activitySetId) => {
      return list.activitySets?.find((activity: any) => activity.activitySetId === activitySetId);
    });
    return activitySetData;
  }

  findLessonData(list: any, unitIdOrLessonId?: string, downloadsView?: string) {
    let lessonsData: any = [];
    if (downloadsView === "view_unit") {
      let selectedUnit = list.units?.find((x: any) => x.unitId === unitIdOrLessonId);
      if (list.lessons?.length > 0 && selectedUnit?.lessons && selectedUnit?.lessons instanceof Array) {
        lessonsData = selectedUnit?.lessons.map((lessonId) => {
          return list.lessons.find((lesson) => lesson.lessonId === lessonId);
        });
      } else {
        lessonsData = list.activitySets?.find((x: any) => x.activitySetId === selectedUnit?.activitySetId[0]);
      }
    } else if (downloadsView === 'view_lesson') {
      lessonsData = list.lessons.filter((lesson) => lesson.lessonId === unitIdOrLessonId);
    } else {
      if (list.lessons?.length > 0) {
        lessonsData = list.lessons?.filter((x: any) => x.lessonId === list.units[0]?.lessons[0]);
      } else {
        lessonsData = list.activitySets?.find((x: any) => x.activitySetId === list.units[0]?.activitySets[0]);
      }
    }
    return lessonsData;
  }

  extractActivitySetIdFromSectionsData(list: any, lessonsData: any, type: string) {
    let allSections: any[] = [];
    let activitySetIds: string[] = [];
    if (lessonsData instanceof Array && lessonsData.length) {
      lessonsData.forEach((lesson) => {
        if (lesson.sections instanceof Array) {
          let sectionsData = lesson.sections.map((sectionId) => {
            return list.sections.find((x: any) => x.sectionId === sectionId);
          });
          allSections.push(...sectionsData);
        }
      });
    }
    if (allSections.length) {
      if (type === "challenge") {
        allSections.forEach((sectionData) => {
          activitySetIds.push(...sectionData?.activitySets?.filter((obj: any) => (obj.includes('_challenge'))));
        });
      } else {
        allSections.forEach((sectionData) => {
          activitySetIds.push(...sectionData?.activitySets?.filter((obj: any) => (!obj.includes('_challenge'))));
        });
      }
    }
    return activitySetIds;
  }

  extractActivitySetIdFromLessonsData(lessonsData: any, type: string) {
    let activitySetIds: string[] = [];
    if (type === "challenge") {
      if (lessonsData instanceof Array) {
        lessonsData.forEach((lesson) => {
          activitySetIds.push(...lesson?.activitySets?.filter((obj: any) => (obj.includes('_challenge'))));
        });
      } else if (lessonsData?.activitySetId) {
        activitySetIds = [lessonsData?.activitySetId];
      } else {
        activitySetIds = lessonsData?.activitySets?.filter((obj: any) => (obj.includes('_challenge')));
      }
    } else {
      if (lessonsData instanceof Array) {
        lessonsData.forEach((lesson) => {
          activitySetIds.push(...lesson?.activitySets?.filter((obj: any) => (!obj.includes('_challenge'))));
        });
      } else if (lessonsData?.activitySetId) {
        activitySetIds = [lessonsData?.activitySetId]
      } else {
        activitySetIds = lessonsData?.activitySets?.filter((obj: any) => (!obj.includes('_challenge')));
      }
    }
    return activitySetIds;
  }

}
