import { Injectable } from '@angular/core';
import { GlobalSettings } from 'src/app/global.settings';
import { IConstants } from '../models/constants';
import { Data } from 'src/app/core/models/data';
import { GtmService } from './gtm.service';
import { Observable, Subject } from 'rxjs';
import { WhiteboardUtilityService } from './whiteboard-utility.service';
import { Zoom } from '../models/gtm-models/gtm';
@Injectable({
  providedIn: 'root'
})
export class ZoomControlService {
  fontSize: number = GlobalSettings.DEFAULT_LMS_ZOOM_SCALE;
  currZoomLvl: number = 100;
  zoomInOutData$!: Observable<any>;
  private zoomInOutDataSubject = new Subject<any>();
  constructor(
    private constants: IConstants,
    private data: Data,
    public gtmService: GtmService,
    public wbService: WhiteboardUtilityService
  ) {
    this.zoomInOutData$ = this.zoomInOutDataSubject.asObservable();
  }

  controlZoom(options: ZoomControlOptions, _readium?: any,) {
    let fontInc: number = 0;
    const baseCalculation = (this.constants.MININCREMENT / this.constants.MAXINCREMENT) * GlobalSettings.DEFAULT_LMS_ZOOM_SCALE;
    if (_readium && !_readium.ebook) {
      _readium.ebook = this.data?.readerObject?.ebook;
    }
    if (options?.reset == "reset") {
      this.fontSize = GlobalSettings.DEFAULT_LMS_ZOOM_SCALE;
      this.currZoomLvl = 100;
      this.data.currentZoomLevel = this.currZoomLvl;
      if (_readium.ebook) {
        _readium.ebook.reader.setZoom({ style: "fit-screen", scale: this.fontSize });
        this.wbService.zoomCanvas();
      }
    } else {
      if (options?.absolute !== null && options?.absolute !== undefined) {
        this.currZoomLvl = options.absolute;
        fontInc = baseCalculation + (baseCalculation * (options.absolute - 100) / this.constants.MININCREMENT);
        this.fontSize = fontInc;
      } else {
        this.currZoomLvl += options.inc;
        if (options.inc === this.constants.MININCREMENT) {
          fontInc = baseCalculation;
        } else {
          fontInc = baseCalculation * -1;
        }
        this.fontSize += fontInc;
      }
      this.data.currentZoomLevel = this.currZoomLvl;
      if (_readium?.ebook) {
        if (this.currZoomLvl <= 100) {
          _readium.ebook.reader.setZoom({ style: "fit-screen", scale: this.fontSize });
        } else {
          _readium.ebook.reader?.setZoom({ style: "user", scale: this.fontSize });
        }
        this.wbService.zoomCanvas();
      }
    }

    this.gtmService.eventTracking(Zoom, this.data.currentZoomLevel + ' % zoom');

  }
  setZoomInOut(data: ZoomControlOptions) {
    this.data.freeZoom = false;
    this.zoomInOutDataSubject.next(data);
  }
}

export interface ZoomControlOptions {
  inc?: number,
  absolute?: number,
  reset?: string
}
