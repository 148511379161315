import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DropdownOption } from '@maced/angular-components/lib/dropdown/dropdown.model';
import { Subject, takeUntil } from 'rxjs';
import { Data } from 'src/app/core/models/data';
import { LastZonalOperationState } from 'src/app/core/models/enum';
import { CommonService } from 'src/app/core/services/common.service';
import { HotspotService } from 'src/app/core/services/hot-spot.service';
import { LoggerService } from 'src/app/core/services/logger.service';
import { WhiteboardUtilityService } from 'src/app/core/services/whiteboard-utility.service';
import {ZoomControlService} from 'src/app/core/services/zoom-control.service';
import { GlobalSettings } from 'src/app/global.settings';
import { UtilityService } from 'src/app/services/utility.service';

@Component({
  selector: 'app-zoom-control',
  templateUrl: './zoom-control.component.html',
  styleUrls: ['./zoom-control.component.scss']
})
export class ZoomControlComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('zoomDropdown', { read: ElementRef }) zoomDropdown!: ElementRef;
  currZoomLvl: number = 100;
  dropdownOpenOnLoad: boolean = false;
  selectedValue: any = "";
  dropdownAriaLabel: string = "100% "+ this.data.languageJson?.bookviewer?.zoom_dropdown;
  dropdownId: string = "zoomDropdown";
  label: string = "Select";
  cheveronClass: string = "icon-chevron-down";
  iconClass: string = "myclass";
  disabled: boolean = false;
  isIconSvg: boolean = false;
  disableZoomButton=false;
  public destroy$: Subject<boolean> = new Subject();

  options: DropdownOption[] = [
    {
      id: "100",
      name: "100%",
      label: "100%",
      active: true
    },
    {
      id: "125",
      name: "125%",
      label: "125%",
      active: false
    },
    {
      id: "150",
      name: "150%",
      label: "150%",
      active: false
    },
    {
      id: "175",
      name: "175%",
      label: "175%",
      active: false
    },
    {
      id: "200",
      name: "200%",
      label: "200%",
      active: false
    },
    {
      id: "250",
      name: "250%",
      label: "250%",
      active: false
    },
    {
      id: "300",
      name: "300%",
      label: "300%",
      active: false
    },
    {
      id: "400",
      name: "400%",
      label: "400%",
      active: false
    }
  ];
  constructor(
    public data: Data,
    public router: Router,
    public actRoute: ActivatedRoute,
    public loggerService: LoggerService,
    public zoomControlService: ZoomControlService,
    public commonService:CommonService,
    public utilityService:UtilityService,
    public hotspotService:HotspotService,
    public whiteboardUtilityService: WhiteboardUtilityService,
    private _cd: ChangeDetectorRef
  ) {

  }
  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
  ngOnInit(): void {
    this.data.currentZoomLevel=100;
    this.loggerService.logInfo("zoom component initialized");
    this.selectedValue = {
      id: '',
      name: '',
      label: this.data.zoomDefault.toString() + "%",
    };
    if (!GlobalSettings.isBrowser) {
    this.disableZoomOfflineDevice();
    }

    this.data.isBluePatchActiveForZonalZoom.pipe(takeUntil(this.destroy$)).subscribe((status) => {
      this.disableZoomButton = status;
      this._cd.detectChanges();
    });

    this.disableZoomButton = false;
    this._cd.detectChanges();
  }
  
  ngAfterViewInit() {
    this.modifyDropdown();
  }

  modifyDropdown() {
    if (this.zoomDropdown && this.zoomDropdown.nativeElement) {
      let dropLabel = this.zoomDropdown.nativeElement.querySelector('.c-dropdown__button-text');
      if (dropLabel) {
        dropLabel.setAttribute('aria-live', 'polite');
      }
    }
  }

  checkDropdownState() {
    if (this.zoomDropdown && this.zoomDropdown.nativeElement) {
      let option: any = this.zoomDropdown.nativeElement.querySelectorAll('.c-dropdown__list-item-link');
      option.forEach((element: any) => {
        if (element.className.includes('disabled')) {
          element.setAttribute('aria-disabled', 'true');
        } else {
          element.setAttribute('aria-disabled', 'false');
        }
      });
    }
    return this.disableZoomButton === true;
  }
  /*disableZoomOfflineDevice: This function will disable Zoom dropDown if user is offline and didnt downloaded the book */
  disableZoomOfflineDevice(){
    this.commonService.displayReaderPage$.pipe(takeUntil(this.destroy$)).subscribe(status=>{
      if(!status){
        this.disableZoomButton=true;
      }else{
        this.disableZoomButton=false;
      }
    });
  }
  zoomInOut(inc?: number, abs?: number, reset?: string): void {
    this.zoomControlService.setZoomInOut({ inc: inc, absolute: abs, reset: reset });
    this.currZoomLvl = this.data.currentZoomLevel;
    this.selectedValue = {
      id: '',
      name: '',
      label: this.currZoomLvl.toString() + "%",
    };
    this.dropdownAriaLabel = this.currZoomLvl.toString() + "% " + this.data.languageJson?.bookviewer?.zoom_dropdown;
    this.data.framedZoomedOut = false;
    if(this.currZoomLvl == 100) {
      this.data.framedZoomedOut = true;
    }
    if(!this.data.isNoteEnabled && !this.whiteboardUtilityService.isCanvasEnabled) {
      this.hotspotService.doGreyToggleButton();
    }
    this._cd.detectChanges();
  }
  onClickMenu(menu: DropdownOption) {
    if(this.data.rightSidePanelOpen.value){
      this.data.rightSidePanelOpen.next(false);
    }
    const optionIndex = this.options.findIndex(f => f.id === menu.id);
    this.options.forEach(item => {
      item.active = false;
    });
    this.options[optionIndex].active = true;
    this.zoomInOut(undefined , parseInt(menu.id));

    this.data.lastZonalOperation = LastZonalOperationState.ZOOM_CONTROLLER;
    if (this.currZoomLvl === this.data.zoomDefault) {
      if(!this.data.isNoteEnabled && !this.whiteboardUtilityService.isCanvasEnabled) {
        this.utilityService.isNotesModeOn.next(false);
      }
    } else {
      this.utilityService.isNotesModeOn.next(true);
    }
  }

  //Zoom in/out/reset functionality on Ctrl +/-/0 for epub reader
  @HostListener('document:keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    if (!GlobalSettings.isBrowser) {
      if ((event.ctrlKey || event.metaKey) && event.code === 'Equal') {
        if (this.currZoomLvl <= 350) {
          this.zoomInOut(50);
        }
        event.preventDefault();
      } else if ((event.ctrlKey || event.metaKey) && event.code === 'Minus') {
        if (this.currZoomLvl >= 150) {
          this.zoomInOut(-50);
        }
        event.preventDefault();
      } else if ((event.ctrlKey || event.metaKey) && event.code === 'Digit0') {
        this.zoomInOut(1, undefined, 'reset');
        event.preventDefault();
      }
    }
  }

}
