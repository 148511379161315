import { Component, Inject, Input, OnInit, PLATFORM_ID, Renderer2 } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { DialogButtonsId } from 'src/app/core/models/enum';
import { Data } from 'src/app/core/models/data';

@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss']
})
export class DialogComponent implements OnInit {
  checkBboxNgModelNojs: boolean = false;
  @Input() dialogProps: any;

  btnIdOne: string = DialogButtonsId.buttonIdOne;
  btnIdTwo: string = DialogButtonsId.buttonIdTwo;
  isBrowser!: boolean;

  constructor(
    @Inject(PLATFORM_ID) platformId: Object,
    public _data: Data,
    private _renderer: Renderer2,
  ) {
    this.isBrowser = isPlatformBrowser(platformId);
  }

  ngOnInit() {
    document.getElementById('commonDialog')?.focus();
    this._renderer.addClass(document.body, 'modal-open');
  }

  dialogClicked(submitAction: string) {
    this.dialogProps.submitAction = submitAction;
    this._data.dialogSubmitEvent.emit(this.dialogProps);
  }
  ngOnDestroy(): void {
    this._renderer.removeClass(document.body, 'modal-open');
  }
}