import { EventEmitter, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { CommonService } from './common.service';
import { BehaviorSubject  } from 'rxjs';

@Injectable({
    providedIn: 'root'
  })
export class ReadiumReaderService {

    public currentReader$ = new BehaviorSubject ({});
    public highLightSearchResult$ = new BehaviorSubject ({});
    public showSearchPanelFlag: EventEmitter<any> = new EventEmitter();
    public showNavigationMenuFlag: EventEmitter<any> = new EventEmitter();
    public showGlobalSearch: EventEmitter<any> = new EventEmitter();
    public isTextHighlighted: boolean = false;
    constructor(private httpClient: HttpClient, private commonService: CommonService) {
    }

    getSearchIndexFile(searchIndexUrl: any) {
        return this.httpClient.get(searchIndexUrl, { observe: 'response' })
            .pipe(
                catchError(async e => this.commonService.handleError(e))
            );

    }
}