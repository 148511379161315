import { AfterViewInit, Component, OnDestroy, OnInit, Renderer2, ViewEncapsulation } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Subscription, delay, first, of } from 'rxjs';
import { Data } from 'src/app/core/models/data';
@Component({
  selector: 'app-embedded-link-viewer',
  templateUrl: './embedded-link-viewer.component.html',
  styleUrls: ['./embedded-link-viewer.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class EmbeddedLinkViewerComponent implements OnInit, OnDestroy, AfterViewInit {
  showIframe: boolean = false;
  embeddedUrl!: SafeResourceUrl;
  subscription : Subscription;
  iframeTitle: string = '';
  
  constructor(
    public data: Data,
    public sanitizer: DomSanitizer,
    private _renderer: Renderer2
  ) { }

  ngOnInit(): void {
    this.iframeTitle = this.data?.languageJson?.externalLink?.embeddedIframeTitle;
    this.subscription = this.data.isShowEmbeddedLinkViewer.subscribe((value => {
      this.showIframe = value.isShowEmbeddedLink;
      this._renderer.addClass(document.body, 'modal-open');
      if (this.showIframe) {
        this.data.isLoadingLgoin.next(true);
        this.embeddedUrl = this.sanitizer.bypassSecurityTrustResourceUrl(value.url);
      }
    }));

  }

  onCloseIframe() {
    this.data.isShowEmbeddedLinkViewer.next({isShowEmbeddedLink:false,url:''});
  }

  onload() {
    of('accessibility delay').pipe(delay(1500), first()).subscribe(() => {
      this.data.isLoadingLgoin.next(false);
      const elemId = document.getElementById('embeddedCloseBtn');
      if (elemId) {
        elemId.focus();
      }
    });
  }

  ngOnDestroy(){
    this.subscription.unsubscribe();
    this._renderer.removeClass(document.body, 'modal-open');
  }

  ngAfterViewInit(){
    let getEle: any = document.querySelector('.c-dialog-content .u-button');
    if (getEle) {
      getEle.setAttribute("aria-label", 'close');
    }
  }

}
