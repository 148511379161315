import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { catchError, Observable, of, tap } from 'rxjs';
import { environment } from '../../../environments/environment';
import { GlobalSettings } from 'src/app/global.settings';
import { dataObj } from './mock-data/iwb-annotation.mock';
import { ApiConstants } from '../constant/api.constant';
import { getDeleteIwbAnnotationParams } from '../models/http-handler';
import { LoggerService } from './logger.service';
import { Data } from '../models/data';
@Injectable({
  providedIn: 'root'
})
export class IwbAnnotationService {
  private baseUrl: string = ApiConstants.aceApiBaseUrl;

  constructor(private _http: HttpClient, private loggerService: LoggerService,private data:Data) { }

  saveIwbAnnotation(dataObj: any, accountId: string | undefined, meeUserId: string | undefined): Observable<any> {
    if (GlobalSettings.isBrowser) {
      return this.browserAPICallForSaveIwbAnnotation(dataObj);
    } else if (GlobalSettings.isNative) {
      return this.nativeAPICallForSaveIwbAnnotation(dataObj, accountId, meeUserId);
    } else {
      return this.desktopAPICallForSaveIwbAnnotation(dataObj, accountId, meeUserId);
    }
  }

  desktopAPICallForSaveIwbAnnotation(dataObj: any, accountId: string | undefined, meeUserId: string | undefined): Observable<any> {
    const url: string = GlobalSettings.LocalNodeServerBaseUrl + '/api/IWBAnnotation/saveIWBAnnotation';
    return this._http.post<any>(url, { IWBAnnotationData: dataObj, env: environment.name, "AccountID": accountId, "MeeUserId": meeUserId, isForObv: this.data.isOfflineUser })
      .pipe(
        tap(response => {
          return response;
        }),
        catchError(this.handleError('SaveOrUpdateIWBannotations', []))
      );
  }

  nativeAPICallForSaveIwbAnnotation(dataObj: any, accountId: string | undefined, meeUserId: string | undefined): Observable<any> {
    return new Observable(observer => {
      dataObj = { IWBAnnotationData: dataObj, "AccountID": accountId, "MeeUserId": meeUserId }; // This will be handled when FE integration is done
      saveUpdateIWBAnnotationsCallback(dataObj, ((result: any) => {
        result = JSON.parse(result);
        observer.next(result);
      }));
    });
  }

  browserAPICallForSaveIwbAnnotation(dataObj: any): Observable<any> {
    const url = `${this.baseUrl}${ApiConstants.saveUpdateIwbToolsUrl}`;
    dataObj = { IWBAnnotationData: dataObj };
    return this._http.post(url, dataObj)
      .pipe(
        (
          tap((response: any) => this.loggerService.logInfo(response)),
          catchError((error: any) => {
            return error;
          })
        )
      );
  }

  getIwbAnnotation(dataObj: { contentUnitId: string, accountID?: string | undefined, meeUserId?: string | undefined }): Observable<any> {
    if (GlobalSettings.isBrowser) {
      return this.browserAPICallForGetIwbAnnotation(dataObj.contentUnitId);
    } else if (GlobalSettings.isNative) {
      return this.nativeAPICallForGetIwbAnnotation(dataObj.contentUnitId, dataObj.accountID);
    } else {
      return this.desktopAPICallForGetIwbAnnotation(dataObj.contentUnitId, dataObj.accountID, dataObj.meeUserId);
    }
  }

  browserAPICallForGetIwbAnnotation(contentUnitId: string): Observable<any> {
    const url = `${this.baseUrl}${ApiConstants.getIwbToolsUrl}?ContentUnitId=${contentUnitId}`;
    return this._http.get(url)
      .pipe(
        (
          tap((response: any) => this.loggerService.logInfo(response)),
          catchError((error: any) => {
            return error;
          })
        )
      );
  }

  nativeAPICallForGetIwbAnnotation(contentUnitId: string, accountId: string | undefined): Observable<any> {
    const dataObj = {
      contentUnitId: contentUnitId,
      accountId: accountId
    };
    return new Observable(observer => {
      getIWBAnnotationsCallback(dataObj, ((res: any) => {         
       let response = JSON.parse(res);       
        observer.next(response.result);     
      }));
    });
  }

  desktopAPICallForGetIwbAnnotation(contentUnitId: string, accountId: string | any, meeUserId: string | any): Observable<any> {
    const url: string = GlobalSettings.LocalNodeServerBaseUrl + '/api/IWBAnnotation/GetIWBAnnotations';
    let params = new HttpParams().set('env', environment.name).set('contentUnitID', contentUnitId).set('accountID', accountId).set('meeUserId', meeUserId).set('isForObv', this.data.isOfflineUser); // This will be handled when FE integration is done
    return this._http.request('GET', url, { responseType: 'json', params })
      .pipe(
        tap(response => {
          return response;
        }),
        catchError(this.handleError('GetOrUpdateIWBannotations', [])));
  }

  syncIwbAnnotation(dataObj: any): Observable<any> {
    if (GlobalSettings.isNative) {
      return this.nativeAPICallForSyncIwbAnnotation(dataObj);
    } else {
      return this.desktopAPICallForSyncIwbAnnotation(dataObj);
    }
  }

  desktopAPICallForSyncIwbAnnotation(dataObj: any): Observable<any> {
    const url: string = GlobalSettings.LocalNodeServerBaseUrl + '/api/IWBAnnotation/syncIWBAnnotations';
    let params = new HttpParams().set('env', environment.name).set('contentUnitID', dataObj.contentUnitId).set('accountID', dataObj.accountId).set('meeUserId', dataObj.meeUserId);
    return this._http.request('GET', url, { responseType: 'json', params })
      .pipe(
        tap(response => {
          return response;
        }),
        catchError(this.handleError('GetOrUpdateIWBannotations', [])));
  }

  nativeAPICallForSyncIwbAnnotation(dataObj: any): Observable<any> {
    return new Observable(observer => {
      syncIWBAnnotationsCallback(dataObj, ((result: any) => {
        result = JSON.parse(result);
        observer.next(result);
      }));
    });
  }

  deleteIwbAnnotation(dataObj: any | null, meeUserId: string | undefined, accountId: string | undefined) {
    if (GlobalSettings.isBrowser) {
      return this.browserAPICallForDeleteIwbAnnotation(dataObj);
    } else if (GlobalSettings.isDesktop) {
      return this.desktopAPICallForDeleteIwbAnnotation(dataObj, meeUserId, accountId);
    }
    else {
      return this.nativeAPICallForDeleteIwbAnnotation(dataObj, accountId, meeUserId);
    }
  }

  browserAPICallForDeleteIwbAnnotation(dataObj: any) {
    let url: string = `${this.baseUrl}${ApiConstants.deleteIwbToolsUrl}`;
    let params = getDeleteIwbAnnotationParams(dataObj);
    return this._http.post(url, params)
      .pipe(
        (
          tap((response: any) => this.loggerService.logInfo('delete annotation')),
          catchError((error: any) => {
            return error;
          })
        )
      );
  }

  desktopAPICallForDeleteIwbAnnotation(dataObj: any, meeUserId: string | any, accountId: string | any) {
    const url: string = GlobalSettings.LocalNodeServerBaseUrl + '/api/IWBAnnotation/DeleteIWBAnnotations';
    return this._http.post<any>(url, { env: environment.name, "ContentUnitID": dataObj.ContentUnitID, "PageNo": dataObj.PageNo, "AccountID": accountId, "meeUserId": meeUserId, isForObv: this.data.isOfflineUser})
      .pipe(
        tap(response => {
          return response;
        }),
        catchError(this.handleError('Delete Annotation Data', []))
      );

  }

  nativeAPICallForDeleteIwbAnnotation(dataObj: any, accountId: string | undefined, meeUserId: string | undefined): Observable<any> {
    return new Observable(observer => {
      dataObj = { env: environment.name, "ContentUnitID": dataObj.ContentUnitID, "PageNo": dataObj.PageNo, "AccountID": accountId}; // This will be handled when FE integration is done
      deleteIWBAnnotationsCallback(dataObj, ((res: any) => {    
              res = JSON.parse(res);         
              observer.next(res.result);        
      }));
    });
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      return of(result as T);
    };
  }

}
