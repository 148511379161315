<div>
<maced-dialog dialogId="dialog2">
  <maced-dialog-content>
    <h2 class="c-modal-content__title" [attr.aria-label]="contentTitle">{{contentTitle}}</h2> 
        <p class="c-modal-content__text" [attr.aria-label]="contentTextMain+''+myDownloadsText+' '+contentTextSub" >
        {{contentTextMain}} <a *ngIf="!data.isOfflineUser" (click)="myDownloadRedirect()"  href="javascript:void(0)" >{{myDownloadsText}} </a>
      </p>
  </maced-dialog-content>
  <maced-dialog-footer *ngIf="(errorOptions.source !== 'downloadManager' && errorOptions.source !== 'downloads' && !data.isOfflineUser)">
    <maced-button buttonId="No" label="{{cancel}}" color="secondary" styleClass="u-button-normal" (click)="toggleCancel()">
    </maced-button>&nbsp;
    <maced-button *ngIf="!checkInternetConnectLoader" buttonId="Yes" label="{{tryAgain}}" color="primary"
      styleClass="u-button-normal" (click)="toggleTryAgain()" buttonType="submit">
    </maced-button>
    <div *ngIf="checkInternetConnectLoader" class="theme1">
      <span class="custom-loader"></span>
    </div>
  </maced-dialog-footer>
  <maced-dialog-footer *ngIf="(errorOptions.source === 'downloadManager' || errorOptions.source === 'downloads' || data.isOfflineUser)">
    <maced-button buttonId="No" label="{{cancel}}" color="secondary" styleClass="u-button-normal" (click)="toggleCancel()">
    </maced-button>
  </maced-dialog-footer>
</maced-dialog>
</div>