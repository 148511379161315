import { Component, HostListener, OnInit, Input, ChangeDetectorRef, Output, EventEmitter, ViewEncapsulation } from '@angular/core';
import { Subscription } from 'rxjs';
import { Data } from 'src/app/core/models/data';
import { ToastMessageType } from 'src/app/core/models/enum';
import { AuthService } from 'src/app/core/services/auth.service';
import { ClassManagementDashboardComponent } from 'src/app/features/settings/class-management/class-management-dashboard/class-management-dashboard.component';
import { ToastService } from 'src/app/services/toast.service';
import { CODE_TYPE } from 'src/app/features/settings/class-management/class-management.constant';
import { Router } from '@angular/router';
@Component({
  selector: 'app-data-processing-popup',
  templateUrl: './data-processing-popup.component.html',
  styleUrls: ['./data-processing-popup.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class DataProcessingPopupComponent implements OnInit {
  private popupSubscription: Subscription[] = [];
  isDataProcessing: boolean = false;
  isDataMigrated: boolean = false;
  isDataProcessingSuccess: boolean = false;
  importingDataMessage: string = '';
  importingDataMessageBody: string = '';
  horray: string = '';
  dataMigratedSuccessfully: string = '';
  activationCodeType: any;
  ispopup : boolean = false;
  description :string = '';

  constructor(
    private cdref: ChangeDetectorRef,
    public toasterService: ToastService,
    public data: Data,
    private router: Router,
  ) { }

  ngOnInit(): void {
    this.popupSubscription.push(this.data.ispopupLoading?.subscribe((value: any) => {
      this.isDataProcessing = value;
      this.importingDataMessage = this.data.languageJson?.dataProcessingPopup?.mapping_data;
      this.importingDataMessageBody = this.data.languageJson?.dataProcessingPopup?.mapping_data_message;
      this.cdref.detectChanges();
    }));
    this.popupSubscription.push(this.data.ispopupLoadingSuccess?.subscribe((value: any) => {
      this.isDataMigrated = value;
      this.horray = this.data.languageJson?.dataProcessingPopup?.mapping_success;
      this.cdref.detectChanges();
    }));
    this.popupSubscription.push(this.data.ispopup.subscribe((value: any) => {
      this.ispopup = value.isShow;
      this.horray = ' Device orientation';
      this.description = "To view "+value.description+", please change the orientation to landscape."
      this.cdref.detectChanges();
    }));
  }
  onSuccess() {
    const currentPath = this.router.url;  
    const classManagementLabels = this.data.languageJson?.classManagement;
    this.isDataMigrated = false;
    const successMessage = this.data.activationCodeType === CODE_TYPE.INSTITUTE ? classManagementLabels.joining_success_message_institute : classManagementLabels.joining_success_message_class;
    if (!currentPath.includes('/courses/courselist')) {
      this.toasterService.showMessage(ToastMessageType.SUCCESS, successMessage, false);
    }
  }
  ngOnDestroy(): void {
    if (this.popupSubscription) {
      this.popupSubscription.forEach((subscription: Subscription) => subscription.unsubscribe());
    }
  }
  getPopupSubscription(): Subscription[] {
    return this.popupSubscription;
  }

}