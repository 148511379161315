export const VALIDATOR_INPUT_MIN_LENGTH = 8;
export const NOTIFICATION_ARIA_LABEL = 'class management notification';
export const CLASS_MANAGEMENT_BASE_URL = '/settings/class-management';
export const VALIDATOR_INPUT_CONFIG = {
  placeholder: 'Unique ID',
  label: 'Please enter your access code below',
  statusMessage: 'This code is a mix of letters and numbers',
  errorMessage: 'The code entered is invalid. Try again or contact admin.'
};

export enum CODE_TYPE {
  CLASS = 'class',
  INSTITUTE = 'institute',
  INVALID = 'invalid'
}

export const MY_INSTITUTION_HEADERS: any[] = [
  {
    field: 'institutionName',
    headerName: '',
    type: 'text'
  }
];

export const MY_CLASSES_HEADERS: any[] = [
  {
    field: 'className',
    headerName: '',
    type: 'text'
  },
  {
    field: 'teachersName',
    headerName: '',
    type: 'text'
  }
];