import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { Data } from 'src/app/core/models/data';
import { IConstants } from 'src/app/core/models/constants';


@Component({
  selector: 'app-performance-popup',
  templateUrl: './performance-popup.component.html',
  styleUrls: ['./performance-popup.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class PerformancePopupComponent implements OnInit {

  @Input() showPoupupMsg: boolean;
  notificationModifiedIcon: string = "icon-info-circle";
  notificationInlineId: string = "slowInternetAlert";

  @Output() snoozePopupFunction:EventEmitter<any>= new EventEmitter();
  @Output() isSnoozedTillLogout:EventEmitter<any>= new EventEmitter();
  

  constructor(public data: Data,public constants: IConstants) { }

  ngOnInit(): void {
  }
  snoozeTime(){
    this.snoozePopupFunction.emit();
  }
  isSnoozed(event){
    this.isSnoozedTillLogout.emit(event);
  }
  readMore() {
    let url
    if (this.data.displayInternetMessage.length > 0 && this.data.displayBothMessage.length == 0) {
      url = this.constants.INTERNET_MESSAGE_URL;
    }
    else if (this.data.displayCPUMessage.length > 0 && this.data.displayBothMessage.length == 0) {
      url = this.constants.CPU_MESSAGE_URL;
    }
    else if (this.data.displayBothMessage.length > 0) {
      url = this.constants.BOTH_MESSAGE_URL;
    }
    window.open(url, '_blank');
    this.snoozeTime();
  }
}
