<h1>Data Migration</h1>

<maced-dropdown [dropdownId]="dropdownId" [name]="dropdownId" ariaLabel="label" label="label"
    cheveronClass="icon-chevron-down" [options]="options"
    [selectedOption]="selectedOptions" (menuEvent)="onClick($event)">
</maced-dropdown>

<maced-button buttonId="Schedule Setting Submit Button" color="primary" (click)="submitResponse()"
    [label]="buttonLabel" styleClass="u-button-normal" size="large" buttonType="submit">
</maced-button>

<p>{{status}}</p>


