import { environment } from 'src/environments/environment';
import { SCHEMA } from './enum';
export class Frame {
  private id: string;
  private frameId: string;
  public pctX: number;
  public pctY: number;
  public pctW: number;
  public pctH: number;
  public linkTo: string[];
  public linkFrom: string[];
  public rcfActivitySets: string[];
  public icons: string[] | undefined;
  public type: string;

  constructor(frame: any) {
    this.id = frame.id;
    this.frameId = frame.frameId;
    this.pctX = frame.pctX;
    this.pctY = frame.pctY;
    this.pctW = frame.pctW;
    this.pctH = frame.pctH;
    this.linkTo = frame.linkTo;
    this.linkFrom = frame.linkFrom;
    this.rcfActivitySets = frame.rcfActivitySets;
    this.icons = frame.icons;
    this.type = frame.type;
  }

  public getFrameId() {
    return this.id ? this.id : this.frameId;
  }
}
export class Icons {
  private id: string;
  public posX: string;
  public posY: string;
  public rcfActivitySets: string[];

  constructor(icons: any) {
    this.id = icons.id;
    this.posX = icons.posX;
    this.posY = icons.posY;
    this.rcfActivitySets = icons.rcfActivitySets;
  }

  public getIconId() {
    return this.id;
  }
}
export class BookPage {
  private id: string;
  private frames: string[];
  private sourcePDF: string;
  private pdfPage: number;
  private icons: string[];

  constructor(page: any) {
    this.id = page.id;
    this.sourcePDF = page.sourcePDF;
    this.pdfPage = page.pdfPage;
    this.frames = page.frames;
    this.icons = page.icons;
  }

  public getBookPageId() {
    return this.id;
  }

  public getSourcePDF() {
    return this.sourcePDF;
  }

  public getBookPagePDFPage() {
    return this.pdfPage;
  }

  public getBookPageFrames() {
    return this.frames;
  }

  public getBookPageIcons() {
    return this.icons;
  }

  public getPDFPageNumber() {
    return this.pdfPage;
  }
}
export class ResourceDetails {
  public series: string = '';
  private level: string = '';
  private projectType: string = '';
  private componentType: string = '';
  private contentUnitID: string = '';
  private rootPath?: string = '';
  public schemaVersion: string = '';
  private pages: BookPage[] = [];
  private frames: Frame[] = [];
  private icons: Icons[] = [];
  private bookPageOrder: string[] = [];
  private sourceFile: string = '';
  private manifestVersion!: number;
  
  constructor(resource: any) {
    if (resource) {
      this.series = resource.series;
      this.level = resource.level;
      this.projectType = resource.projectType;
      this.componentType = resource.componentType;
      this.contentUnitID = resource.contentUnitID;
      this.rootPath = resource.rootPath;
      this.schemaVersion = resource.schemaVersion;
      this.pages = [];
      this.constructPages(resource.pages);
      this.frames = [];
      this.icons = [];
      this.constructFrames(resource.frames);
      this.sourceFile = resource.sourceFile;
      this.bookPageOrder = resource.bookPageOrder;
      if((resource.schemaVersion == SCHEMA.version3 || resource.schemaVersion == SCHEMA.versionframeless1) && resource.icons){
        this.constructIcons(resource.icons);
    }
    }
  }

  constructPages(pages: any) {
    for (let [key, value] of Object.entries(pages)) {
      const bookPage = new BookPage(value);
      this.pages.push(new BookPage(bookPage));
    }
  }

  constructFrames(frames: any) {
    if (frames) {
      for (let [key, value] of Object.entries(frames)) {
        const bookFrames = new Frame(value);
        this.frames.push(new Frame(bookFrames));
      }
    }
  }

  constructIcons(icon: any) {
    if (icon) {
      for (let [key, value] of Object.entries(icon)) {
        const bookIcons = new Icons(value);
        this.icons.push(new Icons(bookIcons));
      }
    }
  }

  public getcontentUnitID() {
    return this.contentUnitID;
  }

  public getPages() {
    return this.pages;
  }

  public getFrames() {
    return this.frames;
  }
  public getIcons() {
    return this.icons;
  }
  public getResourceRootPath() {
    return this.rootPath;
  }
  public getSourceFile() {
    return this.sourceFile;
  }

  public getSeries() {
    return this.series;
  }

  public getLevel() {
    return this.level;
  }

  public getProjectType() {
    return this.projectType;
  }

  public getComponentType() {
    return this.componentType;
  }

  public getBookPageOrder() {
    return this.bookPageOrder;
  }

  public getManifestVersion() {
    return this.manifestVersion;
  }

  public getBookPath(): string {
    let bookPath: string;
    if (this.getSourceFile()) {
      bookPath = environment.lmsCDNPath + '/macmillanXHotspotMapping/' + this.getResourceRootPath() + '/' + this.getSourceFile().split('.')[0];
    } else {
      bookPath = environment.lmsCDNPath + '/macmillanXHotspotMapping/' + this.getResourceRootPath() + '/' + this.getcontentUnitID();
    }
    return bookPath;
  }

  public getFrameDetailsByframeId(frameId: string) {
    let currentFrame!: Frame;
    if (this.frames.length > 0) {
        this.frames.forEach((frame) => {
            if (frame.getFrameId() === frameId) {
                currentFrame = frame;
            }
        });
    }
    return currentFrame;
}

public getIconDetailsByiconId(iconId: string) {
  let currentIcon!:Icons;
  if (this.icons.length > 0){
      this.icons.forEach((icon) => {
          if (icon.getIconId() === iconId) {
              currentIcon = icon;
          }
      });
  }
  return currentIcon;
}
}