const  Role =         'user.segment.category';
const  Platform =     'content.category.platform';
const  CourseName =   'content.category.courseName';
const  CourseLevel =  'content.category.courseLevel';
const  BookName =     'content.category.bookName';
const  CourseUnit =   'content.category.courseUnit';
const  CourseLesson = 'content.category.lesson';
const  ActivityType = 'content.category.activityType';
const  UserId  =      'user.profile.profileId';

export const MainMenu = 'Main Menu';
export const DownloadingContent = 'Downloading Content';
export const BookControls = 'Book Controls';
export const BookNavigation = 'Book Navigation';
export const RcfActivityBook = 'RCF Activity - Book';
export const BookMarks = 'Bookmarks - Book';
export const DownloadApp = 'Download - App';
export const Notes = 'Notes - Book';
export const Bookviewer = 'Bookviewer - Book';
export const LessonExtras = 'Lesson Extras - Book';
export const TableOfContent = 'Table Of Content - Book';
export const UpdateContent = 'Content Update - Course';
export const NavigationPanel = 'Navigation Panel - Book';
export const QuickLinks = 'Quick Links - Book';
export const DigitalResources = 'Digital resources - Book';
export const RcfActivityHW = 'RCF Activity - Homework';
export const RcfActivityChallenge = 'RCF Activity - Challenge';
export const RcfActivityPreparation = 'RCF Activity - Preparation & Practice';
export const Homework = 'Homework';
export const IWBAnnotation = 'IWBAnnotation - Book';
export const GlossaryCategory = 'Glossary';
export const ReadAloud = 'Read Aloud - Book';
export const Zoom = 'Zoom - Book';
export const RemoveContent = 'Remove Content';
export const ViewContent = 'Event_EPC_MyDownloads_View_Content';
export const ViewContentBV = 'Event_BookViewer_MyDownloads_View_Content';
export const Login = 'Login Page';
export const Calendar = 'Calender';
export const Calendaraddevent = 'Calender Add Event';
export const Calendaraddattendees = 'Calender Add Attendees';
export const Notifications = 'Notifications';
export const EPC_Navigation="Navigation_EPC";
export const EPC_DefaultLaunchActivity="Event_EPC_Launch_DefaultRCF_Activity";
export const EPC_TOC_LaunchActivity="Event_EPC_TOC_LaunchRCF_Activity";
export const EPC_ContinueActivity="Event_EPC_ContinueRCF_Activity";
export const EPC_Exit="Event_EPC_Exit";
export const Challenge_Navigation="Navigation_Challenge";
export const Challenge_DefaultLaunchActivity="Event_Challenge_Launch_DefaultRCF_Activity";
export const Challenge_TOC_LaunchActivity="Event_Challenge_TOC_LaunchRCF_Activity";
export const Challenge_ContinueActivity="Event_Challenge_ContinueRCF_Activity";
export const Challenge_Exit="Event_Challenge_Exit";
export const Challenge_Browser_Activity="Event_Challenge_BrowseActvities";
export const Grading_Navigation="Navigation_Grading";
export const Grading_Exit="Event_Grading_Exit";
export const Progress_Navigation="Navigation_Progress";
export const Progress_Exit="Event_Progress_Exit";
export const Grading_Activity_Feedback_Submit="Event_Grading_Activity_Feedback_Submit";
export const Progress_Tracker_Activity_Feedback_Submit="Event_Progress_Tracker_Activity_Feedback_Submit";


export const SearchModule = 'Search - GlobalSearch';
export const CourseDetails = 'Courses - Course Details';
export const BookShelf = 'Courses - BookShelf';

class GTMGlobalVariable {
    userId?: string;
    eventValue?: number;
}

class GTMPageViewVariable {
    pageDomain?: string;
    pagePath?: string;
    pageCategory?: string;
    pageTitle?: string;
    country?: string;
    language?: string;
    [UserId]?:string;
    [Role]?:string;
    [Platform]?:string;
    [CourseName]?:string;
    [CourseLevel]?:string;
    [ActivityType]?:string;
    [BookName]?:string;
    [CourseUnit]?:string;
    [CourseLesson]?:string;
}

export enum GTMAction {
    DataTrack = 'data-track',
    PageView = 'custom.historyChange'
}

export class GtmDataTrackEventObject extends GTMGlobalVariable {
    event?: GTMAction;
    eventAction?: string;
    eventCategory?: string;
    eventLabel?: string;
}

export enum GTMAppTypes {
    Browser = 'Web Browser',
    Native = 'Mobile Device',
    Desktop = 'Application'
}

export class GtmPageViewObject extends GTMPageViewVariable {
    event?: GTMAction;
}
