import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { SectionsData } from './sections-data';

@Injectable({
  providedIn: 'root'
})
export class SidepanelService {
  sectionsData = SectionsData;
  section = new BehaviorSubject<any>({});
  activeSection: any = this.sectionsData[0];
  isSidePanelOpen: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  setSidePanelSection(section: any) {
    let selectedSection = this.sectionsData[0];
    if (typeof (section) == "string") {
      let sectionName = section.toLowerCase();
      sectionName = section.toLowerCase();

      switch (sectionName) {
        case "lesson-extras":
          selectedSection = this.sectionsData[1];
          break;
        case "glossary-player":
          selectedSection = this.sectionsData[2];
          break;
        case "my-downloads":
          selectedSection = this.sectionsData[3];
          break;
        case "my-quicklinks":
          selectedSection = this.sectionsData[4];
          break;
        case "digital-resources":
          selectedSection = this.sectionsData[5];
          break;
        default:
          selectedSection = this.sectionsData[0];
      }
    } else {
      selectedSection = section;
    }
    this.activeSection = selectedSection;
  }

  getActiveSection(): Observable<any> {
    return new Observable(observer => {
      observer.next(this.activeSection);
    });
  }
}