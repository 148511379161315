<div class="internet-alert">
    <maced-notification-inline 
        type="info"
        closeButtonArialabel="Close" 
        [notificationInlineId]="notificationInlineId"
        [hasModifiedIcon]=true
        [hasDefinedIcon] = false
        [notificationModifiedIcon]="notificationModifiedIcon"
        [hasCloseButton]=true 
        >
        <ng-container ngProjectAs="[notificationInlineTitle]">
          <div class="internet-alert__message"
            *ngIf="data.displayInternetMessage.length > 0 && data.displayBothMessage.length == 0 && data.displayCPUMessage.length == 0">
            <p class="internet-alert__message-label">{{data.displayInternetMessage}}</p>
            <p class="internet-alert__message-info">{{data.displayInternetMessageSubtext}}</p>
          </div>
          <div class="internet-alert__message"
            *ngIf="data.displayCPUMessage.length > 0 && data.displayBothMessage.length == 0 && data.displayInternetMessage.length == 0">
            <p class="internet-alert__message-label">{{data.displayCPUMessage}}</p>
            <p class="dinternet-alert__message-info">{{data.displayCPUMessageSubtext}}</p>
          </div>
          <div class="internet-alert__message" *ngIf="data.displayBothMessage.length > 0">
            <p class="internet-alert__message-label">{{data.displayBothMessage}}</p>
            <p class="internet-alert__message-info">{{data.displayBothMessageSubtext}}</p>
          </div>
          
          <div class="internet-alert__check-box">
            <maced-checkbox label="Show me later" [checkboxId]="'isSnoozedTillLogout'" [value]="data.isSnoozedTillLogout"
              [checked]="data.isSnoozedTillLogout" (change)="isSnoozed($event)"></maced-checkbox>
          
          </div>
          <div class="internet-alert__buttons">
            <maced-button buttonId="buttonReadMore" color="primary" size="small" shape="circle" buttonType="button"
              tootipButtonId="tootipButtonId" ariaLabel="read more" label="Read more" name="readMore" value="val1"
              (click)="readMore()">
            </maced-button>
            <maced-button buttonId="buttonOkay" color="secondary" size="small" shape="circle" buttonType="button"
              tootipButtonId="tootipButtonId" ariaLabel="OK" label="OK" name="OK" value="val1" (click)="snoozeTime()">
            </maced-button>
          </div>
        </ng-container>
  
      </maced-notification-inline>
  </div>