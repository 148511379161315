import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, first } from 'rxjs';
import { Data } from 'src/app/core/models/data';
import { RESOURCETYPES,manifestVersionsUrl } from 'src/app/core/models/enum';
import { ManifestVersionInfo} from 'src/app/core/models/manifestVersions';
import { CourseService } from 'src/app/core/services/course.service';
import { GlobalSettings } from 'src/app/global.settings';
import { LoggerService } from './logger.service';
import { UtilityService } from 'src/app/services/utility.service';

@Injectable({
  providedIn: 'root',
})
export class DownloadManifestService {
  private manifestVersionInfoList: ManifestVersionInfo[] = [];
  public manifestVersionDatalist: any;
  public ManifestVersionAPIData: any;
  updateContentshow$!: Observable<any>;
  private updateContentshowSubject = new BehaviorSubject<any>(false);
  
  constructor(
    public http: HttpClient,
    public data: Data,
    public courseService: CourseService,
    public loggerService: LoggerService,
    public utilityService: UtilityService

  ) {
    this.updateContentshow$ = this.updateContentshowSubject.asObservable();
   }

  getManifestVersionData(data: any): Promise<ManifestVersionInfo[]> {
    return new Promise((resolve, reject) => {
      if (data && data.length > 0) {
        data.forEach((subscription: any) => {
          this.manifestVersionInfoList.push({
            manifestType: 'toc',
            manifestIdentifier: subscription.courseName + '<:><:><:>' + subscription.courseLevel,
            version: '',
          });
          if (subscription.resources && subscription.resources.length > 0) {
            subscription.resources.forEach((resource: any) => {
              if (
                resource.resourceType && (resource.resourceType.toLocaleLowerCase() == RESOURCETYPES.PrintDerived.ResourseType.toLocaleLowerCase() ||
                  resource.resourceType.toLocaleLowerCase() == RESOURCETYPES.NonPrintDerived.ResourseType.toLocaleLowerCase() ||
                  resource.resourceType.toLocaleLowerCase() == RESOURCETYPES.NavioHybridPrintDerived.ResourseType.toLocaleLowerCase() ||
                  resource.resourceType.toLocaleLowerCase() == RESOURCETYPES.CURPrintDerived.ResourseType.toLocaleLowerCase() ||
                  resource.resourceType.toLocaleLowerCase() == RESOURCETYPES.CURNonPrintDerived.ResourseType.toLocaleLowerCase())
              ) {
                this.manifestVersionInfoList.push({
                  manifestType: 'rcf',
                  manifestIdentifier: resource.externalId,
                  version: '',
                });
                if (
                  resource.resourceType &&
                  (resource.resourceType.toLocaleLowerCase() == RESOURCETYPES.PrintDerived.ResourseType.toLocaleLowerCase() ||
                    resource.resourceType.toLocaleLowerCase() == RESOURCETYPES.NavioHybridPrintDerived.ResourseType.toLocaleLowerCase() ||
                    resource.resourceType.toLocaleLowerCase() == RESOURCETYPES.CURPrintDerived.ResourseType.toLocaleLowerCase() ||
                    resource.resourceType.toLocaleLowerCase() == RESOURCETYPES.CURNonPrintDerived.ResourseType.toLocaleLowerCase())
                ) {
                  this.manifestVersionInfoList.push({
                    manifestType: 'spotOn',
                    manifestIdentifier: resource.externalId,
                    version: '',
                  });
                }
              }
            });
          }
        });

        this.courseService.checkFileExistsGlobal(manifestVersionsUrl.url).subscribe((fileStatus:any)=>{
          if(fileStatus){
            this.courseService.getManifestVersionFile().subscribe(async (res) => {
              this.utilityService.checkInternetConnectivity(true).pipe(first()).subscribe((internetStatus: boolean) => {
                if(internetStatus){
              this.courseService.getManifestVersions(this.manifestVersionInfoList).subscribe((response) => {
                if (GlobalSettings.isDesktop) {
                  if(res && res.manifestVersions && res.manifestVersions.length) {
                    this.ManifestVersionAPIData = [];
                    this.ManifestVersionAPIData = res.manifestVersions;
                    this.manifestVersionDatalist = response.result;
                    this.data.manifestVersionData = response.result;
                  } else {
                   this.saveManifestVersionJSON(response.result);
                  }
                }
                else if( GlobalSettings.isNative) {
                  if(res && res.manifestVersions && res.manifestVersions.length) {
                    this.ManifestVersionAPIData = [];
                    this.ManifestVersionAPIData = res.manifestVersions;
                    this.manifestVersionDatalist = response;
                    this.data.manifestVersionData = response;
                  } else {
                   this.saveManifestVersionJSON(response);
                  }
                }
              }, error => {
                this.loggerService.logInfo(error);
              });
            }
            });
            })
          }
          else
          {
            this.courseService.getManifestVersions(this.manifestVersionInfoList).subscribe((response) => {
              if (GlobalSettings.isDesktop) {
                this.saveManifestVersionJSON(response.result);
              }
              else if (GlobalSettings.isNative) {
                this.saveManifestVersionJSON(response);
              }
            }, error => {
              this.loggerService.logInfo(error);
            });
        }
      })

      }
    })
  }

  saveManifestVersionJSON(manifestVersionList: any) {
    const manifestVersionsObj = {
      manifestVersions: manifestVersionList
    };
    const manifestVersionFileObj = {
      contentUnitId: 'manifestVersions',
      jsonFileType: 'manifestVersions',
      jsonData: manifestVersionsObj
    };
    // store the manifest version data in local folder
    if (GlobalSettings.isDesktop || GlobalSettings.isNative) {
      this.courseService.saveManifestLocally(manifestVersionFileObj).subscribe((rcfResponse) => {
      }, error => {
        this.loggerService.logInfo('saveManifestLocallyFailed');
      });
    }
  }

  getsaveManifestVersions() {
    let updatedManifestData: any;
    if (GlobalSettings.isNative || GlobalSettings.isDesktop){
        if (this.ManifestVersionAPIData && this.ManifestVersionAPIData.length) {
          updatedManifestData = this.compareManifestVersion(this.ManifestVersionAPIData)
          if (updatedManifestData) {
            this.updatedManifestName(updatedManifestData);
          }
        }
    }
  }

  compareManifestVersion(localManifest: any) {
    let updatesAvailable: any = [];
    if (localManifest && localManifest.length) {
      localManifest.forEach((element: any) => {
        if (this.manifestVersionDatalist && this.manifestVersionDatalist.length) {
          if (element.manifestType === 'toc') {
            element.manifestIdentifier = element.manifestIdentifier
              .replace(/&lt;/g, "<")
              .replace(/&gt;/g, ">")
              .replace(/&amp;/g, "&")
          }
          this.manifestVersionDatalist.forEach((data: any) => {
            if (element.manifestType == data.manifestType &&
              element.manifestIdentifier == data.manifestIdentifier) {
              if (element.version !== data.version) {
                let value = {
                  version: data.version,
                  manifestIdentifier: data.manifestIdentifier,
                  manifestType: data.manifestType
                }
                updatesAvailable.push(value);
              }
            }
          })
        }
      });
    }
    if (updatesAvailable.length) {
      return updatesAvailable
    }
  }

  updatedManifestName(updatedManifestData: any) {
    this.data.showUpdateContent = []
    if (this.data.courses?.length) {
      this.data.courses.forEach((courseData: any)=> {
           if (courseData.resources.length) {
            courseData.resources.forEach((value: any) => {
              updatedManifestData.forEach((element: any) => {
                  if (element.manifestType == "rcf" || 
                  element.manifestType == "spotOn") {
                    if (element.manifestIdentifier == value.externalId) {
                      element['courseName'] = value.translations.en.title
                    }
                  } else {
                    let splitValue = element.manifestIdentifier.split('<:>')
                    if (splitValue && splitValue.length) {
                      element['courseName'] = splitValue[0]+ ' ' + splitValue[3]
                    }
                  }
              });
            })
           }
      })
      this.data.showUpdateContent.push(updatedManifestData)
      this.setUpdatedContents(true);
    }
  }
  setUpdatedContents(val:any){
    this.updateContentshowSubject.next({isVisible:val});
  }
}
