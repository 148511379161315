import { Component, OnInit, Renderer2, ChangeDetectorRef} from '@angular/core';
import { updateDialogButtonsLabel , DialogButtonsId} from 'src/app/core/models/enum';
import { Data } from 'src/app/core/models/data';
import { CONTENT_TEXT } from './content-update.constant';
import { UpdateContent } from '../../../core/models/gtm-models/gtm';
import { GtmService } from 'src/app/core/services/gtm.service';
import {DownloadManifestService} from '../../../core/services/download-manifest.service';
import { Subscription } from 'rxjs';
import { GlobalSettings } from 'src/app/global.settings';

@Component({
  selector: 'app-content-update',
  templateUrl: './content-update.component.html',
  styleUrls: ['./content-update.component.scss']
})
export class ContentUpdateComponent implements OnInit {
  public btnIdOne: string = DialogButtonsId.buttonIdOne;
	public btnIdTwo: string = DialogButtonsId.buttonIdTwo;
  public updateContentData: any = [];
  public isUserLogIn = false;
  public contentFooterText = '';
  public contentTitle: string = '';
  public contentText: string = '';
  public btnLabelOne: string = '';
	public btnLabelTwo: string = '';
  updateContentSubscription!: Subscription;
  constructor(
    public data: Data,
    private _renderer: Renderer2,
    private _cd: ChangeDetectorRef,
    public gtmService: GtmService,
    public downloadManifestService: DownloadManifestService
  ) { 
  this.updateContentSubscription = this.downloadManifestService.updateContentshow$.subscribe((response: any) => {
      if(response && (GlobalSettings.isDesktop || GlobalSettings.isNative)){
        this.updateContent(response.isVisible);
      }
    })
  }

  ngOnInit(): void {
    this.setLables();
    this._renderer.addClass(document.body, 'modal-open');
  }

  setLables() {
    if (this.data.languageJson) {
      this.contentTitle = this.data.languageJson?.update_application?.update_content?.text;
      this.contentText = this.data.languageJson?.update_application?.update_content?.sub_text_1;
      this.contentFooterText = this.data.languageJson?.update_application?.update_content?.content_footer_text;
      this.btnLabelOne = this.data.languageJson?.update_application?.update_content?.update;
      this.btnLabelTwo = this.data.languageJson?.update_application?.update_content?.remind_me_later;
    }
  }

  updateContent(response: any) {
    if (response && this.data.showUpdateContent.length) {
      this.updateContentData = this.data.showUpdateContent[0]
      this.isUserLogIn = true;
      this._renderer.addClass(document.body, 'modal-open');   
      this._cd.detectChanges();
    }
  }

  dialogClicked(params: boolean) {
    this.GAGTMeventUnitTrigger();
    this.isUserLogIn = false;
    this._renderer.removeClass(document.body, 'modal-open');
    this.data.contentUpdateDailogue.emit(params);
	}

  GAGTMeventUnitTrigger() {
    this.gtmService.eventTracking(UpdateContent,'Download Update Content');
    this.gtmService.pageViewTracking(UpdateContent);
  }
  ngOnDestroy() {
    if (this.updateContentSubscription) {
      this.updateContentSubscription.unsubscribe();
    }
  }
}

