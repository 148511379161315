import { EventEmitter, Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class BookMenuUtilityService {
  public smilContentCheck: EventEmitter<boolean> = new EventEmitter();
  public readAloudPlayState: EventEmitter<boolean> = new EventEmitter();
  public LessonExtra: EventEmitter<boolean> = new EventEmitter();
  public isReadAloudOpen: EventEmitter<boolean> = new EventEmitter();
}