export class GlobalSettings {
  public static MACXTeacherAppClientId = '3D6886B1-3DAB-46E9-B6BD-091DBB4EC241';
  public static MACXStudentAppClientId = 'E4BFA0D1-3B95-4DE2-8EC9-D8BD31DC3BFB';
  public static MACXClientId = '3CE1F4B2-02B0-41F2-AECE-9D2F200956E0';  
  public static LocalNodeServerBaseUrl = '';
  public static LocalNodeServerStaticBaseUrl = '';
  public static isBrowser = true;
  public static isDesktop = false;
  public static routePath = '';
  public static isOIDCLocal = false;
  public static isNative = false;
  public static isCoreApi = true;
  public static DEFAULT_LMS_ZOOM_SCALE = 0.71;
  public static productType = '{productType_Key_1}';
  public static focusHours = 8;
  public static intervalTime :number = 1;
  public static defultLang = 'en';
  public static escapeUrl='/notifications';
  public static escapeRedirectPath='/notifications?currentPage=1&selectedDropdownOptionId=5&dropdownId=paginationIdPageSizeList&ver=z95';
  public static srIntervalTime = 2000;
  public static HYBRID_RESOURCETYPES = 'NHYNPD,NHYPD';
  public static defaultTheme = 'blue';
  public static PD_RESOURCETYPES = '{supportedContentType_Key}';
  public static defaultEmptyImgPath = '/assets/default/images/';
}

