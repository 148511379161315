<div class="page-navigation">
    <form [formGroup]="formElement" class="page-navigation__form u-display-flex" (ngSubmit)="goToPageClick()">
        <div class="page-navigation__form-wrapper">
            <maced-input placeholder="" inputId="inputIdGoto" name="gotoControl" type="text"
                formControlName="gotoControl" [hasError]="hasError" (input)="onInputChange($event)" [aria-label]="currentPageNo">
            </maced-input>
            <label class="page-navigation__form-wrapper-label" for="inputIdGoto">{{noOfPages}}</label>
            <div class="page-navigation__form-wrapper-go-button">
                <maced-button buttonId="gotoSubmit" (click)="goToPageClick()" color="secondary" shape="square" icon="" [label]="goLabel" [ariaLabel]="goLabel">
                </maced-button>
            </div>
        </div>
        <div class="page-navigation__form-close-button">
            <maced-button *ngIf="data.isMobileView && !data?.iwbModeBtn" buttonId="gotoClose" (click)="goToPageClose()" color="default"
                shape="square" icon="icon-close" label="" [ariaLabel]="closeLabel">
            </maced-button>
        </div>
    </form>
</div>