export class IConstants {
    RIGHT = 'right';
    LEFT = 'left';
    BOXREFOBJECT = 'BoxRefObject';
    BOXWITHRCF = 'BoxWithRCF';
    DOUBLEPAGESPREAD = 'double';
    SINGLEPAGESPREAD = 'single';

    LEFTTOP = 'lefttop';
    LEFTBOTTOM = 'leftbottom';
    RIGHTTOP = 'righttop';
    RIGHTBOTTOM = 'rightbottom';

    SCHEMAVERSION = 4;

    NEW_USER_PREFERENCE = 'new user preference';
    CONFIRMATION_MODAL_CONTENT = 'You can complete your digital book activities on this device. To see the book pages, switch to a PC, laptop or tablet.';
    EPUB_INFO_MODAL_CONTENT = 'Please switch to landscape mode in order to continue';

    EXTERNAL_JS_FILE = 'assets/js/dragscroll.js';

    isdownloadStatus = 'complete';
    studentApp = 'Student';
    TEACHERAPP = 'Teacher';
    nextPageNavigate = 'next';
    prevPageNavigate = 'prev';
    rcfDownloadPageUrl = '/download-activity';
    home = "/home";
    RCFCONSTANT = 'rcf';
    TOCCONSTANT = 'toc';
    EPUBCONSTANT = 'epub';
    PDFCONSTANT = 'pdf';
    ASSETCONSTANT = 'asset';
    GLOSSARYCONSTANT = 'glossary';
    RCFACTIVITYPAGECONSTANT = 'downloadRcf-Activity';
    INSTRUCTOR = 'instructor';
    PASSWORD = 'password';
    FORGOT_USERNAME = 'forgot-username';
    FORGOT_PASSWORD = 'forgot-password';
    FORGOT_ALTURA_USERNAME = 'RecoverUserName';
    FORGOT_ALTURA_PASSWORD = 'ForgotPassword';
    ACTIVE_HOMEWORK = 'Active homework';
    INACTIVE_HOMEWORK = 'Inactive homework';

    PAGEJUMP = 'pageJump';

    classNameOpenBody = 'quick-link-open-body';
    classNameOpenBodylandscape = 'body-landscape';

    titleBarStatus = {
        LOGINTITLE: 'Welcome to MEE',
        COURSETITLE: 'Bookshelf',
        COURSEDETAILSTITLE: 'Course Content',
        BOOKVIEWERTITLE: 'Book view',
        CALENDARTITLE: 'Calendar',
        DOWNLOADAPPTITLE: 'Download MEE app',
        NOTIFICATIONSTITLE: 'Notifications',
        EPC:"Extra Practice",
        CHALLENGE:"Challenge",
        GRADING: "Grading",
        PROGRESS: "Progress",
        LANGUAGE:"Language change",
        ASSESSMENT: "Assessment",
        HOMEWORK: "Homework",
        SETTINGS: "Settings",
        CB:"Course Builder"
    };
    ENCRYPTED = 'encrypted';
    ENCRYPTED_IOS = 'encrypted_ios';
    USER_PREF_CONSTS = {
        UNITS_LIST: 'unitsList'
    };
    A11Y_CONSTS = {
        CLOSE_QUICKLINKS: 'Close quicklinks',
        QUICKLINKS_BUTTON_LBL: 'quicklinks toggle'
    };

    public OIDCConstants = {
        MEPTeacherBrowser: 'MEPTeacherBrowser',
        MEPStudentBrowser: 'MEPStudentBrowser',
        MEPTeacherApp: 'MEPTeacherApp',
        MEPStudentApp: 'MEPStudentApp',
        AlturaLMS: 'AlturaLMS',
        AlturaApp: 'AlturaApp',
        MeePlatform: 'MeePlatform'
    };

    MININCREMENT = 50;
    MAXINCREMENT = 50;
    links: { label: string, hyperLink: string, target: string }[] = [];
    baseURL: string = "https://www.macmillaneducationeverywhere.com/";
    myClassesURL: string = "https://myclasses.macmillaneducationeverywhere.com/MEE/";
    footerLogoLink: string = 'https://www.springernature.com/gp/macmillaneducation';
    companyNo: string = "Company number: 1755588";
    VATNumber: string = "VAT number: 199 4406 21";
    version: string = "Version:";
    companyName: string = '© Macmillan Education Limited';
    FOOTER_LINK = [{ label: "Terms and conditions", url: this.baseURL + "terms-conditions/", target: '_blank' },
    { label: "Privacy policy", url: this.baseURL + "privacy-policy/", target: '_blank' },
    { label: "Cookies policy", url: this.baseURL + "cookies-policy", target: '_blank' },
    { label: "System requirements", url: this.baseURL + "system-requirements/", target: '_blank' },
    { label: 'For parents', url: this.baseURL + 'for-parents', target: '_blank' }];
    public platformAndroid = 'Android';
    public platformIos = 'iOS';
    public units = ['bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    public cdn = "cdn";
    public macmillanXHotspotMapping = 'macmillanXHotspotMapping';

    cascadeMenuList: { id: string, name: string, isTitle?: boolean, isContainSubMenu?: boolean, active?: boolean, iconClass?: string, url?: string, queryParams?: any }[] = [
        { id: "Help", name: "Help", isTitle: false, isContainSubMenu: true, iconClass: "u-icon icon-link-external", url: this.baseURL + "help-centre/" },
        { id: "System_Requirements", isTitle: false, isContainSubMenu: true, name: "System Requirements", iconClass: "u-icon icon-link-external", url: this.baseURL + "system-requirements/", },
        { id: "Terms_&_Conditions", isTitle: false, isContainSubMenu: true, name: "Terms & Conditions", iconClass: "u-icon icon-link-external", url: this.baseURL + "terms-conditions/" },
        { id: "Privacy_Policy", isTitle: false, isContainSubMenu: true, name: "Privacy Policy", iconClass: "u-icon icon-link-external", url: this.baseURL + "privacy-policy/" },
        { id: "Cookie_Policy", isTitle: false, isContainSubMenu: true, name: "Cookie Policy", iconClass: "u-icon icon-link-external", url: this.baseURL + "cookies-policy/" }
    ];
    cascadeSchoolMenuList: { id: string, name: string, isTitle?: boolean, isContainSubMenu?: boolean, active?: boolean, icon?: string, url?: string, queryParams?: any }[] = [];

    productType = 'mee-win-electron';
    fileDownloaded = "Selected content downloaded"
    public mobileAppUrl = {
        mobileAndroidUrl: 'https://play.google.com/store/games',
        mobileAppleUrl: 'https://www.apple.com/in/app-store/'
    };
    accessCodeUrl: string = 'projects/activate-course';
    AndroidUrl: string = 'com.macmillaneducation.mee'
    helpCentreUrl: string = 'https://macmillaneducation.secure.force.com/help/bg_FAQArticle?id=kA04H000000MmO6SAK&language=en_US&retURL=https%3A%2F%2Fmacmillaneducation.secure.force.com%2Fhelp%2Fbg_SearchResults%3Fat%3DWhy%252Bcan%252527t%252BI%252Bsee%252Ba%252Bcourse%252BI%252527ve%252Bactivated%252Bon%252Bthe%252BMEE%252Bplatform%25253F%26language%3Den_US%26retURL%3Dhttps%253A%252F%252Fmacmillaneducation.secure.force.com%252Fhelp%252Fbg_HomePage';
    HOMEWORKASSESSMENTDELETE: string = "Assessment is deleted successfully.";
    HOMEWOEKDELETE:string = "Homework is deleted successfully.";
    COURSEDELETE:string = "Course is deleted successfully.";

    public BASE_URL = 'https://macmillaneducation.secure.force.com/help/bg_FAQArticle?id='	
    public QUERY_PARAMS = '&language=en_US&retURL=https%3A%2F%2Fmacmillaneducation.secure.force.com'
    public THRESHOLDVAL = 'thresholdValues';
    public DISPLAY_BASE_MESSAGE = ['may cause the app to run slowly or have trouble loading content.',
                                  'our troubleshooting guide for more information.',
                                  'close down any unnecessary browser tabs and apps.',
                                  'Performance issues detected on your device'];

    public DISPLAY_INTERNET_MESSAGE = "Your internet connection is currently unstable";
    public DISPLAY_INTERNET_SUB_MESSAGE = `This ${this.DISPLAY_BASE_MESSAGE[0]} Please check your network settings or read ${this.DISPLAY_BASE_MESSAGE[1]}`;
    public DISPLAY_CPU_MESSAGE = `${this.DISPLAY_BASE_MESSAGE[3]}`;
    public DISPLAY_CPU_SUB_MESSAGE  = `This ${this.DISPLAY_BASE_MESSAGE[0]} We recommend you ${this.DISPLAY_BASE_MESSAGE[2]} Read ${this.DISPLAY_BASE_MESSAGE[1]}`;
    public DISPLAY_BOTH_MESSAGE  = `${this.DISPLAY_BASE_MESSAGE[3]}/network`;
    public DISPLAY_BOTH_SUB_MESSAGE = `These ${this.DISPLAY_BASE_MESSAGE[0]} We recommend you check your network settings and ${this.DISPLAY_BASE_MESSAGE[2]} Read ${this.DISPLAY_BASE_MESSAGE[1]}`;
    public INTERNET_MESSAGE_URL = `${this.BASE_URL}kA04H000000MjtfSAC${this.QUERY_PARAMS}`;
    public CPU_MESSAGE_URL = `${this.BASE_URL}kA04H000000MjtkSAC${this.QUERY_PARAMS}`;
    public BOTH_MESSAGE_URL = `${this.BASE_URL}kA04H000000MjtpSAC${this.QUERY_PARAMS}`;
    public READ_MORE = 'Read more';
    public SHOW_LATER = 'Show me later';
    public INTERNETSPEEDCHECKFILESIZE = 513960;
}
export const urlValueCourseDetails = {
    Courses: 'courses',
    SchoolWork: 'school',
    Homework: 'schoolHomework',
    Assessment: 'schoolAssessment',
    Settings: 'Settings',
    HelpAndPolicies: 'Help',
    Logout: 'Logout'
};
export const urlValueCourlist = {
    Courses: 'courses',
    School_Work: 'school_work',
    SchoolWork: 'school',
    Homework: 'schoolHomework',
    Assessment: 'schoolAssessment',
    Settings:'Settings',
    HelpAndPolicies:'HelpAndPolicies',
    Logout:'Logout'
  };
  export const dashboardTypes = {
    assessment:'Assessment' ,
    homework:'Homework',
    courseBuilder:'courseBuilder'
  };

  export const dialogType = {
    assessment: 'addAssessment',
    homework: 'addHomework'
  };

  export const trophyTypes = {
    goldTrophy: 'icon-trophy-gold',
    silverTrophy: 'icon-trophy-silver',
    bronzeTrophy: 'icon-trophy-bronze',
    whiteTrophy: 'icon-trophy-white'
  };

  export const rewardDataMap: any = {
    excellent : "gold",
    great : "silver",
    good: "bronze",
    none: ""
  };

  export const DOWNLOAD_ASSESSMENT_GTM = {
    eventCategory: 'Download Paper Assessment',
    eventAction: 'No.of times paper assessment has been downloaded '
  }

  export const copyHomeWorkAssessment_GTM={
    eventCategory: 'Copy Assessment',
    eventAction: 'No.of times copy assessment has been Clicked '
  };
  export const copyHomeWork_GTM={
    eventCategory: 'Copy Homework',
    eventAction: 'No.of times copy Homework has been Clicked '
  };
  export const CdnAssets ={
    footerLogoCdn: 'footer-logo.svg',
    logoCdn: 'logo.svg',
    blackLogoCdn: 'logo-mee-black-mac.svg'
  };

  export const deleteHomework_GTM = {
    eventCategory: 'Delete Homework',
    eventAction: 'No.of times delete homework has been clicked '
  };

  export const nativeCallback:any={
    exportclasswisestudentprogresssummary:'exportClassWiseStudentProgressSummaryCallback'
  };

  