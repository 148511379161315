<maced-dialog dialogId="dialog2" *ngIf="isUserLogIn">
    <maced-dialog-content>
        <h2 class="c-modal-content__title" [attr.aria-label]="contentTitle">{{contentTitle}}</h2>
        <p class="c-modal-content__text" [attr.aria-label]="contentText">{{contentText}}</p>
        <div class="c-modal-content__list" *ngIf="updateContentData && updateContentData.length">
            <ul class="c-modal-list">
              <li *ngFor="let content of updateContentData" [attr.aria-label]="content?.courseName">
                {{content?.courseName}}</li>
            </ul>
        </div>
        <p class="c-modal-content__text">{{contentFooterText}}</p>
    </maced-dialog-content>
    <maced-dialog-footer class="c-dialog__content-footer">
        <maced-button [buttonId]=btnIdTwo [label]=btnLabelTwo color="secondary" styleClass="u-button-normal"
          (click)="dialogClicked(false)">
        </maced-button>
        <maced-button [buttonId]=btnIdOne [label]=btnLabelOne color="primary" styleClass="u-button-normal"
          (click)="dialogClicked(true)">
        </maced-button>
    </maced-dialog-footer>
  </maced-dialog>