import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class BodyClassService {

  addBodyClass(className: string) {
    document.body.classList.add(className);
  }

  removeBodyClass(className: string) {
    document.body.classList.remove(className);
  }
}
