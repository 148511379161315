export class ITocPreference {
    unitId!: number;
    lessonId!: number;
    tocStyle!: string; //list or grid
//list or grid
}


// Landscape Bookviwer
export interface FullScreenDocument extends Document {
    documentElement: FullScreenDocumentElement;
    mozFullScreenElement?: Element;
    msFullscreenElement?: Element;
    webkitFullscreenElement?: Element;
    msExitFullscreen?: () => void;
    mozCancelFullScreen?: () => void;
    webkitExitFullscreen?: () => void;
    webkitExitFullScreen?: () => void;
  }
  
  interface FullScreenDocumentElement extends HTMLElement {
    msRequestFullscreen?: () => void;
    mozRequestFullScreen?: () => void;
    webkitRequestFullscreen?: () => void;
    webkitRequestFullScreen?: () => void;
  }