import { NgModule,CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { GlobalSystemErrorComponent } from './global-system-error/global-system-error.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '../shared/shared.module';
import { CommonModule } from '@angular/common';



@NgModule({
  declarations: [
    GlobalSystemErrorComponent
  ],
  exports:[GlobalSystemErrorComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    SharedModule
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class SystemErrorModule { }
