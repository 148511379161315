import { ChangeDetectorRef, Component, Injector } from '@angular/core';
import { DropdownOption } from '@maced/angular-components/lib/dropdown/dropdown.model';
import { DataMigrationService } from 'src/app/services/data-migration.service';

@Component({
  selector: 'app-data-migration-status',
  templateUrl: './data-migration-status.component.html',
  styleUrls: ['./data-migration-status.component.scss']
})
export class DataMigrationStatusComponent {
  options: DropdownOption[] = [];
  selectedOptions!: DropdownOption;
  dropdownId: string = 'dropdownId';
  buttonLabel: string = 'Submit';
  apiResponse!: any;
  public _cd!: ChangeDetectorRef;
  status: any;
  obj:DropdownOption = {
    id: 'item1',
    label: 'Insert Module Details',
    name: 'insertModule',
    active: true
  };
  obj1:DropdownOption = {
    id: 'item2',
    label: 'Insert Homework Module Details',
    name: 'insertHomeWorkModule',
    active: false
  };
  obj2:DropdownOption = {
    id: 'item3',
    label: 'Insert Assessment Module Details',
    name: 'insertAssessmentModule',
    active: false
  };
  // To do
  obj3:DropdownOption = {
    id: 'item4',
    label: 'Insert Annotation Module Details',
    name: 'insertAnnotationModule',
    active: false
  };
  obj4:DropdownOption = {
    id: 'item5',
    label: 'Insert Navio Student Avatar Guide Rewards Info',
    name: 'insertNavioStudentAvatarGuideRewardsInfo',
    active: false
  };
  obj5:DropdownOption = {
    id: 'item6',
    label: 'Update Student Related Details',
    name: 'updateStudentRelatedDetails',
    active: false
  };
  obj6:DropdownOption = {
    id: 'item7',
    label: 'Insert Homework Student Module Details',
    name: 'insertHomeworkStudentModuleDetails',
    active: false
  };
  obj7:DropdownOption = {
    id: 'item8',
    label: 'Insert Assessment Student Module Details',
    name: 'insertassessmentstudentmoduledetails',
    active: false
  };

  obj8:DropdownOption = {
    id: 'item9',
    label: 'Check Users Final Migration Status',
    name: 'checkusersfinaldatamigrationstatus',
    active: false
  };
  obj9:DropdownOption = {
    id: 'item10',
    label: 'Get Insert Homework Student Remaining Attempt Details',
    name: 'getinserthomeworkstudentremainingattemptsdetails',
    active: false
  };
  obj10:DropdownOption = {
    id: 'item11',
    label: 'Get Insert Eplanner Module Details',
    name: 'getInsertEplannerModuleDetails',
    active: false
  };

  constructor(injector: Injector,public dataMigrationService: DataMigrationService) {
    this._cd = injector.get<ChangeDetectorRef>(ChangeDetectorRef);
  }
  ngOnInit(): void {
    this.addOption();
  }
  addOption() {
    this.options.push(this.obj);
    this.options.push(this.obj1);
    this.options.push(this.obj2);
    this.options.push(this.obj3);
    this.options.push(this.obj4);
    this.options.push(this.obj5);
    this.options.push(this.obj6);
    this.options.push(this.obj7);
    this.options.push(this.obj8);
    this.options.push(this.obj9);
    this.options.push(this.obj10);
    this.selectedOptions = this.options[0];
  }

  onClick(option: any) {
    this.options.forEach(element => {
      if(element.name === option.name) {
        element.active = true;
        this.selectedOptions = option;
      } else {
        element.active = false;
      }
    });
  }

  submitResponse() {
    const payLoad: any = {
      "attemptNo": 1
    };
    if(this.selectedOptions.name === 'insertModule') {
      this.dataMigrationService.getInsertModuleDetails(payLoad).subscribe((res: any) => {
        this.apiResponse = res;
        this.status = this.apiResponse.status;
        this._cd.detectChanges();
      });
    } else if(this.selectedOptions.name === 'insertHomeWorkModule') {
      this.dataMigrationService.getInsertHomeworkModuleDetails(payLoad).subscribe((res: any) => {
        this.apiResponse = res;
        this.status = this.apiResponse.status;
        this._cd.detectChanges();
      });
    } 
    else if(this.selectedOptions.name === 'insertAssessmentModule') {
      this.dataMigrationService.getInsertAssessmentModuleDetails(payLoad).subscribe((res: any) => {
        this.apiResponse = res;
        this.status = this.apiResponse.status;
        this._cd.detectChanges();
      });
    }
    else if(this.selectedOptions.name === 'insertAnnotationModule') {
      this.dataMigrationService.getInsertAnnotationModuleDetails(payLoad).subscribe((res: any) => {
        this.apiResponse = res;
        this.status = this.apiResponse.status;
        this._cd.detectChanges();
      });
    }
    else if(this.selectedOptions.name === 'insertNavioStudentAvatarGuideRewardsInfo') {
      this.dataMigrationService.getInsertNavioStudentAvatarGuideRewardsInfo(payLoad).subscribe((res: any) => {
        this.apiResponse = res;
        this.status = this.apiResponse.status;
        this._cd.detectChanges();
      });
    }
    else if(this.selectedOptions.name === 'updateStudentRelatedDetails') {
      this.dataMigrationService.updateStudentRelatedDetails(payLoad).subscribe((res: any) => {
        this.apiResponse = res;
        this.status = this.apiResponse.status;
        this._cd.detectChanges();
      });
    }
    else if(this.selectedOptions.name === 'insertassessmentstudentmoduledetails') {
      this.dataMigrationService.getInsertAssessmentStudentModuleDetails(payLoad).subscribe((res: any) => {
        this.apiResponse = res;
        this.status = this.apiResponse.status;
        this._cd.detectChanges();
      });
    }
    else if(this.selectedOptions.name === 'checkusersfinaldatamigrationstatus') {
      this.dataMigrationService.checkUsersFinalDataMigrationStatus().subscribe((res: any) => {
        this.apiResponse = res;
        this.status = this.apiResponse.status;
        this._cd.detectChanges();
      });
    }
    else if(this.selectedOptions.name === 'getinserthomeworkstudentremainingattemptsdetails') {
      this.dataMigrationService.getInsertHomeworkStudentRemainingAttemptsDetails(payLoad).subscribe((res: any) => {
        this.apiResponse = res;
        this.status = this.apiResponse.status;
        this._cd.detectChanges();
      });
    }
    else if(this.selectedOptions.name === 'getInsertEplannerModuleDetails') {
      this.dataMigrationService.getInsertEplannerModuleDetails(payLoad).subscribe((res: any) => {
        this.apiResponse = res;
        this.status = this.apiResponse.status;
        this._cd.detectChanges();
      });
    }
    else {
      this.dataMigrationService.getInsertHomeworkStudentModuleDetails(payLoad).subscribe((res: any) => {
        this.apiResponse = res;
        this.status = this.apiResponse.status;
        this._cd.detectChanges();
      });
    }
  }
}