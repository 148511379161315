import { ChangeDetectorRef, Component, EventEmitter, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Data } from 'src/app/core/models/data';
import { AccountService } from 'src/app/core/services/account.service';
import { CommonService } from 'src/app/core/services/common.service';
import { GlobalSettings } from 'src/app/global.settings';

@Component({
  selector: 'app-global-system-error',
  templateUrl: './global-system-error.component.html',
  styleUrls: ['./global-system-error.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class GlobalSystemErrorComponent implements OnInit {
  @Output() public resetPageLoad:any= new EventEmitter();
  currentUrl: string = "";
  emptyDekstopSystemErrorId = "emptyGlobalStateId";//500 
  emptyDekstopSystemErrorImg: string = GlobalSettings.routePath + '/assets/default/images/error_blue/No-lesson-selected_Blue.svg';
  emptyDekstopSystemErrorTitle: string = `Opps cant find the page`;
  emptyDekstopSystemErrorMessage: string = 'To open a downloaded lessons access my downloads';
  errorStatusCode: any = ``;
  showGatewayTimeOutloaderFlag = false;
  systemErrobj:any={};
  showGatewayTimeOutloaderTimeLeft = 10;
  bookshelfLabel: any;
  accessDeniedLabel: any;
  public tryAgain?:string;
	public cancel?:string;
  ariaLabels: any = {
    imageAltText: ''
  }
  isContentAccessible: boolean = false;
  constructor(public commonService: CommonService, public data: Data, private _cd: ChangeDetectorRef,
    public accountService: AccountService, public router: Router) { }

  ngOnInit(): void {
    this.displayGlobalSystemError();
    this.displayGlobalSystemErrorNative();
  }
  displayGlobalSystemErrorNative(){
    if(GlobalSettings.isNative){
      this.commonService.globalSystemErrorPageNative$.subscribe(statusObj=>{
        this.processSystemError(statusObj);
        this._cd.detectChanges();//For App device forcefull update for DOMN'
    });
    }
  }

  displayGlobalSystemError() {
    this.commonService.globalSystemErrorPage$.subscribe((statusObj: any) => {
      if (statusObj.showErrorPage) {
        this.processSystemError(statusObj);
        this._cd.detectChanges();//For App device forcefull update for DOMN'
      }
    });
  }
  processSystemError(statusObj: any) {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.currentUrl = event.url;
      }
    });
    const errorDetails = this.data.languageJson?.globalError?.errorCode;
    this.bookshelfLabel = this.data.languageJson?.globalError?.errorCode?.optionalLink;
    this.tryAgain=this.data?.languageJson?.global?.global_button?.tryAgain;
		this.cancel=this.data?.languageJson?.global?.global_button?.cancel;
    if (errorDetails) {
      this.getErrorMessages(statusObj, errorDetails);
    } else {
      setTimeout(() => {
        const errorDetails = this.data.languageJson?.globalError?.errorCode;
        this.bookshelfLabel = this.data.languageJson?.globalError?.errorCode?.optionalLink;
        this.getErrorMessages(statusObj, errorDetails);
      }, 3000);
    }
  }

  getErrorMessages(statusObj: any, errorDetails: any) {
    this.isContentAccessible = statusObj.isContentAccessible ?? false;
    this.accessDeniedLabel = errorDetails & errorDetails['403'] ? errorDetails['403']?.subTitle_1 : '';
    this.showGatewayTimeOutloaderFlag = false;
    this.errorStatusCode = `Error ${statusObj.statusCode}`;
    this.emptyDekstopSystemErrorTitle = statusObj.from === 'student-details-page' ? this.accessDeniedLabel : this.data.languageJson?.global?.no_page;
    this.ariaLabels.imageAltText = (errorDetails && errorDetails['403'] && statusObj.from === 'student-details-page') ? errorDetails['403']?.altText : 'content-image';
    this.systemErrobj=statusObj;
    switch (statusObj.statusCode) {
      case 400:
        this.emptyDekstopSystemErrorImg = GlobalSettings.routePath + '/assets/default/images/error_blue/400_Bad-request.svg';
        this.emptyDekstopSystemErrorMessage = errorDetails['400'].subTitle_1;
        break;
      case 401:
        this.emptyDekstopSystemErrorImg = GlobalSettings.routePath + '/assets/default/images/error_blue/401_Unauthorized-request.svg';
        this.emptyDekstopSystemErrorMessage = errorDetails['401']?.subTitle_2;
        break;
      case 403:
        this.emptyDekstopSystemErrorImg = GlobalSettings.routePath + '/assets/default/images/error_blue/403_Access-denied.svg';
        this.emptyDekstopSystemErrorMessage = errorDetails['403']?.subTitle_2;
        break;
      case 404:
        this.emptyDekstopSystemErrorImg = GlobalSettings.routePath + '/assets/default/images/error_blue/404_Page-not-found.svg';
        this.emptyDekstopSystemErrorMessage = errorDetails['404']?.subTitle_2;
        break;
        case 422:
          this.emptyDekstopSystemErrorImg = GlobalSettings.routePath + '/assets/default/images/error_blue/401_Unauthorized-request.svg';
          this.emptyDekstopSystemErrorMessage = errorDetails['422']?.subTitle_2;
          break;  
      case 500:
        this.emptyDekstopSystemErrorImg = GlobalSettings.routePath + '/assets/default/images/error_blue/500_Internal-Server-Error.svg';
        this.emptyDekstopSystemErrorMessage = errorDetails['500']?.subTitle_2;
        break;
      case 502:
        this.emptyDekstopSystemErrorImg = GlobalSettings.routePath + '/assets/default/images/error_blue/502_Bad-gateway.svg';
        this.emptyDekstopSystemErrorMessage = errorDetails['502']?.subTitle_2;
        break;
      case 503:
        this.emptyDekstopSystemErrorImg = GlobalSettings.routePath + '/assets/default/images/error_blue/503_Service-unavailable.svg';
        this.emptyDekstopSystemErrorMessage = errorDetails['503']?.subTitle_2;
        break;
      case 504:
        this.emptyDekstopSystemErrorImg = GlobalSettings.routePath + '/assets/default/images/error_blue/504_Gateway-timeout.svg';
        this.emptyDekstopSystemErrorMessage = errorDetails['504']?.subTitle_2;
        break;
    }
  }

  toggleTryAgain() {
    let _self = this;
    _self.showGatewayTimeOutloaderTimeLeft = 10;
    let offlineIntervalCheck = setInterval(() => {
      if (_self.showGatewayTimeOutloaderTimeLeft > 0) {

        _self.showGatewayTimeOutloaderFlag = true;
        _self.showGatewayTimeOutloaderTimeLeft--;
        this._cd.detectChanges();
      }
      else if (_self.showGatewayTimeOutloaderTimeLeft == 0) {
        if (_self.systemErrobj?.statusCode==401) {
          _self.accountService.logout(true);
        } else {
          _self.showGatewayTimeOutloaderFlag = false;
          _self.commonService.globalSystemErrorPage$.next({});
          clearInterval(offlineIntervalCheck);
          if (GlobalSettings.isBrowser) {
            window.location.reload();
          } else {
            _self.resetPageLoad.emit();
            _self._cd.detectChanges();
          }
        }
        this._cd.detectChanges();
      } else {
        _self.showGatewayTimeOutloaderTimeLeft = 30;
      }
    }, 1000);

  }

  ngOnDestroy() {
      if(this.currentUrl.includes('/homework-assessment-student/global-system-error') || this.currentUrl.includes('/course-builder/global-system-error') || this.systemErrobj.from !== 'student-details-page' ){
        this.commonService.globalSystemErrorPage$.next({});
      }
  }
}
