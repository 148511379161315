import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { SsrCookieService } from 'ngx-cookie-service-ssr';
import { Subscription } from 'rxjs';
import { Data } from 'src/app/core/models/data';
import { LanguageExtService } from 'src/app/services/language-ext.service';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss']
})
export class LoaderComponent implements OnInit, OnDestroy {
  private loaderSubscription: Subscription[] = [];
  isLoadingDiv: boolean = false ;
  isLoading_login:boolean= false;
  isLoading_Kmli:boolean= false;
  loadingLabel: string ='Loading';

  constructor(
    private cdref: ChangeDetectorRef,
    public data :Data,
    public languageExtService: LanguageExtService,
    public cookieService: SsrCookieService
  ) { }

  ngOnInit(): void {
    this.loaderSubscription.push(this.languageExtService.languageLoadNotifier.subscribe(() => {
      if (this.data && this.data.languageJson) {
        this.loadingLabel = this.data.languageJson?.global?.loading;
      }
    }));
    this.loaderSubscription.push(this.data.isLoading.subscribe((value: any) => {
      this.loadingLabel = (this.cookieService.get('langKey') == 'es') ? 'Cargando' : this.loadingLabel;
      this.isLoadingDiv = value;
      this.cdref.detectChanges();
    }));
    this.loaderSubscription.push(this.data.isLoadingLgoin.subscribe((value: any) => {
      this.isLoading_login = value;
      this.cdref.detectChanges();
    }));
    this.loaderSubscription.push(this.data.isLoadingKmli.subscribe((value: any) => {
      this.isLoading_Kmli = value;
      this.cdref.detectChanges();
    }));
  }

  ngOnDestroy(): void {
    if (this.loaderSubscription) {
      this.loaderSubscription.forEach((subscription:Subscription) => subscription.unsubscribe());
    }
  }
}
