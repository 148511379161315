import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Subject, takeUntil } from 'rxjs';
import { Data } from 'src/app/core/models/data';
import { ReadiumReaderService } from 'src/app/core/services/readium-reader.service';
import { UtilityService } from 'src/app/services/utility.service';

@Component({
  selector: 'app-goto-page',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './goto-page.component.html',
  styleUrls: ['./goto-page.component.scss']
})
export class GotoPageComponent implements OnInit, OnDestroy{
  formElement!: UntypedFormGroup;
  noOfPages: string = '';
  currentPageNo: string = '';
  hasError: boolean = false;
  closeLabel!: string;
  goLabel!: string;
  ofLabel!: string;
  oldPageValue!: string;

  private destroy$ = new Subject<boolean>();
  constructor(
    public data: Data,
    public _cd: ChangeDetectorRef,
    public utilityService: UtilityService,
    public readiumReaderService: ReadiumReaderService,
    public formBuilder: UntypedFormBuilder
  ) {}

  ngOnInit(): void {
    this.setLanguageData();
    this.showCurrentPageNumberSubscribe();
    this.formElement = this.formBuilder.group({
      gotoControl: ['']
    });
    this.currentPageNo = this.data.goToPageNmber;
    this.formElement.controls.gotoControl.reset();
    this.formElement.controls.gotoControl.setValue(this.currentPageNo);
    this.noOfPages = `${this.ofLabel} ${this.data.totalNumberOfPages}`;
  }

  setLanguageData() {
    if (this.data.languageJson?.bookviewer) {
      let bookviewerJson = this.data.languageJson?.bookviewer;
      this.closeLabel = bookviewerJson.icons?.close;
      this.goLabel = bookviewerJson.icons?.go;
      this.ofLabel = bookviewerJson.icons?.of;
    }
  }

  goToPageClick() {
    this.utilityService.checkInternetConnectivityFileExist().pipe(takeUntil(this.destroy$)).subscribe(internetStatus => {
      if (internetStatus) {
        let finalPageNo = '';
        let searchPageNo = this.formElement.value.gotoControl.trim().toLowerCase();
        let pageArray = this.data.spotOnData.componentPageZoneMap.bookPageOrder;

        for (let searchPages of pageArray) {
          let pages = searchPages.toLowerCase();
          if (searchPageNo === pages) {
            finalPageNo = pages;
            break;
          }
          else if (pages.includes('-')) {
            const twoPages = pages.split('-');
            if (searchPageNo === twoPages[0]) {
              finalPageNo = twoPages[0];
              break;
            }
            else if (searchPageNo === twoPages[1]) {
              finalPageNo = twoPages[1];
              break;
            }
          }
        }
        if (finalPageNo) {
          if(this.data.isQuicklinksDisabledInBrowser){
            this.data.rightSidePanelOpen.next(false);
          }
          this.readiumReaderService.currentReader$.next({ booktriggerType: `goToSearch`, goToPageInput: finalPageNo });
        }
        else {
          this.hasError = true;
          this._cd.detectChanges();
        }
      }
    });
  }

  showCurrentPageNumberSubscribe() {
    this.data.updateCurrentPageNumber.subscribe(() => {
      this.hasError = false;
      this.currentPageNo = this.data.goToPageNmber;
      this.formElement.controls.gotoControl.reset();
      this.formElement.controls.gotoControl.setValue(this.currentPageNo);
      this.noOfPages = `${this.ofLabel} ${this.data.totalNumberOfPages}`;
      if(this.oldPageValue !== this.currentPageNo) {
        this.utilityService.reRenderQuicklink(this.currentPageNo);
        this.data.isLoading.emit(true)
        }
        this.oldPageValue = this.data.goToPageNmber;
      this._cd.detectChanges();
    });
  }

  goToPageClose() {
    this.data.showGotoForMobile = false;
    this._cd.detectChanges();
  }

  onInputChange(event: any) {
    this.hasError = false;
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
