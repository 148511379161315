export class ACEToken {
    ".expires": string;
    ".issued": string;
    AccountId: string='';
    AccountKey: string='';
    DefaultItemsCount: string='';
    InstitutionID: string='';
    OrganisationName: string='';
    InstitutionTimeZone: string='';
    IsClientCredentials: string='';
    IsConfirmed: string='';
    Level: string='';
    LogoutUrl: string='';
    MEEFirstName: string='';
    MEELastName: string='';
    MEEUserId: string='';
    MachineIdentifier: string='';
    ProductFrameworkTypeIdentifier: string='';
    ProductFrameworkTypeIds: string='';
    Role: string='';
    RoleID: string='';
    URL: string='';
    access_token: string='';
    action_required: string='';
    audience: string='';
    expires_in: number | undefined;
    refresh_token: string='';
    token_type: string='';
    LogoUrl:string='';
    redisKey:string="";
    fbc?:string="";
    id_token?:string="";
    meeRole?:string="";
    populatedata?:string="";
    issalesrep?:string= "";
    userTheme?:string="";
}

export class MEEToken {
    accessToken: string='';
    tokenType: string='';
    expiresIn: string='';
    refreshToken: string='';
    email: string='';
    firstName: string='';
    lastName: string='';
    userId: string='';
    username: false | undefined;
    issued: string='';
    expires: string='';
    actionRequired: string='';
    error: string='';
}
