import { HttpClient} from '@angular/common/http';
import { EventEmitter,Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { BehaviorSubject, catchError, Observable, of, tap } from 'rxjs';
import { environment } from 'src/environments/environment';
import { GlobalSettings } from '../global.settings';
import { Data } from '../core/models/data';
import { CommonService } from '../core/services/common.service';
import { SsrCookieService } from 'ngx-cookie-service-ssr';
import { ApiConstants } from '../core/constant/api.constant';
import { CourseService } from '../core/services/course.service';
import { UtilityService } from './utility.service';
import { isPlatformBrowser } from '@angular/common';
import { TransferState, makeStateKey } from '@angular/platform-browser';
const LANG_KEY = makeStateKey<any>('languageKey');
@Injectable({
  providedIn: 'root',
})
export class LanguageExtService {
  //Managing the state of the language data fetched.
  private readonly _langData = new BehaviorSubject<any>(undefined);

  //An access point to state data of the language.
  readonly langData$ = this._langData.asObservable();

  //The flags for desktop and browser is to be configured when the electron application is setup.
  isBrowser: boolean = GlobalSettings.isBrowser;
  isDesktop: boolean = GlobalSettings.isDesktop;
  isNative: boolean = GlobalSettings.isNative;

  //Notify the subscriber after loading language data
  public languageLoadNotifier: EventEmitter<boolean> = new EventEmitter();
  someFunctionThatUsesLoadLanguageForPage: any;
  languagedata :any = [];
  // Injecting Http Clinet to make HTTP Calls.
  constructor(
    public http: HttpClient,
    public data: Data,
    public commonService: CommonService,
    public cookieService: SsrCookieService,
    public courseService: CourseService,
    private utilities:UtilityService,
    @Inject(PLATFORM_ID) public platformId: Object,
    private transferState: TransferState,
  ) {}

  /*  
      Method: loadLanguageForPage
      Params: 'language' of type string which specifies the selected language i.e 'en','es'..etc.
              'page' of type string which specifies the page name using which we will download from CDN.
      Description: The method determines where to fetch the data for language for the page that is been loaded,
                   we have to pass the 'language' that is selected and 'page' which is loaded, the page name should math the page name in CDN.
                   using which we will download the data as JSON and use a util method to flattern the array receved to get the desired the data.
  */
  public loadLanguageForPage(language: string, fileName: string) {
    this.cookieService.delete('langKey');
    this.cookieService.set('langKey', language);
    if(this.data.languageJson.languageType && this.data.languageJson.languageType == language){
      this._langData.next(this.data.languageJson);
      return;
    }
    if (this.isBrowser) {
      if(!isPlatformBrowser(this.platformId)){
        this.commonService.loadJSON(language).subscribe((data) => {
          this._langData.next(data);
          this.transferState.set(LANG_KEY, data);
        });
      }
      if(isPlatformBrowser(this.platformId)){
        this.languagedata  = this.transferState.get(LANG_KEY, null);
        if(!this.languagedata){
          this.commonService.loadJSON(language).subscribe((data) => {
            this._langData.next(data);
          });
        }else{
          this._langData.next(this.languagedata);
          this.transferState.remove(LANG_KEY)
        }
      }   
    } else if (this.isDesktop) {
      //Prepare the url for node endpoint which will download the file for us to be availabel offline.
      let nodeApiUrl = GlobalSettings.LocalNodeServerBaseUrl + '/api/download/getLanguageData';
      this.courseService.checkFileExistsGlobal('downloaded_files/language_resources/meeLanguageTranslation_' +language +'.json')
      .subscribe((fileExistStatus) => {
          if (fileExistStatus) {
            this.http .post(nodeApiUrl, {fileName: `${fileName}_${language}.json`,env: environment.name,})
            .subscribe((data) => {
                this._langData.next(data);
            });
          }
        });
    } else if (this.isNative) {
      let url ='useruploadedfiles/languagefiles/meeplatform/meeLanguageTranslation_' +
        language +
        '.json';
      this.courseService
        .checkFileExistsGlobal(url)
        .subscribe((fileExistStatus) => {
          if (fileExistStatus) {
            readLanguageTranslationCallback(url, (res: any) => {
              if (typeof(res) === 'string'){
                res = JSON.parse(res);
              }
              this._langData.next(res);
            });
          }
        });
    }
  }

  loadAllLanguagesInDevice(langArr: string[], callback: Function) {
    if (this.isNative) {
      let url = 'useruploadedfiles/languagefiles/meeplatform/';
      let arr = langArr.map((val: any) => {
        return url + 'meeLanguageTranslation_' + val + '.json';
      });
      let showHideFileUrl =
        'useruploadedfiles/menuconfig/meeplatform/functionality_show_hide.json';
      arr = [...arr, showHideFileUrl];
      downloadLanguagesCallback(arr, (res: any) => {
        console.log('downloadLanguagesCallback success response:', res);
        res = JSON.parse(res);
        callback(res);
      });
    } else if (this.isDesktop) {
      //Prepare the url for node endpoint which will download the file for us to be availabel offline.
      let nodeApiUrl =
        GlobalSettings.LocalNodeServerBaseUrl +
        '/api/download/downloadLanguageData';

      let fileName = 'meeLanguageTranslation';
      let arr = langArr.map((val: any) => {
        return fileName + '_' + val + '.json';
      });
      let showHideFileUrl = 'functionality_show_hide.json';
      arr = [...arr, showHideFileUrl];
      // Fetch the Json from downloaded files.
      this.http
        .post(nodeApiUrl, {fileUrl: arr, env: environment.name })
        .subscribe((data) => {
          // Flattern the data to have direct key value pair to use in data.
          console.log('desktop app response:', data);
          callback(data);
        });
    }
  }

  downloadLanguageForDesktop(langArr: string[],isForObv = false) {
    if (this.isDesktop) {
      //Prepare the url for node endpoint which will download the file for us to be availabel offline.
      let nodeApiUrl =
        GlobalSettings.LocalNodeServerBaseUrl +
        '/api/download/downloadLanguageData';

      let fileName = 'meeLanguageTranslation';
      let arr = langArr.map((val: any) => {
        return fileName + '_' + val + '.json';
      });
      let showHideFileUrl = 'functionality_show_hide.json';
      arr = [...arr, showHideFileUrl];
      // Fetch the Json from downloaded files.
      this.http
        .post(nodeApiUrl, {isForObv:isForObv, fileUrl: arr, env: environment.name })
        .subscribe((data) => {
          // Flattern the data to have direct key value pair to use in data.
          console.log('desktop app response:', data);
        });
    }
  }

  storLangKey(key: any, langId: any): Observable<any> {
    this.data.selectedLanguage = key;
    if (GlobalSettings.isDesktop) {
      const url: string =
        GlobalSettings.LocalNodeServerBaseUrl +
        '/api/setLanguageDesktop?key=' +
        key +
        '&langId=' +
        langId;
      return this.http.request('GET', url, { responseType: 'json' }).pipe(
        tap((response) => {
          return response;
        }),
        catchError(this.commonService.handleAPIError('getResponseUrl', []))
      );
    } else if (GlobalSettings.isBrowser) {
      if(isPlatformBrowser(this.platformId)){
        let url: string = ApiConstants.siteUrl +'/api/setLanguage?key='+key+'&langId='+langId+'&randomNum='+this.utilities.getRandomBytes();
        return this.http.get<any>(url);
      }else{
        return of([])
      }
     
    } else {
      return new Observable((observer) => {
        const today = new Date();
        const newDate = new Date(today.getTime() + 15 * 24 * 60 * 60 * 1000);
        const data = { langKeyName: key, langId: langId, timeStamp: newDate };
        setLanguageKeyCallback(data, (result: string) => {
          result = JSON.parse(result);
          if (result) {
            observer.next(result);
          }
        });
      });
    }
  }
  getLanguageKey(keyName: string): Observable<any> {
    if (GlobalSettings.isNative) {
      return new Observable((observer) => {
        getLanguageKeyCallback({ langKeyName: keyName }, (response: string) => {
          let result = JSON.parse(response);
          if (result) {
            observer.next(result.result);
          }
        });
      });
    } else {
      return new Observable((observer) => {
        observer.next(this.cookieService.get(keyName));
      });
    }
  }
}

