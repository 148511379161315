import { ACEToken, MEEToken } from './token';

export class UserInfo {
    public aceToken?: ACEToken;
    public aceTokenString?: string;
    public meeTokenString?: string;
    public meeToken?: MEEToken;
    public meeUSerID?: string;
    public keepMeLogin?: boolean;
    public username?: string;
    public isUserLoggedIn: boolean=false;
    public accessToken?: string;
    public isExpired: boolean=false;
    public meeTokenApiResponseData : any;
}
