import { isPlatformServer } from '@angular/common';
import {Inject, Injectable, PLATFORM_ID, Renderer2, RendererFactory2 } from '@angular/core';
import { makeStateKey, TransferState } from "@angular/platform-browser";
import { ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { first, tap } from 'rxjs/operators';
import { Data } from 'src/app/core/models/data';
import { ACEToken } from 'src/app/core/models/token';
import { GlobalSettings } from 'src/app/global.settings';
import { AuthService } from './auth.service';
import { productFrameworkTypeIdentifier } from '../models/enum';
import { ToastService } from 'src/app/services/toast.service';
import { TokenService } from './token.service';
import { AccountService } from './account.service';
import { CommonService } from './common.service';
import { LoggerService } from './logger.service';
@Injectable()
export class AuthResolver implements Resolve<ACEToken> {
    isOCID: string = 'false';
    isSkipUserCheck: string = 'false';
    resp: any = new ACEToken;
    ottKey: string = 'OTT';
    _renderer: Renderer2;
    constructor(
        private toastService: ToastService,
        private data: Data,
        private router: Router,
        private authService: AuthService,
        private transferState: TransferState,
        private tokenService: TokenService,
        private accountService: AccountService,
        private commonService: CommonService,
        rendererFactory: RendererFactory2,
        private logger:LoggerService,
        @Inject(PLATFORM_ID) private platformId: any) {
        this._renderer = rendererFactory.createRenderer(null, null);
    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<ACEToken> {
        this.toastService.close();
        let OTT = '';
        let machineIdentifier = '';
        let isLoadTest = '';
        let redirectToURL = window.location.href;
        const urlSearchParams = new URLSearchParams(redirectToURL);
        this.data.isMeePortalNavigation = urlSearchParams.get('isMeePortalNavigation')?.toLowerCase() === 'true';        
        if(route.queryParams.path){this.data.globalQuerypath = route.queryParams;}
        if(route.queryParams.path && !GlobalSettings.isBrowser){this.data.clearCacheData = true}
        if (GlobalSettings.isNative && window.location.href && window.location.href.includes(this.ottKey)) {
            OTT = window.location.href.split('&')[0].split('=')[1];
            machineIdentifier = window.location.href.split('machineIdentifier=')[1].split('&')[0];
            isLoadTest = window.location.href.split('isLoadTest=')[1].split('&')[0];

            if (isLoadTest.charAt(isLoadTest.length - 1) === '#') {
                isLoadTest = isLoadTest.substring(0, isLoadTest.length - 1);
            }
        } else {
            OTT = route.queryParams[GlobalSettings.isCoreApi ? 'ott' : 'OTT'];
            machineIdentifier = route.queryParams[GlobalSettings.isCoreApi ? 'machineidentifier' : 'machineIdentifier'];
            isLoadTest = route.queryParams[GlobalSettings.isCoreApi ? 'false' : 'isLoadTest'];

        }
        const TOKEN_KEY = makeStateKey<ACEToken>("tokenKey-" + OTT);
        if (this.transferState.hasKey(TOKEN_KEY)) {
            const tokenObj = this.transferState.get(TOKEN_KEY, this.resp);
            this.transferState.remove(TOKEN_KEY);
            this.data.aceToken = tokenObj;
            if (Object.keys(tokenObj).length > 0) {
                this.data.isUserLogIn = true;
                this.data.loadMenuconfigAfterLogin.next(true);
                this.data.isAuthenticated = true;
                this._renderer.addClass(document.body, 'modal-open');
            }
            if (GlobalSettings.isBrowser) {
                this.tokenService.setRedisData(tokenObj);
            }
            this.data.isLoadingLgoin.next(true);
            this.getThresholdValuesForSpeedCheck();
            this.data.globalURL = redirectToURL;
            this.router.navigate(['courses/courselist']);

            return of(tokenObj);
        }
        else {
            let redirectionRoute;
            this.login(OTT, redirectionRoute, machineIdentifier, isLoadTest);
            //This condition will be executed when the user is already logged in.
            if (OTT === undefined) {
                //If the user is already logged in, this checkKMLIStatus  will redirect them to the courses page.
                this.accountService.checkKMLIStatus().subscribe((status: any) => {
                    this.data.isKMLIChecked = status && status.length > 0 && status[0].keepMeLoggedIn ? status[0].keepMeLoggedIn : false;
                    if (this.data.isKMLIChecked || this.data.isOfflineUser) {
                        this.data.loadMenuconfigAfterLogin.next(true);
                        this.accountService.setKMLI();
                        this.commonService.isLoading_hide();
                    } else {
                        this.data.isLoadingLgoin.next(false);
                    }
                })
                return this.resp;
            } else {
                return this.login(OTT, redirectionRoute, machineIdentifier, isLoadTest)
                    .pipe(
                        first(),
                        tap(accessToken => {
                            /** Currently sending static value to getMasterDetails() for august release 
                             * After August release the static value needs to be replaced with => accessToken.ProductFrameworkTypeIdentifier
                            */
                            if (GlobalSettings.isNative) {
                                this.data.isInNative = true;
                                getDeviceTokenFromMobileCallback((res: any) => {
                                    this.data.deviceRootPath = JSON.parse(res).deviceRootPath;
                                    this.data.deviceType = JSON.parse(res).deviceType;
                                    this.data.deviceOSType = JSON.parse(res).deviceOS;

                                });
                            }
                            if (!GlobalSettings.isBrowser) {
                                this.authService.getMasterDetails(productFrameworkTypeIdentifier, accessToken.MEEUserId).subscribe((res: any) => {
                                });
                            }
                            if (isPlatformServer(this.platformId) || GlobalSettings.isNative) {
                                this.transferState.set(TOKEN_KEY, accessToken);
                            }
                            this.data.aceToken = accessToken;
                            if(GlobalSettings.isNative) {
                                let redisData: any = { "key":  `cachetoken_cachetoken_${this.data.aceToken.MEEUserId}_${this.data.aceToken.MachineIdentifier}`, "value": this.data.aceToken, "cacheValidity": 21600 }
                                this.accountService.setRedisData(redisData).subscribe();
                            }
                            if (Object.keys(accessToken).length > 0) {
                                this.data.isUserLogIn = true;
                                this.data.loadMenuconfigAfterLogin.next(true);
                                this.data.isAuthenticated = true;
                                this._renderer.addClass(document.body, 'modal-open');
                            }
                            if (GlobalSettings.isBrowser) {
                                this.tokenService.setRedisData(accessToken);
                            }
                            this.data.isLoadingLgoin.next(true);
                            this.getThresholdValuesForSpeedCheck();
                            this.router.navigate(['courses/courselist']);

                        })
                    );
            }
        }

    }
    login(OTT?: string, redirectionPage?: string, machineIdentifier?: string, isLoadTest?: string, contentUnitId?: string, isHomeworkNavigation?: any, sharableHomeworkId?: string) {
        this.data.isLoadingLgoin.next(true);
        if (redirectionPage) {
            redirectionPage = redirectionPage.split('redirectionPage=')[1];
        }
        let login_userid: any = null;
        let login_password: any = null;
        return this.authService.login(login_userid, login_password, OTT, machineIdentifier, isLoadTest, contentUnitId, this.isOCID, this.isSkipUserCheck);
    }

    getThresholdValuesForSpeedCheck() {
        this.commonService.getThresholdValues().subscribe((res) => {
            if (res) {
                this.data.enablePerformanceMeter = res.enablePerformanceMeter;
                this.data.snoozeTimeInMin = res.snoozeTimeInMin;
                this.data.stopSnoozeTime = res.stopSnoozeTime;
                this.data.internetSpeedThreshold = res.InternetSpeed;
                this.data.cpuUsageThreshold = res.CPU_Usage;
                this.data.internetSpeedCheckFileSize = res.fileSize;
                if (!GlobalSettings.isBrowser) {
                    this.commonService.downloadThresoldValues(res).subscribe(saveResponse => {
                        this.logger.logInfo('download thresold values successfully');
                    })
                }
            }
        });
    }
}
