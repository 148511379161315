import { ChangeDetectionStrategy, ChangeDetectorRef, Component, HostListener, Inject, Injector, OnDestroy, OnInit, PLATFORM_ID } from '@angular/core';
import { ToastService } from 'src/app/services/toast.service';
import { Subscription } from 'rxjs';
import { ToastType } from '@maced/angular-components/lib/toast/toast.model';
import { Data } from 'src/app/core/models/data';
import { GlobalSettings } from 'src/app/global.settings';

@Component({
  selector: 'app-toast-message',
  templateUrl: './toast-message.component.html',
  styleUrls: ['./toast-message.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ToastMessageComponent implements OnInit, OnDestroy {

  message:string='';
  msgType!:ToastType;  
  subscription!: Subscription;
  isHasCloseButton: boolean = true;
  link:any = { label:'', hyperLink:'' };
  hasModifiedIcon: boolean = true;
  modifiedIcon= '' as string;
  constructor(private toastService: ToastService, public data: Data, private _cd: ChangeDetectorRef) {   
   }

  ngOnInit(): void {       
    this.subscription = this.toastService.currentMessage
    .subscribe((data: any) => {            
      this.msgType = data.type as ToastType;
      if (this.msgType === 'warning') {
        if (GlobalSettings.isNative) {
          this.isHasCloseButton = false;
        }
        this.modifiedIcon = 'icon-alert-triangle';
      }
      else{
        this.modifiedIcon= 'icon-info-circle';
      }
      this.message = data.message;
      if(data.link){
        this.link = data.link;
       }
      this._cd.detectChanges();  
    });       
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

}
