import { EventEmitter, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { catchError, Observable, of, tap } from 'rxjs';
import { GlobalSettings } from 'src/app/global.settings';
import { ApiConstants } from '../core/constant/api.constant';
import { LoggerService } from '../core/services/logger.service';

@Injectable({
  providedIn: 'root'
})
export class DataMigrationService {
  baseUrl: string = ApiConstants.aceApiBaseUrl;
  printPdfBaseUrl: string = ApiConstants.printPdfBaseUrl;
  params: any;
  headers: any;
  url: any;
  apiData: EventEmitter<any> = new EventEmitter()
  tempParam: any = '';


  constructor(
    private _http: HttpClient,
    public _loggerService: LoggerService,
  ) { }

  getInsertModuleDetails(payLoad: any): Observable<any> {
    if (GlobalSettings?.isBrowser) {
      return this.browserGetInsertModuleDetails(payLoad);
    } else if (GlobalSettings?.isNative) {
      return this.nativeGetInsertModuleDetails(payLoad);
    } else {
      return this.desktopGetInsertModuleDetails(payLoad);
    }
  }

  browserGetInsertModuleDetails(payLoad: any): Observable<any> {
    const url: string = ApiConstants?.aceApiBaseUrl + ApiConstants?.getInsertModuleDetailsUrl;
    return this._http.post<any>(url, payLoad).pipe((response => {
      return response;
    }), catchError(
      this.handleError('browserGetInsertModuleDetails', [])
    ));
  }

  nativeGetInsertModuleDetails(payLoad: any): Observable<any> {
    return new Observable(observer => {
      getInsertModuleDetailsCallback(payLoad, (res: any) => {
        const response = JSON.parse(res);
        if (response.error) {
          observer.next(response.error);
        } else {
          observer.next(response.result);
        }
      }), catchError (
        this.handleError('nativeGetInsertModuleDetails', [])
      );
    });
  }

  desktopGetInsertModuleDetails(payLoad: any): Observable<any> {
    const url: string = GlobalSettings?.LocalNodeServerBaseUrl + '/api/progress/getInsertModuleDetails';
    return this._http.post<any>(url, payLoad).pipe(tap(response => {
      return response;
    }), catchError(
      this.handleError('desktopGetInsertModuleDetails', [])
    ));
  }

  getInsertHomeworkModuleDetails(payLoad: any): Observable<any> {
    if (GlobalSettings?.isBrowser) {
      return this.browserGetInsertHomeworkModuleDetails(payLoad);
    } else if (GlobalSettings?.isNative) {
      return this.nativeGetInsertHomeworkModuleDetails(payLoad);
    } else {
      return this.desktopGetInsertHomeworkModuleDetails(payLoad);
    }
  }

  browserGetInsertHomeworkModuleDetails(payLoad: any): Observable<any> {
    const url: string = ApiConstants?.aceApiBaseUrl + ApiConstants?.getInsertHomeworkModuleDetailsUrl;
    return this._http.post<any>(url, payLoad).pipe(tap(response => {
      return response;
    }), catchError(
      this.handleError('browserGetInsertHomeworkModuleDetails', [])
    ));
  }

  nativeGetInsertHomeworkModuleDetails(payload: any): Observable<any> {
    return new Observable(observer => {
      getInsertHomeworkModuleDetailsCallback(payload, (res: any) => {
        const response = JSON.parse(res);
        if (response.error) {
          observer.next(response.error);
        } else {
          observer.next(response.result);
        }
      }), catchError (
        this.handleError('nativeGetInsertHomeworkModuleDetails', [])
      );
    });
  }

  desktopGetInsertHomeworkModuleDetails(payLoad: any): Observable<any> {
    const url: string = GlobalSettings?.LocalNodeServerBaseUrl + '/api/homework/getInsertHomeworkModuleDetails';
    return this._http.post<any>(url, payLoad).pipe(tap(response => {
      return response;
    }), catchError(
      this.handleError('desktopGetInsertHomeworkModuleDetails', [])
    ));
  }

  getInsertAssessmentModuleDetails(payLoad: any): Observable<any> {
    if (GlobalSettings?.isBrowser) {
      return this.browserGetInsertAssessmentModuleDetails(payLoad);
    } else if (GlobalSettings?.isNative) {
      return this.nativeGetInsertAssessmentModuleDetails(payLoad);
    } else {
      return this.desktopGetInsertAssessmentModuleDetails(payLoad);
    }
  }

  browserGetInsertAssessmentModuleDetails(payload: any): Observable<any> {
    const url: string = ApiConstants?.aceApiBaseUrl + ApiConstants?.getInsertAssessmentModuleDetailsUrl;
    return this._http.post<any>(url, payload).pipe(tap(response => {
      return response;
    }), catchError(
      this.handleError('browserGetInsertAssessmentModuleDetails', [])
    ));
  }

  nativeGetInsertAssessmentModuleDetails(payload: any): Observable<any> {
    return new Observable(observer => {
      getInsertAssessmentModuleDetailsCallback(payload,(res: any) => {
        const response = JSON.parse(res);
        if (response.error) {
          observer.next(response.error);
        } else {
          observer.next(response.result);
        }
      }), catchError (
        this.handleError('nativeGetInsertAssessmentModuleDetails', [])
      );
    });
  }

  desktopGetInsertAssessmentModuleDetails(payLoad: any): Observable<any> {
    const url: string = GlobalSettings?.LocalNodeServerBaseUrl + '/api/homework/getInsertAssessmentModuleDetails';
    return this._http.post<any>(url, payLoad).pipe(tap(response => {
      return response;
    }), catchError(
      this.handleError('desktopGetInsertAssessmentModuleDetails', [])
    ));
  }

  getInsertAnnotationModuleDetails(payLoad: any): Observable<any> {
    if (GlobalSettings?.isBrowser) {
      return this.browserGetInsertAnnotationModuleDetails(payLoad);
    } else if (GlobalSettings?.isNative) {
      return this.nativeGetInsertAnnotationModuleDetails(payLoad);
    } else {
      return this.desktopGetInsertAnnotationModuleDetails(payLoad);
    }
  }

  browserGetInsertAnnotationModuleDetails(payLoad: any): Observable<any> {
    const url: string = ApiConstants?.aceApiBaseUrl + ApiConstants?.getInsertAnnotationModuleDetailsUrl;
    return this._http.post<any>(url, payLoad).pipe(tap(response => {
      return response;
    }), catchError(
      this.handleError('browserGetInsertAnnotationModuleDetails', [])
    ));
  }

  nativeGetInsertAnnotationModuleDetails(payLoad: any): Observable<any> {
    return new Observable(observer => {
      getInsertAnnotationModuleDetailsCallback(payLoad, (res: any) => {
        const response = JSON.parse(res);
        if (response.error) {
          observer.next(response.error);
        } else {
          observer.next(response.result);
        }
      }), catchError (
        this.handleError('nativeGetInsertAnnotationModuleDetails', [])
      );
    });
  }

  desktopGetInsertAnnotationModuleDetails(payLoad: any): Observable<any> {
    const url: string = GlobalSettings?.LocalNodeServerBaseUrl + '/api/bookmarkAndNotes/getInsertAnnotationModuleDetails';
    return this._http.post<any>(url, payLoad).pipe(tap(response => {
      return response;
    }), catchError(
      this.handleError('desktopGetInsertAnnotationModuleDetails', [])
    ));
  }

  getInsertNavioStudentAvatarGuideRewardsInfo(payLoad: any): Observable<any> {
    if (GlobalSettings?.isBrowser) {
      return this.browserGetInsertNavioStudentAvatarGuideRewardsInfo(payLoad);
    } else if (GlobalSettings?.isNative) {
      return this.nativeGetInsertNavioStudentAvatarGuideRewardsInfo(payLoad);
    } else {
      return this.desktopGetInsertNavioStudentAvatarGuideRewardsInfo(payLoad);
    }
  }

  browserGetInsertNavioStudentAvatarGuideRewardsInfo(payLoad: any): Observable<any> {
    const url: string = ApiConstants?.aceApiBaseUrl + ApiConstants?.getInsertNavioStudentAvatarGuideRewardsInfoUrl;
    return this._http.post<any>(url, payLoad).pipe(tap(response => {
      return response;
    }), catchError(
      this.handleError('browserGetInsertNavioStudentAvatarGuideRewardsInfo', [])
    ));
  }

  nativeGetInsertNavioStudentAvatarGuideRewardsInfo(payLoad: any): Observable<any> {
    return new Observable(observer => {
      getInsertNavioStudentAvatarGuideRewardsInfoCallback(payLoad,(res: any) => {
        const response = JSON.parse(res);
        if (response.error) {
          observer.next(response.error);
        } else {
          observer.next(response.result);
        }
      }), catchError (
        this.handleError('nativeGetInsertNavioStudentAvatarGuideRewardsInfo', [])
      );
    });
  }

  desktopGetInsertNavioStudentAvatarGuideRewardsInfo(payLoad: any): Observable<any> {
    const url: string = GlobalSettings?.LocalNodeServerBaseUrl + '/api/common/getInsertNavioStudentAvatarGuideRewardsInfo';
    return this._http.post<any>(url, payLoad).pipe(tap(response => {
      return response;
    }), catchError(
      this.handleError('desktopGetInsertNavioStudentAvatarGuideRewardsInfo', [])
    ));
  }

  updateStudentRelatedDetails(payLoad: any): Observable<any> {
    if (GlobalSettings?.isBrowser) {
      return this.browserUpdateStudentRelatedDetails(payLoad);
    } else if (GlobalSettings?.isNative) {
      return this.nativeUpdateStudentRelatedDetails(payLoad);
    } else {
      return this.desktopUpdateStudentRelatedDetails(payLoad);
    }
  }

  browserUpdateStudentRelatedDetails(payLoad: any): Observable<any> {
    const url: string = ApiConstants?.aceApiBaseUrl + ApiConstants?.updateStudentRelatedDetailsUrl;
    return this._http.post<any>(url, payLoad).pipe(tap(response => {
      return response;
    }), catchError(
      this.handleError('browserUpdateStudentRelatedDetails', [])
    ));
  }

  nativeUpdateStudentRelatedDetails(payLoad: any): Observable<any> {
    return new Observable(observer => {
      updateStudentRelatedDetailsCallback(payLoad, (res: any) => {
        const response = JSON.parse(res);
        if (response.error) {
          observer.next(response.error);
        } else {
          observer.next(response.result);
        }
      }), catchError (
        this.handleError('nativeUpdateStudentRelatedDetails', [])
      );
    });
  }

  desktopUpdateStudentRelatedDetails(payLoad: any): Observable<any> {
    const url: string = GlobalSettings?.LocalNodeServerBaseUrl + '/api/common/updateStudentRelatedDetails';
    return this._http.post<any>(url, payLoad).pipe(tap(response => {
      return response;
    }), catchError(
      this.handleError('desktopUpdateStudentRelatedDetails', [])
    ));
  }

  getInsertHomeworkStudentModuleDetails(payLoad: any): Observable<any> {
    if (GlobalSettings?.isBrowser) {
      return this.browserGetInsertHomeworkStudentModuleDetails(payLoad);
    } else if (GlobalSettings?.isNative) {
      return this.nativeGetInsertHomeworkStudentModuleDetails(payLoad);
    } else {
      return this.desktopGetInsertHomeworkStudentModuleDetails(payLoad);
    }
  }

  browserGetInsertHomeworkStudentModuleDetails(payLoad: any): Observable<any> {
    const url: string = ApiConstants?.aceApiBaseUrl + ApiConstants?.getInsertHomeworkStudentModuleDetailsUrl;
    return this._http.post<any>(url, payLoad).pipe(tap(response => {
      return response;
    }), catchError(
      this.handleError('browserGetInsertHomeworkStudentModuleDetails', [])
    ));
  }

  nativeGetInsertHomeworkStudentModuleDetails(payLoad: any): Observable<any> {
    return new Observable(observer => {
      getInsertHomeworkStudentModuleDetailsCallback(payLoad, (res: any) => {
        const response = JSON.parse(res);
        if (response.error) {
          observer.next(response.error);
        } else {
          observer.next(response.result);
        }
      }), catchError (
        this.handleError('nativeGetInsertHomeworkStudentModuleDetails', [])
      );
    });
  }

  desktopGetInsertHomeworkStudentModuleDetails(payLoad: any): Observable<any> {
    const url: string = GlobalSettings?.LocalNodeServerBaseUrl + '/api/homework/getInsertHomeworkStudentModuleDetails';
    return this._http.post<any>(url, payLoad).pipe(tap(response => {
      return response;
    }), catchError(
      this.handleError('desktopGetInsertHomeworkStudentModuleDetails', [])
    ));
  }

  checkCurrentUserEligibilityForMigration (): Observable<any> {
    if (GlobalSettings?.isBrowser) {
      return this.browsercheckCurrentUserEligibilityForMigration();
    } else if (GlobalSettings?.isNative) {
      return this.nativecheckCurrentUserEligibilityForMigration();
    } else {
      return this.desktopcheckCurrentUserEligibilityForMigration();
    }
  }
  browsercheckCurrentUserEligibilityForMigration(): Observable<any> {
    const url: string = ApiConstants?.aceApiBaseUrl + ApiConstants?.checkcurrentusereligibilityformigration;
    return this._http.get<any>(url, {}).pipe(tap(response => {
      return response;
    }), catchError(
      this.handleError('browsercheckCurrentUserEligibilityForMigration', [])
    ));
  }
  nativecheckCurrentUserEligibilityForMigration(): Observable<any> {
    return new Observable(observer => {
      checkCurrentUserEligibilityForMigrationCallback(((res: any) => {
        const response = JSON.parse(res);
        if (response.error) {
          observer.next(response.error);
        } else {
          observer.next(response.result);
        }
      })), catchError (
        this.handleError('nativecheckCurrentUserEligibilityForMigration', [])
      );
    });
  }
  desktopcheckCurrentUserEligibilityForMigration(): Observable<any> {
    const url: string = GlobalSettings?.LocalNodeServerBaseUrl + '/api/common/checkCurrentUserEligibilityForMigration';
    return this._http.get<any>(url, {}).pipe(tap(response => {
      return response;
    }), catchError(
      this.handleError('desktopcheckCurrentUserEligibilityForMigration', [])
    ));
  }
  getInsertAssessmentStudentModuleDetails(payLoad: any): Observable<any> {
    if (GlobalSettings?.isBrowser) {
      return this.browserGetInsertAssessmentStudentModuleDetails(payLoad);
    } else if (GlobalSettings?.isNative) {
      return this.nativeGetInsertAssessmentStudentModuleDetails(payLoad);
    } else {
      return this.desktopGetInsertAssessmentStudentModuleDetails(payLoad);
    }
  }
  browserGetInsertAssessmentStudentModuleDetails(payLoad: any): Observable<any> {
    const url: string = ApiConstants?.aceApiBaseUrl + ApiConstants?.getinsertassessmentstudentmoduledetails;
    return this._http.post<any>(url, payLoad).pipe(tap(response => {
      return response;
    }), catchError(
      this.handleError('browserGetInsertAssessmentStudentModuleDetails', [])
    ));
  }
  nativeGetInsertAssessmentStudentModuleDetails(payLoad: any): Observable<any> {
    return new Observable(observer => {
      getInsertAssessmentStudentModuleDetailsCallback(payLoad, (res: any) => {
        const response = JSON.parse(res);
        if (response.error) {
          observer.next(response.error);
        } else {
          observer.next(response.result);
        }
      }), catchError (
        this.handleError('nativeGetInsertAssessmentStudentModuleDetails', [])
      );
    });
  }
  desktopGetInsertAssessmentStudentModuleDetails(payLoad: any): Observable<any> {
    const url: string = GlobalSettings?.LocalNodeServerBaseUrl + '/api/homework/getInsertAssessmentStudentModuleDetails';
    return this._http.post<any>(url, payLoad).pipe(tap(response => {
      return response;
    }), catchError(
      this.handleError('desktopGetInsertAssessmentStudentModuleDetails', [])
    ));
  }

  checkUsersFinalDataMigrationStatus(): Observable<any> {
    if (GlobalSettings?.isBrowser) {
      return this.browserCheckUsersFinalDataMigrationStatus();
    } else if (GlobalSettings?.isNative) {
      return this.nativeCheckUsersFinalDataMigrationStatus();
    } else {
      return this.desktopCheckUsersFinalDataMigrationStatus();
    }
  }
  browserCheckUsersFinalDataMigrationStatus(): Observable<any> {
    const url: string = ApiConstants?.aceApiBaseUrl + ApiConstants?.checkusersfinaldatamigrationstatus;
    return this._http.post<any>(url, {}).pipe(tap(response => {
      return response;
    }), catchError(
      this.handleError('browserCheckUsersFinalDataMigrationStatus', [])
    ));
  }
  nativeCheckUsersFinalDataMigrationStatus(): Observable<any> {
    return new Observable(observer => {
      checkUsersFinalDataMigrationStatusCallback(((res: any) => {
        const response = JSON.parse(res);
        if (response.error) {
          observer.next(response.error);
        } else {
          observer.next(response.result);
        }
      })), catchError (
        this.handleError('nativeCheckUsersFinalDataMigrationStatus', [])
      );
    });
  }
  desktopCheckUsersFinalDataMigrationStatus(): Observable<any> {
    const url: string = GlobalSettings?.LocalNodeServerBaseUrl + '/api/common/checkUsersFinalDataMigrationStatus';
    return this._http.post<any>(url, {}).pipe(tap(response => {
      return response;
    }), catchError(
      this.handleError('desktopCheckUsersFinalDataMigrationStatus', [])
    ));
  }

  getInsertHomeworkStudentRemainingAttemptsDetails(payLoad: any): Observable<any> {
    if (GlobalSettings?.isBrowser) {
      return this.browserGetInsertHomeworkStudentRemainingAttemptsDetails(payLoad);
    } else if (GlobalSettings?.isNative) {
      return this.nativeGetInsertHomeworkStudentRemainingAttemptsDetails(payLoad);
    } else {
      return this.desktopGetInsertHomeworkStudentRemainingAttemptsDetails(payLoad);
    }
  }
  browserGetInsertHomeworkStudentRemainingAttemptsDetails(payLoad: any): Observable<any> {
    const url: string = ApiConstants?.aceApiBaseUrl + ApiConstants?.getinserthomeworkstudentremainingattemptsdetails;
    return this._http.post<any>(url, payLoad).pipe(tap(response => {
      return response;
    }), catchError(
      this.handleError('desktopGetInsertHomeworkStudentRemainingAttemptsDetails', [])
    ));
  }
  nativeGetInsertHomeworkStudentRemainingAttemptsDetails(payLoad: any): Observable<any> {
    return new Observable(observer => {
      getInsertHomeworkStudentRemainingAttemptsDetailsCallback(payLoad, (res: any) => {
        const response = JSON.parse(res);
        if (response.error) {
          observer.next(response.error);
        } else {
          observer.next(response.result);
        }
      }), catchError (
        this.handleError('nativeGetInsertHomeworkStudentRemainingAttemptsDetails', [])
      );
    });
  }
  desktopGetInsertHomeworkStudentRemainingAttemptsDetails(payLoad: any): Observable<any> {
    const url: string = GlobalSettings?.LocalNodeServerBaseUrl + '/api/homework/getInsertHomeworkStudentRemainingAttemptsDetails';
    return this._http.post<any>(url, payLoad).pipe(tap(response => {
      return response;
    }), catchError(
      this.handleError('desktopGetInsertHomeworkStudentRemainingAttemptsDetails', [])
    ));
  }

  getInsertEplannerModuleDetails(payLoad: any): Observable<any> {
    if (GlobalSettings?.isBrowser) {
      return this.browserGetInsertEplannerModuleDetails(payLoad);
    } else if (GlobalSettings?.isNative) {
      return this.nativeGetInsertEplannerModuleDetails(payLoad);
    } else {
      return this.desktopGetInsertEplannerModuleDetails(payLoad);
    }
  }
  browserGetInsertEplannerModuleDetails(payLoad: any): Observable<any> {
    const url: string = ApiConstants?.aceApiBaseUrl + ApiConstants?.getinserteplannermoduledetails;
    return this._http.post<any>(url, payLoad).pipe(tap(response => {
      return response;
    }), catchError(
      this.handleError('desktopGetInsertHomeworkStudentRemainingAttemptsDetails', [])
    ));
  }
  nativeGetInsertEplannerModuleDetails(payLoad: any): Observable<any> {
    return new Observable(observer => {
      getInsertEplannerModuleDetailsCallback(payLoad, (res: any) => {
        const response = JSON.parse(res);
        if (response.error) {
          observer.next(response.error);
        } else {
          observer.next(response.result);
        }
      }), catchError (
        this.handleError('nativeGetInsertEplannerModuleDetails', [])
      );
    });
  }
  desktopGetInsertEplannerModuleDetails(payLoad: any): Observable<any> {
    const url: string = GlobalSettings?.LocalNodeServerBaseUrl + '/api/common/getInsertEplannerModuleDetails';
    return this._http.post<any>(url, payLoad).pipe(tap(response => {
      return response;
    }), catchError(
      this.handleError('desktopGetInsertEplannerModuleDetails', [])
    ));
  }

  handleError<T>(_operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      this._loggerService.logInfo(error);
      return of(result as T);
    };
  }

  getTotalHomeworkCountForMigration(): Observable<any> {
    const url: string = ApiConstants?.aceApiBaseUrl + ApiConstants?.getTotalHomeworkCountForMigration;
    return this.commonPostAPI(url, {});
  }
  deleteAllDataForMigration(): Observable<any> {
    const url: string = ApiConstants?.aceApiBaseUrl + ApiConstants?.deleteAllDataForMigration;
    return this.commonPostAPI(url, {});
  }
  deleteModuleDataForMigration(): Observable<any> {
    const url: string = ApiConstants?.aceApiBaseUrl + ApiConstants?.deleteModuleDataForMigration;
    return this.commonPostAPI(url, {});
  }

  commonPostAPI(url: string, reqBody: any): Observable<any> {
    if (GlobalSettings.isBrowser) {
      return this.browserCommonPostAPI(reqBody, url);
    } else if (GlobalSettings.isNative) {
      return this.nativeCommonPostAPI(reqBody, url);
    } else {
      return this.desktopCommonPostAPI(reqBody, url);
    }
  }

  browserCommonPostAPI(reqBody: any, url: string): Observable<any> {
    return this._http.post<any>(url, reqBody)
      .pipe(
        tap(response => {
          return response;
        }),
        catchError(this.handleError('browserCommonPostAPI', []))
      );
  }

  nativeCommonPostAPI(reqBody: any, url: string): Observable<any> {
    return new Observable(observer => {
      postCommonCallback(this.getNativeObject(reqBody, url), this.bindNativeObservable(observer));
    });
  }

  desktopCommonPostAPI(reqBody: any, url: string): Observable<any> {
    const commonUrl: string = `${GlobalSettings.LocalNodeServerBaseUrl}/api/common/postCommonApi`;
    return this._http.post<any>(commonUrl, this.getNativeObject(reqBody, url))
      .pipe(
        tap(response => {
          return response;
        }),
        catchError(this.handleError('desktopCommonPostAPI', []))
      );
  }
  getNativeObject(reqBody: any, apiUrl: string) {
    let nativeObject;
    if (reqBody) {
      nativeObject = {
        reqBody,
        apiUrl
      }
    }
    return nativeObject;
  }

  bindNativeObservable(observer: any) {
    return (res: any) => {
      if (typeof res === 'string') {
        res = JSON.parse(res);
      }
      if (res.error) {
        observer.next(res.error);
      } else {
        observer.next(res.result);
      }
    };
  }

  updateOfflineMigrationFirstLogin(): Observable<any> {
    const url: string = ApiConstants?.aceApiBaseUrl + ApiConstants?.updateOfflineMigrationFirstLogin;
    return this.commonPostAPI(url, {});
  }

}