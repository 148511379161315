<div *ngIf="isShowGlobalmenu" class="c-navigation " [ngClass]="{'c-navigation--overlay':isShowCascadebar}">
    <div (click)="ovarlayClickJS()" *ngIf="showOverlay && isShowCascadebar && isJS " class="c-navigation__overlay">
    </div>
    <a class="c-navigation__overlay" *ngIf="showOverlay && !isJS" [routerLink]="currentUrl"
        [queryParams]="{'menuHierarchy':activeMenuHierarchy}"></a>
    <maced-menubar id="Menubar" *ngIf="isShowMenubar" [menuList]="menuList" [isShowExpandCollapse]="false" [optInStatusIcon]="optInStatusIcon"
        [isMenubarExpand]="true" (closeMenubarEvent)="onCloseMenubar()" (menuEvent)="onClickMenu($event)" [isThemeMenubarExpand]="isThemeMenubarExpand"
        (moreMenuEvent)="onClickMoreMenu()" [hasTitleBar]="false"  [hasThemeOption]="hasThemeOption" [themeMenu]="themeMenu">
    </maced-menubar>
    <div class="c-navigation__cascade" [ngClass]="{'c-navigation__cascade--without-menubar': !isShowMenubar}">
        <button type="button" data-cc-action="preferences" class="cc-preferences-button"> Cookie settings </button>
        <ng-container *ngIf="isShowCascadebar">
            <ng-container *ngFor="let cascadeMenu of cascadeMenus;trackBy: trackByFn;let i = index">
                <maced-cascade-menubar [cascadeMenubarId]="'CascadeMenubar' + i"
                    [cascadeMenubarLabel]="cascadeMenubarLabel" [cascadeMenuList]="cascadeMenu.menus" [backAriaLabel]="cascadeMenubarLabel"
                    (menuEvent)="onClickCascadeMenu($event,i)" [backUrl]="getBackUrl()"
                    [backQueryParams]="getBackQueryParam(cascadeMenu)" (backEvent)="onClickCascadeBack(i)"
                    (closeEvent)="onClickCascadeClose()" [hasBackButton]="true" [hasCloseButton]="false"
                    [hasTitleBar]="hasTitleBar(i)" [isCascadeThemeOpens]="isCascadeThemeOpens" [dataccActionName]="'preferences'" *ngIf="cascadeMenu.isVisible">
                    <ng-container ngProjectAs="[cascadeMenubarLabel]">
                        <h1 [ngClass]="{'c-navigation-cascading__title-theme-text--small': !isJS}">{{cascadeMenubarLabel}}</h1>
                    </ng-container>
                </maced-cascade-menubar>
            </ng-container>
        </ng-container>
    </div>
</div>
<ng-container *ngIf="!isInternetAvailable">
    <app-no-internet-connection-dialog (cancel)="onCancel($event)"
        (tryAgain)="onTryAgain()" [isThemeNoInternet]="true"></app-no-internet-connection-dialog>
</ng-container>