export const SectionsData = [
  {
    title: 'Search',
    name: 'search'
  },
  {
    title: 'Extra activities',
    name: 'lesson-extras'
  },
  {
    title: 'Glossary',
    name: 'glossary'
  },
  {
    title: 'Downloads',
    name: 'my-downloads'
  },
  {
    title: 'QuickLinks',
    name: 'my-quickLinks'
  },
  {
    title: 'Digital resources',
    name: 'digital-resources',
    backButtonClass: 'backIcon'
  }
]
